import './RightPane.scss'
import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  ordersAtom,
  transfersAtom,
  userLiveDocAtom,
  selectedEmptyChartAtom,
} from '../../types/global_types'
import { capitalizeFirstLetterOfAWord, stringToNumber, formatPercent, formatDateForTable, formatNumberForTable, valueForTable, roundToSigFigs, set_open_chart, returnChartPaneContextObj } from '../../logic/u'
import { currentUser } from '../../firebase'
import { Icon, showModal } from '../reusable'

import { getConfig } from '../../config'
import { OrderDetailsModal } from '../modals/OrderDetailsModal'
import { PositionDetailsModal } from '../modals/PositionDetailsModal'
import { TransferDetailsModal } from '../modals/TransferDetailsModal'
import { Variable } from '../../types/user_types'
const config = getConfig() as any

export const RightPane = () => {
  const [ loggedInUser ] = useAtom(loggedInUserAtom)

  if (!loggedInUser) return null

  const [ selectedFirstPane, setSelectedFirstPane ] = useState<string>('positions')
  const [ selectedSecondPane, setSelectedSecondPane ] = useState<string>('orders')

  // Draggable split, set to user
  useEffect(() => {
    const returnSplitToDefault = () => {
      db.collection('users').doc(loggedInUser.uid).update({ rightPaneDividerSplit: [50, 50] });
    };

    const gutterElement = document.querySelector('.right-pane-split .gutter-vertical');

    if (gutterElement) {
      gutterElement.addEventListener('dblclick', returnSplitToDefault);
    }

    return () => {
      if (gutterElement) {
        gutterElement.removeEventListener('dblclick', returnSplitToDefault);
      }
    };
  }, [loggedInUser]);

  let rightPaneDividerSplit = loggedInUser?.rightPaneDividerSplit || [50, 50]

  return (
    <div className={'right-pane'}>
      <Split
        className="right-pane-split"
        direction="vertical"
        sizes={rightPaneDividerSplit}
        minSize={37}
        gutterSize={5}
        gutterAlign='center'
        cursor='row-resize'
        onDragEnd={(val) => {db.collection('users').doc(loggedInUser.uid).update({rightPaneDividerSplit: val})}}
      >
        <div className='section'>
          <div className='header'>
            <div className='row'>
              <div
                className={selectedFirstPane === 'positions' ? 'header-tab selected' : 'header-tab'}
                onClick={() => {setSelectedFirstPane('positions')}}
              >
                Positions
              </div>
            </div>
          </div>
          <PositionsPane />
        </div>
        <div className='section'>
          <div className='header'>
            <div className='handle' />
            <div className='row'>
              <div
                className={selectedSecondPane === 'orders' ? 'header-tab selected' : 'header-tab'}
                onClick={() => {setSelectedSecondPane('orders')}}
              >
                Orders
              </div>
              <div
                className={selectedSecondPane === 'transfers' ? 'header-tab selected' : 'header-tab'}
                onClick={() => {setSelectedSecondPane('transfers')}}
              >
                Transfers
              </div>
            </div>
          </div>
          {selectedSecondPane === 'orders' ? <OrdersPane /> : <TransfersPane />}
        </div>
      </Split>
    </div>
  )
}

const PositionsPane = () => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyChart] = useAtom(selectedEmptyChartAtom)

  if (!user) return null
  if (!uld) return null

  if (Object.keys(uld.liveData?.POSITIONS).length) {
    return (
      <div className='sect-body'>
        {Object.keys(uld.liveData?.POSITIONS).sort().map(p => {
          const position = uld.liveData?.POSITIONS[p]
          const chart = position.symbol
          const chartIcon = 'square-' + position.symbol.charAt(0)
          const rounded_qty = roundToSigFigs(position.qty, position.avg_entry_price)
          const chartPaneContextObj = returnChartPaneContextObj(user, chart)
          const chartIsVisible = chartPaneContextObj?.chartIsVisible
          let divClass = 'entry'
          if (chartIsVisible) {
            divClass += ' selected'
          }
          return (
            <div
              className={divClass}
              onClick={() => {
                set_open_chart(user, uld, selectedEmptyChart || 1, chart)
              }}
            >
              <div className='column'>
                <div className='row space-between'>
                  <div className='row'>
                    <Icon
                      icon={chartIcon}
                      set='sharp-solid'
                      size={12}
                      className={'key-icon'}
                      style={{marginTop: 2, marginRight: 5}}
                    />
                    <div className='orange-text'>{position.symbol}</div>
                  </div>
                  <div className={position.unrealized_pl >= 0 ? ' green-tag' : ' red-tag'}>{formatNumberForTable(position.unrealized_pl)}{' [' + formatPercent(position.unrealized_pl_pc) + ']'}</div>
                </div>
                <div className='row space-between font-size-11'>
                  <div className='orange-text'>{rounded_qty} @ {formatNumberForTable(position.avg_entry_price)}</div>
                  <div className={'orange-text'}>{formatNumberForTable(position.mkt_value)}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div className='sect-body'>
      <div
        className='margin-top-10 margin-left-10 orange-text font-size-11'
      >
        No positions.
      </div>
    </div>
  )
}

const OrdersPane = () => {
  const [ orders, ] = useAtom(ordersAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [selectedOrderCategory, setSelectedOrderCategory] = useState<string>('recent')

  if (!uld) return null


  let ordersToShow: any[] = []

  if (selectedOrderCategory === 'recent') {
    ordersToShow = orders?.recent
  }
  if (selectedOrderCategory === 'working') {
    ordersToShow = orders?.working
  }
  if (selectedOrderCategory === 'filled') {
    ordersToShow = orders?.filled
  }

  const rows: JSX.Element[] = []
  ordersToShow?.forEach((order, index) => {
    let fontColor = 'orange-text'
    if (order.status === 'canceled' || order.status === 'rejected') {
      fontColor = 'gray-text'
    }
    let price = order.filled_avg_price
    let otherPrice: number | null = null
    if (order.type === 'limit') {
      price = order.limit_price
    }
    if (order.type === 'stop') {
      price = order.stop_price
    }
    if (order.type === 'stop_limit') {
      price = order.stop_price
      otherPrice = order.limit_price
    }

    // Round qty to a reasonable number
    let unrounded_qty = order.qty
    if (order.notional) {
      unrounded_qty = order.filled_qty
    }
    const rounded_qty = roundToSigFigs(unrounded_qty, price)

    const chartIcon = 'square-' + order.symbol.charAt(0)

    const orderDiv = (
      <div
        className='entry'
        onClick={() => setOpenModal(<OrderDetailsModal order={order} />)}
      >
        <div className={'column ' + fontColor}>
          <div className='row space-between'>
            <div className='row'>
              <Icon
                icon={chartIcon}
                set='sharp-solid'
                size={12}
                className={'key-icon'}
                style={{marginTop: 2, marginRight: 5}}
              />
              <div className='orange-text'>{order.symbol}</div>
            </div>
            <div>
              {rounded_qty}
              {' @ ' + formatNumberForTable(price) + (otherPrice ? '-' + formatNumberForTable(otherPrice) : '')}
            </div>
          </div>
          <div className='row space-between font-size-11'>
            <div>{order.side}</div>
            <div>{order.type + ' • ' + order.time_in_force}</div>
          </div>
          <div className='row space-between font-size-10'>
            <div>{order.status}</div>
            <div>{formatDateForTable(order.updated_at)}</div>
          </div>
        </div>
      </div>
    )
    rows.push(orderDiv)
  })

  const SubHeader = () => {
    return (
      <div className='subheader'>
        <div
          className={selectedOrderCategory === 'recent' ? 'subtab selected' : 'subtab'}
          onClick={() => {setSelectedOrderCategory('recent')}}
        >
          Recent
        </div>
        <div
          className={selectedOrderCategory === 'working' ? 'subtab selected' : 'subtab'}
          onClick={() => {setSelectedOrderCategory('working')}}
        >
          Working
        </div>
        <div
          className={selectedOrderCategory === 'filled' ? 'subtab selected' : 'subtab'}
          onClick={() => {setSelectedOrderCategory('filled')}}
        >
          Filled
        </div>
      </div>

    )
  }

  if (!orders) {
   return (
      <>
        <SubHeader />
        <div className='sect-body has-subheader'>
          <div
            className='margin-top-10 margin-left-10 orange-text font-size-11'
          >
            Loading orders...
          </div>
        </div>
      </>
   )
  }
  if (rows.length) {
    return (
      <>
        <SubHeader />
        <div className='sect-body has-subheader'>
          {rows}
        </div>
      </>
    )
  }

  return (
    <>
      <SubHeader />
      <div className='sect-body has-subheader'>
        <div
          className='margin-top-10 margin-left-10 orange-text font-size-11'
        >
          No {selectedOrderCategory} orders.
        </div>
      </div>
    </>
  )
}

const TransfersPane = () => {
  const [ transfers ] = useAtom(transfersAtom)
  const [uld] = useAtom(userLiveDocAtom)

  const [, setOpenModal] = useAtom(openModalAtom)
  const [selectedTransferCategory, setSelectedTransferCategory] = useState<string>('recent')

  if (!uld) return null

  const incomingTransfers: any[] = []
  const outgoingTransfers: any[] = []

  transfers?.forEach(transfer => {
    if (transfer.direction === 'INCOMING') {
      incomingTransfers.push(transfer)
    }
    if (transfer.direction === 'OUTGOING') {
      outgoingTransfers.push(transfer)
    }
  })

  let transfersToShow: any[] | null = []
  if (selectedTransferCategory === 'recent') {
    transfersToShow = transfers
  }
  if (selectedTransferCategory === 'incoming') {
    transfersToShow = incomingTransfers
  }
  if (selectedTransferCategory === 'outgoing') {
    transfersToShow = outgoingTransfers
  }

  const rows: JSX.Element[] = []
  transfersToShow?.forEach((transfer, index) => {
    let fontColor = 'orange-text'
    if (transfer.status === 'CANCELED') {
      fontColor = 'gray-text'
    }
    const div = (
      <div
        className='entry'
        onClick={() => setOpenModal(<TransferDetailsModal transferId={transfer.id} />)}
      >
        <div className={'column ' + fontColor}>
          <div className='row space-between'>
            <div>{transfer.direction}</div>
            <div>{valueForTable('amount', transfer.amount)}</div>
          </div>
          <div className='row space-between font-size-11'>
            <div>{transfer.status}</div>
            <div>{formatDateForTable(transfer.updated_at)}</div>
          </div>
        </div>
      </div>
    )
    rows.push(div)
  })

  const SubHeader = () => {
    return (
      <div className='subheader'>
        <div
          className={selectedTransferCategory === 'recent' ? 'subtab selected' : 'subtab'}
          onClick={() => {setSelectedTransferCategory('recent')}}
        >
          Recent
        </div>
        <div
          className={selectedTransferCategory === 'incoming' ? 'subtab selected' : 'subtab'}
          onClick={() => {setSelectedTransferCategory('incoming')}}
        >
          Incoming
        </div>
        <div
          className={selectedTransferCategory === 'outgoing' ? 'subtab selected' : 'subtab'}
          onClick={() => {setSelectedTransferCategory('outgoing')}}
        >
          Outgoing
        </div>
      </div>
    )
  }
  if (!transfers) {
    return (
      <>
        <SubHeader />
        <div className='sect-body has-subheader'>
          <div
            className='margin-top-10 margin-left-10 orange-text font-size-11'
          >
            Loading transfers...
          </div>
        </div>
      </>
    )
  }
  if (rows.length) {
    return (
      <>
        <SubHeader />
        <div className='sect-body has-subheader'>
          {rows}
        </div>
      </>
    )
  }
  return (
    <>
      <SubHeader />
      <div className='sect-body has-subheader'>
        <div
          className='margin-top-10 margin-left-10 orange-text font-size-11'
        >
          No transfers.
        </div>
      </div>
    </>
  )
}


