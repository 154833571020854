// IMPORTANT - GO THROUGH 'FIX' 'TODO' ETC. BEFORE GOING LIVE!

import React, { useState, useEffect, useRef } from 'react'

import './App.scss'
// import './components/logged_out/SignUp.scss'

import axios from 'axios'

import { LoggedOutMenuBar } from './components/logged_out/LoggedOutMenuBar'
import { SignUp } from './components/logged_out/SignUp'
import { Login } from './components/logged_out/Login'
import { ResetPassword } from './components/logged_out/ResetPassword'
import { ResetPasswordConfirmationScreen } from './components/logged_out/ResetPasswordConfirmationScreen'
import { AlpacaSignUp } from './components/logged_out/alpaca_signup/AlpacaSignUp'

import { MenuBar } from './components/sidebars/MenuBar'
import { AppTabs } from './components/sidebars/AppTabs'

import { ChartDividerPane } from './components/sidebars/ChartDividerPane'
import { RightPane } from './components/sidebars/RightPane'

import { PortfolioTab } from './components/tabs/PortfolioTab'
import { AccountTab } from './components/tabs/AccountTab'
import { AdminTab } from './components/tabs/AdminTab'
import { get_initial_daily_log } from './logic/initial_daily_log'

import Split from 'react-split'

import { useAtom } from 'jotai'
import {
  appTabAtom,
  toolModeAtom,
  intervalAtom,
  openModalAtom,
  loggedOutTabAtom,
  loggedInUserAtom,
  ordersAtom,
  selectedChartAtom,
  selectedAnnotationAtom,
  annotationInProgressAtom,
  transfersAtom,
  loadingAtom,
  allSymbolsAtom,
  userLiveDocAtom,
  selectedCodeEditorSlotAtom,
  cmdKAtom, cmdSAtom, cmdOAtom, cmdTAtom,
} from './types/global_types'

import {
  ORANGE,
  BACKGROUND,
  GREEN,
  registerUsage,
  haveWeTimedOut,
  array_compare,
  updateLiveData,
  isMarketOpenOrWillItOpenSoon,
} from './logic/u'

import { getFirebase, authFunctionsOnWindow, currentUser, db } from './firebase'
import { getConfig } from './config'
import { Button, Icon } from './components/reusable'
import { LogsTab } from './components/tabs/LogsTab'
import { FunctionsTree } from './components/functions/FunctionsTree'
import { FunctionsTab } from './components/functions/FunctionsTab'
import { BottomPane } from './components/sidebars/BottomPane'
import { LeftPane } from './components/sidebars/LeftPane'
import { WatchlistsTree } from './components/watchlists/WatchlistsTree'
import { WatchlistTab } from './components/watchlists/WatchlistsTab'
// import { Timestamp } from '@firebase/firestore-types'
// import firebase from 'firebase/app';
// import 'firebase/firestore';
const config = getConfig() as any
const firebase = getFirebase()

const SMALL_SCREEN_THRESHOLD = 1080


function App() {
  authFunctionsOnWindow()

  /*
  All globally shared context must be defined here
  ALSO IN global_types
  */

  /* USER & LIVE DATA */
  const [fireBaseToken, setFirebaseToken] = useState<string | null>(null)
  const [loggedInUser, setLoggedInUser] = useAtom(loggedInUserAtom)
  const [userLiveDoc, setUserLiveDoc] = useAtom(userLiveDocAtom)
  const [emailVerified, setEmailVerified] = useState<boolean>(false)
  // const [activeRuns, setActiveRuns] = useAtom(activeRunsAtom)
  // const [updatesPaused, ] = useAtom(updatesPausedAtom)
  const [mountedTimestamp, setMountedTimestamp] = useState<number>(0)

  /* LAYOUT */
  const [loggedOutTab, setLoggedOutTab ] = useAtom(loggedOutTabAtom)
  const [, setAppTab] = useAtom(appTabAtom)
  const [openModal, setOpenModal] = useAtom(openModalAtom)
  const [loading, setLoading] = useAtom(loadingAtom)
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < SMALL_SCREEN_THRESHOLD);
  const [emailVerBtnLoading, setEmailVerBtnLoading] = useState<boolean>(false)

  /* CHART */
  const [selectedChart, setSelectedChart] = useAtom(selectedChartAtom)
  const [selectedAnnotation, setSelectedAnnotation] = useAtom(selectedAnnotationAtom)
  const [, setAnnotationInProgress] = useAtom(annotationInProgressAtom)
  const [, setToolMode] = useAtom(toolModeAtom)
  const [timeframe] = useAtom(intervalAtom)

  /* KEYBOARD SHORTCUTS */
  const [, setCmdK] = useAtom(cmdKAtom)
  const [, setCmdS] = useAtom(cmdSAtom)
  const [, setCmdO] = useAtom(cmdOAtom)
  const [, setCmdT] = useAtom(cmdTAtom)

  /* ALPACA ORDERS */
  const [, setOrders] = useAtom(ordersAtom)

  /* ALPACA TRANSFERS */
  const [, setTransfers] = useAtom(transfersAtom)

  /* MISC */
  const [allSymbols, setAllSymbols] = useAtom(allSymbolsAtom)

  /* FUNCTIONS */
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)

  /* Helpers */
  const hasAppAccess = loggedInUser?.signUpStage === 'Done'

  /* Handle login/logout, subscribe to user and userLiveDoc */
  useEffect(() => {
    setLoading(true);
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          setFirebaseToken(token);
          setEmailVerified(user.emailVerified);

          // Subscribing to user updates and live document updates
          await subscribeToUserUpdates(user.uid);
          await subscribeToUserLiveDoc(user.uid);

          // console.log('Firebase token:', token);

          setLoading(false);
        } catch (error) {
          console.error("Error handling user auth state change:", error);
          setLoading(false);
        }
      } else {
        // Handle user not logged in
        setFirebaseToken(null);
        setLoggedInUser(null);
        setLoading(false);
        setLoggedOutTab('log_in');
        setAppTab('home')
        console.log("No user logged in.");
      }
    });

    // Cleanup function for unsubscribing from auth state changes
    return () => {
      console.log("Unsubscribing from auth state changes.");
      unsubscribe();
    };
  }, []);


  /* FIREBASE SUBSCRIPTIONS */
  const subscribeToUserUpdates = async (userId) => {
    console.log('SUBSCRIBING TO USER SNAPSHOTS')
    const userDocRef = db.collection('users').doc(userId);

    const unsubscribe = userDocRef.onSnapshot((doc) => {
      const userData = doc.data();
      if (userData) {
        console.log('User data updated.');
        //@ts-ignore
        setLoggedInUser({ ...userData, id: doc.id }); // Assuming you spread userData correctly into state
        //@ts-ignore
        window.user = userData
      }
    });

    return unsubscribe; // Return the unsubscribe function for cleanup
  };

  // contains liveData, and everything that streams
  const subscribeToUserLiveDoc = async (userId: string) => {
    await db.collection('userLiveDocs').doc(userId).onSnapshot((doc) => {
      const uld: any = doc.data()
      if (uld) {
        uld.id = doc.id

        if (doc.id !== userId) {
          throw new Error ('userLiveDoc id is the same as the user id')
        }
        setUserLiveDoc(uld)

        //@ts-ignore
        window.uld = uld
      } else {
        console.log('no userLiveDoc exists for this user, creating one now')

        // Create a userLiveDoc with the same id as the user
        const initial_daily_log = get_initial_daily_log()
        db.collection('userLiveDocs').doc(userId).set({
          uid: userId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          cachedEELogs: [],
          cachedDayLogs: [initial_daily_log],
          symbolsFollowing: [],
          needsStreamingData: false,
          quotes: {},
          orders: [],
          transfers: [],
        })
        db.collection('users').doc(userId).collection('EELogs').add(initial_daily_log)
      }
    })
  }

  /*
  UPDATE LAST SEEN ON CRON JOB - this permits streaming to the userLiveDoc
  object from runSSEListeners. Affects userLiveDoc, not user
  */
  const isFirstRun = useRef(true);
  const updateLastSeen = async () => {
    if (!loggedInUser) return
    if (!userLiveDoc) return
    if (haveWeTimedOut()) return          // DO WE STILL NEED THIS?

    // If market is closed, no reason to leave us in !isActive
    if (userLiveDoc?.isActive) {
      if (!isMarketOpenOrWillItOpenSoon()) return
    }



    const now = firebase.firestore.FieldValue.serverTimestamp()
    const toSet: any = {
      lastSeen: now,
      isActive: true            // isActive probably not needed anymore
      }

    // What charts are open? We need to reset symbolsFollowing
    if (!userLiveDoc.symbolsFollowing?.length) {
      const sf: string[] = []
      if (loggedInUser.chartSettingsCH1) sf.push(loggedInUser.chartSettingsCH1)
      if (loggedInUser.chartSettingsCH2) sf.push(loggedInUser.chartSettingsCH2)
      if (loggedInUser.chartSettingsCH3) sf.push(loggedInUser.chartSettingsCH3)
      if (loggedInUser.chartSettingsCH4) sf.push(loggedInUser.chartSettingsCH4)
      if (sf.length) {
        toSet.symbolsFollowing = sf
        toSet.needsStreamingData = true
      }
    }

    await db.collection('userLiveDocs').doc(loggedInUser.uid).update(toSet)
    if (['ACTIVE', 'SUBMITTED'].includes(loggedInUser?.alpacaObject?.status)) {
      updateLiveData(loggedInUser)          // runTick call to update
    }
  }

  // Refresh data when user returns to tab, reopens laptop, etc
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const last_ran_date = userLiveDoc?.liveDataLastUpdated?.toDate()
        const seconds_ago = (new Date().getTime() - last_ran_date.getTime() / 1000)
        if (seconds_ago > 60) {
          console.log(`Updating liveData on account of return to visibility, last update was ${seconds_ago} seconds ago`);
          updateLiveData(loggedInUser);
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [loggedInUser]);

  useEffect(() => {
    if (!loggedInUser) return
    if (!userLiveDoc) return
    if (isFirstRun.current) {
      updateLiveData(loggedInUser)
      updateLastSeen()
      isFirstRun.current = false
    }
    const interval = setInterval(updateLastSeen, 60000)
    return () => clearInterval(interval);
  }, [loggedInUser])

  // Load transfers into common mechanism
  useEffect(() => {
    if (!userLiveDoc) return
    setTransfers(userLiveDoc.transfers)
  }, [userLiveDoc])

  // Load orders into common mechanism
  useEffect(() => {
    if (!userLiveDoc) return

    const orders: any = {
      recent: [],
      canceled: [],
      expired: [],
      working: [],
      filled: [],
    }

    userLiveDoc.orders?.forEach((order: any) => {
      orders.recent.push(order)
      if (order.status === 'filled') {
        orders.filled.push(order)
      } else if (order.status === 'canceled') {
        orders.canceled.push(order)
      } else if (order.status === 'expired') {
        orders.expired.push(order)
      } else if (order.status !== 'filled' && order.status !== 'canceled' && order.status !== 'expired' && order.status !== 'rejected') {
        orders.working.push(order)
      }
    })

    setOrders(orders)
  }, [userLiveDoc])


  /* TRACK AUTH STATUS, SUBSCRIBE TO USER */
  // useEffect(() => {
  //   // Important to setLoading back to true in various levels given edge cases
  //   setLoading(true)
  //   // Triggered anytime state or user auth state is changed
  //   const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
  //     setLoading(true)
  //     if (user) {
  //       setLoading(true)
  //       try {
  //         const token = await user.getIdToken()
  //         console.log(token) // TODO REMOVE AFTER DEBUGGING
  //         setFirebaseToken(token)
  //         setEmailVerified(user.emailVerified)
  //         subscribeToUserUpdates(user.uid)
  //         subscribeToUserLiveDoc(user.uid)
  //         setLoading(false)
  //       } catch (err) {
  //         console.error('Error fetching token:', err)
  //         setLoading(false)
  //       }
  //       // On logout or when there's no token
  //     } else {
  //       setLoading(false)
  //       setFirebaseToken(null)
  //       setLoggedInUser(null)
  //       setLoggedOutTab('log_in')
  //       //@ts-ignore
  //       setAppTab('home')
  //     }
  //   })
  //   return () => unsubscribe()
  // }, [])

  // Convenience
  useEffect(() => {
    if (location.host.includes('staging')) {
      document.title = 'Tickerbot (STAGING)'
    }
    if (location.host.includes('localhost')) {
      document.title = 'Tickerbot (LOCAL)'
    }
  }, [])

  /* LOAD COMPLETE LIST OF ASSETS FOR LATER USE */
  useEffect(() => {
    if (allSymbols.length) return
    const loadAssets = async () => {
      const token = await currentUser()?.getIdToken()
      if (!token) return
      if (allSymbols.length !== 0) return
      const endpoint = `${config.api_root_url}getAllSymbols`
      const res = await axios.get(endpoint, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      const assets = res.data
      setAllSymbols(assets)
      console.log(`loaded details on ${assets.length} assets`)
    }
    loadAssets()
  }, [loggedInUser])

  // Ensure backend tickers are up to date
  useEffect(() => {
    if (!loggedInUser) return
    const backendTickers = Object.keys(loggedInUser.charts || {})
    const positionTickers = Object.keys(userLiveDoc?.liveData?.POSITIONS || {})
    const watchlistTickers = (loggedInUser.watchlists || []).map(w => w.tickers).flat()
    const allRelevantTickers: string[] = [...new Set(positionTickers.concat(watchlistTickers))]
    const newCharts = loggedInUser.charts

    // Make sure each position and watchlist ticker is on the backend
    allRelevantTickers.forEach(t => {
      if (backendTickers.includes(t)) {return}
      newCharts[t] = {
        symbol: t,
        display: t, // ?
        annotations: {},
        indicators: {},
      }
    })

    // Remove unecessary tickers from the backend
    backendTickers.forEach(t => {
      if (allRelevantTickers.includes(t)) {return}
      delete newCharts.t
    })

    db.collection('users').doc(loggedInUser.uid).update({ charts: newCharts })
  }, [loggedInUser])


  /* KEYBOARD LISTENER */
  useEffect(() => {
    const handleKeydown = async (e) => {
      const ts = new Date().getTime().toString()
      // registerUsage()
      if (e.key === 'j' && e.metaKey) {
        e.preventDefault()
        setCmdK(ts)
      }
      else if (e.key === 's' && e.metaKey) {
        e.preventDefault()
        setCmdS(ts)
      }
      // else if (e.key === 't' && e.metaKey) {
      //   e.preventDefault()
      //   setCmdT(ts)
      // }
      // else if (e.key === 'o' && e.metaKey) {
      //   e.preventDefault()
      //   setCmdO(ts)
      // }
      
      // else if (e.key === 'p' && e.metaKey) {
      //   console.log('cmd + p')
      // } else if (e.key === '1' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('home')
      // } else if (e.key === '2' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('couplets')
      // } else if (e.key === '3' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('custom_data')
      // } else if (e.key === '4' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('logs')
      // } else if (e.key === '5' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('portfolio')
      // } else if (e.key === '6' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('account')
      // } else if (e.key === '7' && e.metaKey) {
      //   e.preventDefault()
      //   setAppTab('help')
      // } else if (e.key === 'Backspace' || e.key === 'Delete') {
      //   if (selectedAnnotation && selectedChart) {
      //     // Delete annotation, if selected
      //     await delete_annotation({
      //       id: selectedAnnotation.id,
      //       user: loggedInUser,
      //       selectedChart,
      //     })
      //   }
      // } else if (e.key === 'Escape') {
      //   if (selectedAnnotation) {
      //     setSelectedAnnotation(null)
      //   }
      //   setAnnotationInProgress(null)
      //   setToolMode(null)
      // }
    }
    document.addEventListener('keydown', handleKeydown)
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])


  /* MOUSE LISTENER */
  useEffect(() => {
    const handleClick = (event) => {
      registerUsage()
    }
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  /* WINDOW RESIZE */
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < SMALL_SCREEN_THRESHOLD);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /*
  APP LAYOUT
  */

  /* LOADING SCENARIO */
  if (loading) {
    return (
      <div className="App">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            margin: '5px',
            height: '100%',
            border: `1px solid ${GREEN}`,
            borderRadius: 5,
            backgroundColor: BACKGROUND,
            color: ORANGE,
            fontSize: 20,
            fontWeight: 'bold'
          }}
        >
          <div className='column center align-center loading-screen'>
            <div className='row align-center'>
              <div>
                Tickerbot
              </div>
              <Icon
                className='margin-top-5'
                icon={'arrow-trend-up'}
                set='sharp-solid'
                size={24}
              />
            </div>
            <Icon
              className='margin-top-10'
              icon='spinner'
              set='sharp-solid'
              size={18}
              spin={true}
            />
          </div>
        </div>
      </div>
    )
  }

  if (isSmallScreen) {
    return (
      <div className="App">
        <LoggedOutMenuBar />
        <div className='body'>
          {openModal}
          <div className='logged-out-screen'>
            <div className='signup-container'>
              <div className='signup-form-container'>
                <div className='signup-context-title center'>
                  Small screen detected.
                </div>
                <div className='signup-context-subtitle center'>
                  Make the screen wider.
                </div>
                <div className='signup-context-subtitle center gray-text margin-top-40' style={{fontSize: 11, fontStyle: 'italic'}}>
                  Tickerbot is currently designed for wide screens only.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /* LOGGED OUT SCENARIO */
  if (!fireBaseToken) {
    switch (loggedOutTab) {
      case 'sign_up':
        return (
          <div className="App">
            <LoggedOutMenuBar />
            <div className='body'>
              {openModal}
              <SignUp subscribeToUserUpdates={subscribeToUserUpdates} />
            </div>
          </div>
        );
      case 'log_in':
        return (
          <div className="App">
            <LoggedOutMenuBar />
            <div className='body'>
              {openModal}
              <Login subscribeToUserUpdates={subscribeToUserUpdates} />
            </div>
          </div>
        )
      case 'reset_pw':
        return (
          <div className="App">
            <LoggedOutMenuBar />
            <div className='body'>
              {openModal}
              <ResetPassword />
            </div>
          </div>
        )
      case 'reset_pw_confirmation':
        return (
          <div className="App">
            <LoggedOutMenuBar />
            <div className='body'>
              {openModal}
              <ResetPasswordConfirmationScreen />
            </div>
          </div>
        )
    }
  }

  /* EMAIL NOT VERIFIED SCENARIO */
  if (!emailVerified) {
    return (
      <div className="App">
        <LoggedOutMenuBar />
        <div className='body'>
          {openModal}
          <div className='logged-out-screen'>
            <div className='signup-container'>
              <div className='signup-form-container'>
                <div className='signup-context-title center'>
                  Verify your email to get started.
                </div>
                <div className='signup-context-subtitle center'>
                  Check your inbox and follow the instructions.
                </div>
                <Button
                  display='Resend verification email'
                  className='margin-top-20'
                  style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
                  isLoading={emailVerBtnLoading}
                  onClick={ async () => {
                    try {
                      setEmailVerBtnLoading(true)
                      await currentUser()?.sendEmailVerification({
                        url: 'http://localhost:3000'
                      })
                      setEmailVerBtnLoading(false)
                    } catch (err) {
                      setEmailVerBtnLoading(false)
                    }
                  }}
                />
                <div className='signup-context-subtitle center gray-text margin-top-40' style={{fontSize: 11, fontStyle: 'italic'}}>
                  Need help? Refresh the page or contact support.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /* TICKERBOT ACCOUNT BUT NO ALPACA ACCOUNT */
  if (!hasAppAccess) {
    return (
      <div className="App">
        <LoggedOutMenuBar />
        <div className='body'>
          {openModal}
          <AlpacaSignUp />
        </div>
      </div>
    )
  }

  /* ALL SYSTEMS GO */
  return (
    <div className="App">
      <MenuBar />
      <div className={'body'}>
        {openModal}
        <AppTabs />
        <TabContent />
      </div>
    </div>
  )
}

/* MAIN CONTENT BASED ON TAB */
const TabContent = () => {
  const [ loggedInUser ] = useAtom(loggedInUserAtom)
  const [appTab] = useAtom(appTabAtom)

  useEffect(() => {
    const returnSplitToDefault = () => {
      db.collection('users').doc(loggedInUser?.uid).update({ centerPaneDividerSplit: [70, 30] });
    };

    const gutterElement = document.querySelector('.main-col-split .gutter-vertical');

    if (gutterElement) {
      gutterElement.addEventListener('dblclick', returnSplitToDefault);
    }

    return () => {
      if (gutterElement) {
        gutterElement.removeEventListener('dblclick', returnSplitToDefault);
      }
    };
  }, [loggedInUser]);

  let mainColClass = 'main-col'
  if (loggedInUser?.rightPaneClosed && loggedInUser?.leftPaneClosed) {
    mainColClass += ' left-and-right-closed'
  }
  if (loggedInUser?.rightPaneClosed && !loggedInUser?.leftPaneClosed) {
    mainColClass += ' right-closed'
  }
  if (!loggedInUser?.rightPaneClosed && loggedInUser?.leftPaneClosed) {
    mainColClass += ' left-closed'
  }

  if (!loggedInUser) return null

  switch (appTab) {
    case 'home':
      return (
        <div className='tab-col'>
          {loggedInUser?.leftPaneClosed ? null : <LeftPane />}
          <div className={mainColClass}>
            <Split
              className={loggedInUser.bottomPaneClosed ? 'main-col-split no-bottom-pane' : 'main-col-split'}
              direction='vertical'
              sizes={loggedInUser.bottomPaneClosed ? [100, 0] : loggedInUser?.centerPaneDividerSplit || [70, 30]}
              minSize={loggedInUser.bottomPaneClosed ? 0 : 37}
              gutterSize={loggedInUser.bottomPaneClosed ? 0 : 5}
              gutterAlign='center'
              cursor='row-resize'
              onDragEnd={(val) => {
                db.collection('users')
                  .doc(loggedInUser.uid)
                  .update({centerPaneDividerSplit: val})
              }}
            >
              <ChartDividerPane />
              {loggedInUser.bottomPaneClosed ? null : <BottomPane />}
            </Split>
          </div>
          {loggedInUser?.rightPaneClosed ? null : <RightPane />}
        </div>
      )
    case 'functions':
      return (
        <div className='tab-col'>
          {loggedInUser.functionsTreeClosed ? null : <FunctionsTree />}
          <FunctionsTab />
        </div>
      )
    case 'logs':
      return (
        <div className='tab-col'>
          <LogsTab />
        </div>
      )
    case 'watchlists':
      return (
        <div className='tab-col'>
          {loggedInUser.watchlistsTreeClosed ? null : <WatchlistsTree />}
          <WatchlistTab />
        </div>
      )
    case 'portfolio':
      return (
        <div className='tab-col'>
          <PortfolioTab />
        </div>
      )
    case 'account':
      return (
        <div className='tab-col'>
          <AccountTab />
        </div>
      )
    case 'admin':
      return (
        <div className='tab-col'>
          <AdminTab />
        </div>
      )
    default:
      return (
        <div className='tab-col'>
        </div>
      )
  }
}


export default App;
