import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  selectedCodeEditorSlotAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { DividerPaneIcon } from '../reusable/DividerPaneIcon'
import { CreateFunctionModal } from '../modals/CreateFunctionModal'
import { DuplicateFunctionModal } from '../modals/DuplicateFunctionModal'
import { DeleteFunctionModal } from '../modals/DeleteFunctionModal'
import { FunctionObj } from '../../types/user_types'
import { TEXT_GRAY } from '../../logic/u'

interface FunctionOptionsDropdownProps {
  functionObj: FunctionObj
  codeEditorSlot: number
  left: number
  top: number
  onClose: () => void
}

export const FunctionOptionsDropdown = (props: FunctionOptionsDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)

  const {functionObj, codeEditorSlot, left, top, onClose} = props

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  let contents: DropdownItem[] = []
  contents = [
    // {
    //   display: (
    //     <div className='left'>
    //       <Icon
    //         icon='square-pen'
    //         set='sharp-solid'
    //         size={9}
    //         style={{marginRight: 10}}
    //       />
    //       <div>Update</div>
    //     </div>
    //   ),
    //   onClick: () => {
    //     setOpenModal(<CreateFunctionModal functionObj={functionObj} />)
    //   }
    // },
    {
      display: (
        <div className='left'>
          <Icon
            icon='copy'
            set='sharp-solid'
            size={10}
            style={{marginRight: 10}}
          />
          <div>Duplicate</div>
        </div>
      ),
      onClick: async () => {
        setOpenModal(<DuplicateFunctionModal functionObj={functionObj} />)
      }
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='trash'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10}}
          />
          <div>Delete</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<DeleteFunctionModal functionObj={functionObj} />)
      }
    },
    {
      display: (
        <div
          style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
        ></div>
      )
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='circle-x'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10}}
          />
          <div>Clear editor slot</div>
        </div>
      ),
      onClick: () => {
        setSelectedCodeEditorSlot(codeEditorSlot)
        if (codeEditorSlot === 1) {
          db.collection('users').doc(user?.uid).update({ codeEditorSlot1: '' })
          return
        }
        if (codeEditorSlot === 2) {
          db.collection('users').doc(user?.uid).update({ codeEditorSlot2: '' })
        }
      }
    },
  ]

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={175}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}