/*
Wrapper on fontawesome pro, allows a second icon to be substituted on hover
*/

import './Icon.scss';

import React from 'react';
import { ReactSVG } from "react-svg";



export interface IconProps {
  icon: string
  set?: 'regular' | 'light' | 'solid' | 'thin' | 'duotone' | 'brands' | 'sharp-solid' | 'tickerbot'
  hoverSet?: 'regular' | 'light' | 'solid' | 'thin' | 'duotone' | 'brands' | 'sharp-solid'
  variant?: 'default' | 'primary' | 'gray'
  style?: any
  size?: number
  className?: string
  hoverStyle?: any
  hoverText?: string
  color?: string
  spin?: boolean
  onClick?: (e: any) => void
}

export function Icon(props: IconProps) {
  const set = props.set || 'regular'
  const src = `fa/${set}/${props.icon}.svg`

  const size = props.size || 16
  const variant = props.variant || ''
  const style = structuredClone(props.style) || {}
  let hoverStyle = structuredClone(props.hoverStyle) || {}
  hoverStyle.cursor = 'pointer'

  let className = 'icon'
  if (props.className) {
    className += ' ' + props.className
  }
  if (props.onClick) {
    className += ' is-button'
  }
  if (props.spin) {
    className += ' spin'
  }
  // Apply size
  style.width = size
  style.height = size

  hoverStyle = {...style, ...hoverStyle}

  if (!props.hoverSet) {
    return (
      <ReactSVG
        className={className}
        title={props.hoverText}
        style={style}
        src={src}
        onClick={props.onClick}
      />
    )
  }
    // If hoverSet specified, render two icons and swap on hover
    else {
      const hoverSrc = `fa/${props.hoverSet}/${props.icon}.svg`
      return (
        <div className={`icon ${props.className || ''} ${variant}`} >
          <ReactSVG
            className={'main'}
            title={props.hoverText}
            style={style}
            src={src}
            onClick={props.onClick}
            />
          <ReactSVG
            className={'alt'}
            title={props.hoverText}
            style={hoverStyle}
            src={hoverSrc}
            onClick={props.onClick}
          />
        </div>
      )
    }
}
