import React, { useState } from 'react'

import { PositionsTab } from './PositionsTab';
import { BalancesTab } from './BalancesTab';
import { OrdersTab } from './OrdersTab';
import { TransfersTab } from './TransfersTab';
import { TransactionsTab } from './TransactionsTab';
import { LogsTabTable } from './LogsTabTable';

export function LogsTab() {

  return (
    <div className='tab-parent'>
      <div className='tab-header'>
        <div>
          Logs
        </div>
      </div>
      <LogsTabTable />
    </div>
  )
}