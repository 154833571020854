import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatDateForTable,
  formatNumberForTable,
  valueForTable,
} from '../../logic/u'
import { currentUser, db } from '../../firebase'
import {
  loggedInUserAtom,
} from '../../types/global_types'
import { EvaluationRow } from '../reusable/EvaluationRow';

export function LogsTabTable() {
  const [user] = useAtom(loggedInUserAtom)
  const [logs, setLogs] = useState<any[] | null>(null)
  const defaultSortModel = [
    {
      field: 'time',
      sort: 'desc' as GridSortDirection
    },
  ];
  const [sortModel, setSortModel] = useState(defaultSortModel);

  // Load first 100 log records, and stay up to date
  useEffect(() => {
    let isSubscribed = true; // Flag to manage cleanup
    console.log('**** USE EFFECT RUNNING AGAIN ****');

    const init = async () => {
      try {
        // Assuming `currentUser` is an async function that returns a user object or null
        const user = await currentUser();
        if (!user) throw new Error("No user found");

        const uid = user.uid; // Extract uid from the user object

        const query = db
          .collection('users')
          .doc(uid)
          .collection('EELogs')
          .orderBy('time', 'desc')
          .limit(10);

        const snapshot = await query.get();
        const initialLogs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        if (isSubscribed) {
          setLogs(initialLogs);
        }

        // If needed, initiate subscriptions or other actions that use uid here
        const unsubscribe = subscribeToNewLogs(uid);
        return unsubscribe;
      } catch (error) {
        console.error(error);
      }
    };

    // Define the function to subscribe to new logs, now expecting uid as a parameter
    const subscribeToNewLogs = (uid) => {
      console.log('Subscribing to new logs');
      const query = db
        .collection('users')
        .doc(uid)
        .collection('EELogs')
        .orderBy('time', 'desc')
        .limit(10);

      return query.onSnapshot(snapshot => {
        if (!isSubscribed) return;

        snapshot.docChanges().forEach(change => {
          if (change.type === "added" || change.type === "modified") {
            const doc = change.doc;
            const newLog = { id: doc.id, ...doc.data() };

            setLogs(prevLogs => {
              const existingIndex = prevLogs?.findIndex(log => log.id === newLog.id) ?? -1;
              if (existingIndex > -1) {
                const updatedLogs = [...(prevLogs ?? [])]; // Use empty array if prevLogs is null
                updatedLogs[existingIndex] = newLog;
                return updatedLogs;
              }
              return [newLog, ...(prevLogs ?? [])]; // Use empty array if prevLogs is null
            });
          }
        });
      });
    };

    init();

    // Cleanup function for useEffect
    return () => {
      isSubscribed = false;
    };
  }, []);

  if (!user) return null

  const logRows: any[] = logs || []

  const getOverlay = () => {
    if (!logs) {
      return (
        <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
          <div>Loading logs...</div>
        </GridOverlay>
      )
    }
    return (
      <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
        <div>No logs.</div>
      </GridOverlay>
    )
  }

  const handleSorting = (v1: string | number | Date, v2: string | number | Date): number => {
    // Handle '-' values
    if (v1 === '-' && v2 !== '-') {
      return -1;
    }
    if (v1 !== '-' && v2 === '-') {
      return 1;
    }

    // Custom sorting for dates
    if (typeof v1 === 'string' && typeof v2 === 'string' && Date.parse(v1) && Date.parse(v2)) {
      return new Date(v1).getTime() - new Date(v2).getTime();
    }

    // Default sorting logic for numbers and strings
    return Number(v1) - Number(v2);
  };


  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  }

  return (
    <div className='tab-contents'>
      <div className='table-container'>
        <DataGridPro
          rows={logRows}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 36}
          columns={[
            {
              field: 'time',
              headerName: 'date',
              width: 175,
              type: 'string',
              sortComparator: handleSorting,
              valueGetter: (params) => formatFirebaseDate(params.row.time)
            },
            {
              field: '',
              headerName: 'message',
              flex: 1,
              headerAlign: 'left',
              align: 'left',
              type: 'date',
              sortComparator: handleSorting,
              renderCell: (params) => {
                return <div className='outer-eval-row'>
                  <div className='row space-between'>
                  </div>
                  <EvaluationRow log={params.row}  />
                </div>  
              }
            },
            {
              field: 'equity',
              headerName: 'equity',
              width: 100,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleSorting,
              valueGetter: (params) => valueForTable('equity', params.row._liveData?.BALANCES?.equity)
            },
            {
              field: 'mkt_value',
              headerName: 'mkt_value',
              width: 100,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleSorting,
              valueGetter: (params) => valueForTable('equity', params.row._liveData?.BALANCES?.mkt_value)
            },
            {
              field: 'day_unrealized_pl',
              headerName: 'day_unrealized_pl',
              width: 100,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleSorting,
              valueGetter: (params) => valueForTable('pl', params.row._liveData?.BALANCES?.day_unrealized_pl)
            },
            {
              field: 'day_unrealized_pl_pc',
              headerName: 'day_unrealized_pl_pc',
              width: 100,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleSorting,
              valueGetter: (params) => valueForTable('pl_pc', params.row._liveData?.BALANCES?.day_unrealized_pl)
            },
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          components={{
            NoRowsOverlay: getOverlay,
          }}
          sx={{
            ...tableSX,
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { pt: '5px', pb: '8px' },
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total logs:',
          }}
        />
      </div>

    </div>
  )
}

const formatFirebaseDate = (date: any) => {
  const d = new Date(date?.seconds * 1000)
  return formatDateForTable(d)
}


