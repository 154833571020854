/*
Function-based rewrite using Jotai. Use showModal for simple modals, Modal component directly
if you need things to update based on global state.

Generic modal, accepts contents. Functions more or less directly as Alert or Confirm.
*/

import './Modal.scss';

import { useAtom } from 'jotai'
import { Button, ButtonProps } from './Button'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { openModalAtom } from '../../types/global_types'
import React, { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icon } from './Icon';


export interface ModalComponentProps {
  title: string
  contents: any
  className?: string
  yesButtonText?: string
  onYes?: () => string | any
  onClose?: () => void
  cancelButtonText?: string     // can be used as cancel or secondary really (appears to left of yes btn)
  cancelOnClick?: () => string | any
  noCloseButton?: boolean
  dontCloseOnYes?: boolean
  loadingText?: string        // replaced by isButtonLoading
  isDanger?: boolean
  style?: any
  isVisible?: boolean                               // use these two for traditional
  setIsVisible?: (isVisible: boolean) => void       // opening and closing
  setOpenModal?: (modal: any) => void
  isButtonDisabled?: boolean
  isButtonLoading?: boolean
  scrollableBody?: boolean
  twoStepConfirmation?: boolean   // shouldnt be used with cancelButtonText
  preventDefaultOnClose?: boolean  // use this for modal-on-PE situations, see ConditionsSubtab
  secondaryButtonText?: string    // appears on top of yes btn
  secondaryOnClick?: () => void
  secondaryButtonVariant?: ButtonProps['variant']
  trashIconOnClick?: () => void
  backIconOnClick?: () => void

}

export const Modal = (props: ModalComponentProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ showTwoStepConfirmationBtns, setShowTwoStepConfirmationBtns ] = useState<boolean>(false)
  const yesBtnRef = React.createRef()
  // const secondaryYesBtnRef = React.createRef()


  const close = useCallback(() => {
    if (props.setIsVisible) {
      props.setIsVisible(false)
    }
    if (!props.preventDefaultOnClose) {
      setOpenModal(null)
    }
    if (props.onClose) {
      props.onClose()
    }
  }, [setOpenModal, props.setIsVisible, props.onClose])


  // TODO CLEAN THIS UP
  const yes = useCallback(async () => {
    // Something to do?
    if (props.onYes) {

      // Execute onYes
      const em = await props.onYes()

      // If onYes fails, we show the error message and let the user try again
      if (em) {
        setErrorMessage(em)
        return
      }
    }

    // By default we close
    if (!props.dontCloseOnYes) {
      close()
    }
  }, [close, props.dontCloseOnYes, props.onYes])

  /* KEYBOARD LISTENER */
  useEffect(() => {
    const handleKeydown = async (e) => {
      if (props.isButtonDisabled || props.isButtonLoading) {
        return
      }
      if (e.key === 'Escape') {
        close()
      }
      if (e.key !== 'Enter') return
      if (props.twoStepConfirmation && !showTwoStepConfirmationBtns) {
        setShowTwoStepConfirmationBtns(true)
        return
      }

      // Prevent enter in dropdown from closing modal
      setTimeout(() => {
        //@ts-ignore
        const defaultLastPrevented = window.defaultLastPrevented || 0
        const timeSinceDLP = Date.now() - defaultLastPrevented
        if (timeSinceDLP < 500) {
          return
        }
        if (e.key === 'Escape') {
          close()
        } else if (e.key === 'Enter') {
          yes()
        }
      }, 10)
    }
    document.addEventListener('keydown', handleKeydown)

    // Clear event listener on unmount
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [close, yes, props.twoStepConfirmation, showTwoStepConfirmationBtns])

  useEffect(() => {
    if (props.isButtonLoading || props.isButtonDisabled) {
      setShowTwoStepConfirmationBtns(false)
    }
  }, [props.isButtonLoading, props.isButtonDisabled])


  const ButtonRow = () => {
    let primaryButtonsRow: any = []

    let yesButtonVariant = 'primary'
    if (props.isDanger) {
      yesButtonVariant = 'danger'
    }

    // Assemble primary buttons row
    // Don't use cancel with 2step confirm
    if (props.cancelButtonText && !props.twoStepConfirmation) {
      primaryButtonsRow.push(<Button
        display={props.cancelButtonText}
        onClick={() => {
          if (props.cancelOnClick) {
            props.cancelOnClick()
          } else {
            close()}
          }
        }
        variant={'secondary'}
      />)
    }
    if (primaryButtonsRow.length && props.yesButtonText) {
      primaryButtonsRow.push(<div className={'spacer'}></div>)
    }
    if (props.yesButtonText) {
      primaryButtonsRow.push(
        <Button
          display={props.yesButtonText}
          onClick={async () => {
            if (props.twoStepConfirmation) {
              setShowTwoStepConfirmationBtns(true)
            } else {
              await yes()
            }
          }}
          //@ts-ignore
          variant={yesButtonVariant}
          ref={yesBtnRef}
          isDisabled={props.isButtonDisabled}
          isLoading={props.isButtonLoading}
          loadingText={props.loadingText}
        />
      )
    }

    return (
      <div className={'bottom-row'}>
        <div className='button-row'>
          {primaryButtonsRow}
        </div>
      </div>
    )
  }

  const TwoStepButtonRow = () => {

    let yesButtonVariant = 'primary'
    if (props.isDanger) {
      yesButtonVariant = 'danger'
    }

    const twoStepBtnDiv = (
      <div className='row space-between align-center width-full'>
        <div className='font-size-11 gray-text italic'>
          Are you sure?
        </div>
        <div className='row space-between'>
          <Button
            display={'No'}
            onClick={() => {
              setShowTwoStepConfirmationBtns(false)
            }}
            variant={'secondary'}
            style={{marginRight: 10, width: 60}}
          />
          <Button
            display={'Yes'}
            onClick={async () => {
              setShowTwoStepConfirmationBtns(false)
              await yes()
            }}
            //@ts-ignore
            variant={yesButtonVariant}
            ref={yesBtnRef}
            isDisabled={props.isButtonDisabled}
            isLoading={props.isButtonLoading}
            loadingText={props.loadingText}
            style={{width: 60}}
          />
        </div>
      </div>
    )
    return (
      <div className={'bottom-row'}>
        <div className='button-row'>
          {twoStepBtnDiv}
        </div>
      </div>
    )
  }

  const SecondaryButtonRow = () => {
    let buttonsRow: any = []

    if (props.secondaryButtonText) {
      buttonsRow.push(
        <Button
          display={props.secondaryButtonText}
          onClick={async () => {
            if (props.secondaryOnClick) {
              await props.secondaryOnClick()}}
            }
          variant={props.secondaryButtonVariant || 'secondary'}
        />
      )
    }

    return (
      <div className={'bottom-row secondary'}>
        <div className='button-row'>
          {buttonsRow}
        </div>
      </div>)
  }



  const ErrorMessage = () => {
    if (errorMessage) {
      return <div className={'error-message'}>{errorMessage}</div>
    } else {
      return null
    }
  }


  /* COMPONENT PROPER */
  if (props.isVisible === false) {
    return null
  }

  let closeButton: any = (
    <Icon
      icon={'xmark'}
      set='sharp-solid'
      size={10}
      onClick={() => close()}
    />
  )
  if (props.noCloseButton) {
    closeButton = null
  }

  let classList = 'modal'
  if (props.className) {
    classList += ' ' + props.className
  }

  let classBody = props.scrollableBody ? 'modal-body scrollable' : 'modal-body'

  let hasButtonRow = props.yesButtonText || props.cancelButtonText

  let hasSecondaryRow = props.secondaryButtonText

  return (
    <>
      <div
        className={'modal-scrim'}
        onClick={() => close()}
      />
      <div className={classList} style={props.style}>
        <div className={'top'}>
          <div className={'title-row'}>
            <div className='row'>
              {props.backIconOnClick && <Icon
                icon='arrow-left'
                set='sharp-solid'
                size={16}
                style={{marginTop: 2}}
                className='margin-right-10'
                onClick={() => {
                  if (props.backIconOnClick) {
                    props.backIconOnClick()
                  }
                }}
              />}
              {props.title}
            </div>
            <div className='row' style={{marginTop: -4}}>
              {props.trashIconOnClick && <Icon
                icon='trash'
                set='sharp-solid'
                size={10}
                style={{marginTop: -3}}
                className='margin-right-15'
                onClick={() => {
                  if (props.trashIconOnClick) {
                    props.trashIconOnClick()
                  }
                }}
              />}
              {closeButton}
            </div>

          </div>
          <div className={classBody}>{props.contents}</div>
        </div>
        <ErrorMessage />
        {hasSecondaryRow ? <SecondaryButtonRow /> : null}
        {hasButtonRow ? (
          showTwoStepConfirmationBtns ? (
            <TwoStepButtonRow />
          ) : (
            <ButtonRow />
          )
        ) : (
          null
        )}
      </div>
    </>
  )

}



// Experimenting with this format
export function showModal(props: ModalComponentProps) {
  if (!props.setOpenModal) {
    throw new Error('must pass setOpenModal')
  }
  props.setOpenModal(<Modal {...props}/>)
}
