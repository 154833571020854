import './Watchlists.scss'
import { useAtom } from 'jotai';
import { loggedInUserAtom, userLiveDocAtom, openModalAtom } from '../../types/global_types';
import { Icon, Modal } from '../reusable'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db } from '../../firebase';
import { GREEN_PARTIAL, RED_PARTIAL, BACKGROUND, uuid, formatDateForTable, timeAgo } from '../../logic/u';
import { WatchlistObj } from '../../types/user_types';
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal';

export const WatchlistsTree = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom);
  const [userLiveDoc] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [watchlists, setWatchlists] = useState<WatchlistObj[]>([])
  const [validDrop, setValidDrop] = useState<boolean>(false)

  // Render tree
  useEffect(() => {
    setWatchlists(user?.watchlists || [])
  }, [user])

  const onDragUpdate = (update) => {
    const { source, destination } = update
    console.log(update)
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination, type } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let watchlistsClone: WatchlistObj[] = Array.from(watchlists) || []
    const [reorderedItem] = watchlistsClone.splice(source.index, 1) // remove dragged item
    watchlistsClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setWatchlists(watchlistsClone) // local state
    db.collection('users').doc(user?.uid).update({ watchlists: watchlistsClone });
  }

  // NOTE: if liveData hasn't loaded, sidebar will be missing
  if (!user) return null
  if (!userLiveDoc || !userLiveDoc.liveData) return null

  return (
    <div className='watchlists-tree'>
      <div className='header'>
        <div>
          Watchlists
        </div>
        <div>
          <Icon
            icon='plus'
            set='sharp-solid'
            size={12}
            style={{marginTop: -.5}}
            onClick={() => {
              setOpenModal(<CreateWatchlistModal />)
            }}
          />
        </div>
      </div>
      <div className='watchlists-list-container'>
        {!user.watchlists?.length ? <div
          style={{marginTop: 10, marginLeft: 10, fontSize: 11.5}}
        >
          Create Watchlist
        </div> : null}
        <DragDropContext
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
          key={'modal-content'}
        >
          <div className='droppable-container'>
            <Droppable droppableId={'1'} type='bucket'>
              {(provided, snapshot) => (
                <div
                  className='draggable-container'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {watchlists.map((watchlistObj: WatchlistObj, index) => {

                    let parentClass = 'entry'
                    if (user.selectedWatchlistIdWatchlistsTab === watchlistObj.id) {
                      parentClass += ' selected'
                    }

                    let formatted_date = '-'
                    let time_ago = '-'

                    if (watchlistObj.updatedAt.toDate?.()) {
                      const date =  watchlistObj.updatedAt.toDate()
                      time_ago = timeAgo(date)
                      formatted_date = formatDateForTable(date)
                    }

                    return (
                      <Draggable
                        key={watchlistObj.id}
                        draggableId={watchlistObj.id}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              className={parentClass}
                              title={watchlistObj.name}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                              }}
                              onClick={async () => {
                                await db.collection('users').doc(user.uid).update({selectedWatchlistIdWatchlistsTab: watchlistObj.id})
                              }}
                            >
                              <div className='column'>
                                <div className={'row space-between'}>
                                  <div className='row'>
                                    <Icon
                                      icon={'square-list'}
                                      set='sharp-solid'
                                      size={12}
                                      className={'key-icon'}
                                      style={{marginTop: 2, marginRight: 5}}
                                    />
                                    <div className='watchlist-title'>
                                      {watchlistObj.name}
                                    </div>
                                  </div>
                                  <div>{watchlistObj.tickers.length === 1 ? '1 ticker' : watchlistObj.tickers.length + ' tickers'}</div>
                                </div>
                                <div className='row space-between width-full font-size-11' style={{marginTop: 1}}>
                                  <div title={formatted_date}>{time_ago}</div>
                                  <div className='icon-container'>
                                    <Icon 
                                      icon='grip-dots-vertical'
                                      size={7}
                                      className='grip-icon'
                                    />
                                  </div>
                                </div>

                              </div>
                            </div>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    </div>
  )
}