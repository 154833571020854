import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
} from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../types/global_types'
import { getConfig } from '../../config'
import { PositionDetailsModal } from '../modals/PositionDetailsModal'
const config = getConfig() as any

export function PositionsTab() {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const defaultSortModel = [
    {
      field: 'symbol',
      sort: 'asc' as GridSortDirection
    },
  ];
  const [sortModel, setSortModel] = useState(defaultSortModel);

  if (!user) return null
  if (!uld) return null


  let positionRows: any[] = []
  Object.keys(uld.liveData?.POSITIONS).forEach(key => {
    const position = uld.liveData?.POSITIONS[key]
    positionRows.push({...position, id: uuid()})
  })

  const getOverlay = () => {
    // unnecessary?
    if (!uld.liveData) {
      return (
        <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
          <div>Loading positions...</div>
        </GridOverlay>
      )
    }
    return (
      <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
        <div>No positions.</div>
      </GridOverlay>
    )
  }

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  return (
    <div className='tab-contents has-subtab'>
      <div className='summary-table'>
        <div>
          Summary
        </div>
        <div className='row'>
          <div className='summary-bit'>
            <div>mkt_value</div>
            <div className='val-thing'>{valueForTable('mkt_value', uld.liveData.BALANCES.mkt_value)}</div>
          </div>
          <div className='summary-bit'>
            <div>unrealized P/L since yesterday</div>
            <div className='val-thing'>
              <div className=''>{valueForTable('day_unrealized_pl', uld.liveData.BALANCES.day_unrealized_pl)}</div>
              <div className=''>{'[' + valueForTable('day_unrealized_pl_pc', uld.liveData.BALANCES.day_unrealized_pl_pc) + ']'}</div>
            </div>
          </div>
          <div className='summary-bit last-thing'>
            <div>overall unrealized P/L</div>
            <div className='val-thing'>
              <div className=''>{valueForTable('unrealized_pl', uld.liveData.BALANCES.unrealized_pl)}</div>
              <div className=''>{'[' + valueForTable('unrealized_pl_pc', uld.liveData.BALANCES.unrealized_pl_pc) + ']'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='table-container'>
        <DataGridPro
          className='clickable-grid'
          rows={positionRows}
          columns={[
            {
              field: 'symbol',
              headerName: 'symbol',
              flex: 0.5,
              type: 'string',
              sortComparator: handleMUISorting,
              valueGetter: (params) => params.row.symbol
            },
            {
              field: 'qty',
              headerName: 'qty',
              flex: 0.5,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => roundToSigFigs(params.row.qty, params.row.avg_entry_price)
            },
            {
              field: 'current_price',
              headerName: 'current_price',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => formatNumberForTable(params.row.current_price)
            },
            {
              field: 'avg_entry_price',
              headerName: 'avg_entry_price',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => formatNumberForTable(params.row.avg_entry_price)
            },
            {
              field: 'cost_basis',
              headerName: 'cost_basis',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => formatNumberForTable(params.row.cost_basis)
            },
            {
              field: 'mkt_value',
              headerName: 'mkt_value',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div className={'orange-text'}>
                  {formatNumberForTable(params.row.mkt_value)}
                </div>
              )
            },
            {
              field: 'day_unrealized_pl',
              headerName: 'day_unrealized_pl',
              flex: 1.25,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div className={params.row.day_unrealized_pl >= 0 ? ' pos' : ' neg'}>
                  {formatNumberForTable(params.row.day_unrealized_pl)}
                </div>
              )
            },
            {
              field: 'day_unrealized_pl_pc',
              headerName: 'day_unrealized_pl_pc',
              flex: 1.3,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div className={params.row.day_unrealized_pl >= 0 ? ' pos' : ' neg'}>
                  {formatPercent(params.row.day_unrealized_pl_pc)}
                </div>
              )
            },
            {
              field: 'unrealized_pl',
              headerName: 'unrealized_pl',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div className={params.row.unrealized_pl >= 0 ? ' pos' : ' neg'}>
                  {formatNumberForTable(params.row.unrealized_pl)}
                </div>
              ),
            },
            {
              field: 'unrealized_pl_pc',
              headerName: 'unrealized_pl_pc',
              flex: 1.2,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div className={params.row.unrealized_pl >= 0 ? ' pos' : ' neg'}>
                  {formatPercent(params.row.unrealized_pl_pc)}
                </div>
              )
            }

          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: getOverlay,
          }}
          sx={tableSX}
          onRowClick={(params) => setOpenModal(<PositionDetailsModal symbol={params.row.symbol} />)}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total positions:',
          }}
        />
      </div>
    </div>
  )
}