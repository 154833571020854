import { atom } from 'jotai'
import { FunctionObj, UnsavedEditorObj, User, UserLiveDoc, WatchlistObj } from './user_types'

export type LoggedOutTabType =
  | 'sign_up'
  | 'log_in'
  | 'reset_pw'
  | 'reset_pw_confirmation'

export type appTabType =
  | 'home'
  | 'functions'
  | 'logs'
  | 'watchlists'
  | 'portfolio'
  | 'admin'
  | 'account'
  | 'support'

export type ToolModeType =
| null
| 'trendline'

export type SelectedAnnotationType = {
  id: string
  point: number | null
  xVal: number | null
  yVal: number | null
  xDate: string | null
  yPrice: number | null
}

/* JOTAI ATOMS - GLOBAL STATE */

/* LAYOUT */
export const loggedOutTabAtom = atom<LoggedOutTabType>('log_in')
export const appTabAtom = atom<appTabType>('home')
export const resetPasswordEmail = atom<string>('')
export const openModalAtom = atom<any>(null)
export const ohlcAtom = atom<any>(null)

/* USER & LIVE DATA */
export const loggedInUserAtom = atom<User | null>(null)
export const userLiveDocAtom = atom<UserLiveDoc | null>(null)
export const liveDataAtom = atom<any>([])
export const activeRunsAtom = atom<any>({})
export const updatesPausedAtom = atom<boolean>(false)
export const loadingAtom = atom<boolean>(true)
export const consoleOutputAtom = atom<any>([])
export const functionExecutingAtom = atom<boolean>(false)

/* ALPACA ORDERS */
export const ordersAtom = atom<any>({})

/* ALPACA TRANSFERS */
export const transfersAtom = atom<( any[] | null)>(null)

/* KEYBOARD SHORTCUTS */
export const cmdKAtom = atom<string>('')
export const cmdSAtom = atom<string>('')
export const cmdOAtom = atom<string>('')
export const cmdTAtom = atom<string>('')

/* MISC */
export const allSymbolsAtom = atom<any>([])   // all charts/tradable assets

/* FUNCTIONS */
export const selectedCodeEditorSlotAtom = atom<number | null>(null)
export const functionsWithUnsavedChangesAtom = atom<UnsavedEditorObj>({})

/* TODO MARC CLEANUP */
export const userFunctionsAtom = atom<any>({})
export const algosAtom = atom<any[]>([])
export const chartIsHoveredAtom = atom<boolean>(false)
export const loadedBacktestAtom = atom<any>(null)
export const viewAggregateAtom = atom<boolean>(false)
export const chartsListAtom = atom<any>([])

export const activeChartAtom = atom<string>('AAPL')
export const toolModeAtom = atom<ToolModeType>(null)
export const selectedChartAtom = atom<string | undefined>(undefined)
export const isDraggingAtom = atom<boolean>(false)        // dragging chart
export const refreshChartAtom = atom<number>(0)

export const dsPythonAtom = atom<string>('')
export const intervalAtom = atom<string>('1h')

export const selectedAnnotationAtom = atom<SelectedAnnotationType | null>(null)
export const annotationInProgressAtom = atom<any>(null)

export const selectedEmptyChartAtom = atom<number>(1)
