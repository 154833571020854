import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal'
import { TEXT_GRAY } from '../../logic/u'

interface WatchlistOptionsProps {
  left: number
  top: number
  onClose: () => void
}

export const WatchlistDropdown = (props: WatchlistOptionsProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const {left, top, onClose} = props

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  let contents: DropdownItem[] = []
  user?.watchlists?.forEach(watchlistObj => {
    contents.push(
      {
        display: (
          <div className='left'>
            <div style={{width: 15, marginRight: 15}}>
              {(user.watchlists || []).filter(w => w.id === user.selectedWatchlistIdHomeTab)[0]?.id === watchlistObj.id ? <Icon
                icon={'check'}
                set='sharp-solid'
                size={13}
                style={{marginRight: 20, marginTop: 1.5}}
                className={''}
              /> : null}
            </div>
            <div className='add-ellipses-cutoff'>{watchlistObj.name}</div>
          </div>
        ),
        onClick: async () => {
          await db.collection('users').doc(user.uid).update({ selectedWatchlistIdHomeTab: watchlistObj.id })
        }
      },
    )
  })
  contents.push(
    {
      display: (
        <div
          style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
        ></div>
      )
    },
  )
  contents.push(
    {
      display: (
        <div className='left'>
          <div style={{width: 15, marginRight: 15}}>
            <Icon
              icon={'circle-plus'}
              set='sharp-solid'
              size={13}
              style={{marginRight: 20, marginTop: 1.5}}
              className={''}
            />
          </div>
          <div>{'Create Watchlist'}</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<CreateWatchlistModal fromHome={true} />)
      }
    },
  )

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={300}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}