import "./TBChart.scss";
import React, { useRef, useEffect, useState, useCallback } from "react";
import { useAtom } from "jotai";
import {
  loggedInUserAtom,
  userLiveDocAtom,
  toolModeAtom,
  isDraggingAtom,
  refreshChartAtom,
  selectedAnnotationAtom,
} from "../../types/global_types";
import { db } from "../../firebase";
import { Icon } from "../reusable/Icon";
import { Icon2 } from "../reusable/Icon2";
import { DropdownSelect } from "../reusable";
import { TBChartInner } from "./TBChartInner";
// import { load_bars } from "./chart_utils";
import { load_bars } from "./chart_utils/load_bars";
import { getYBounds } from "./chart_utils/getYBounds";
import { compensate_for_stock_splits } from "./chart_utils/compensate_for_stock_splits";

import {
  INTERVALS,
  TEXT_GRAY,
  is_internal,
  getMarketOpenTimeDiffCandles,
  getMostRecentMarketOpenTime,
  getMostRecentCloseTime,
} from "../../logic/u";
import { get_max_and_min } from "./get_start_end_index";
import { getConfig } from "../../config";
import { ChartSettingsDropdown } from "../dropdowns/ChartSettingsDropdown";
import { ChartOptionsDropdown } from "../dropdowns/ChartOptionsDropdown";
import { set } from "lodash";

const config: any = getConfig();



export const TBChart = ({ symbol, chartIndex, width, height }) => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom);
  const [chartMode, setChartMode] = useState<'candles' | 'line'>('candles');
  const [bars, setBars] = useState<any[]>([]);
  const [interval, setIntervalState] = useState<string>('1m');
  const [__endDate, set__endDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(true);       // internal, related to API
  const [headerDivWidth, setHeaderDivWidth] = useState<number>(10000000);
  const [previousSymbol, setPreviousSymbol] = useState(symbol);
  const [previousInterval, setPreviousInterval] = useState(interval);
  // const [toolMode, setToolMode] = useAtom(toolModeAtom);
  // const [selectedAnnotation, setSelectedAnnotation] = useAtom(selectedAnnotationAtom);
  const [isDragging, setIsDragging] = useAtom(isDraggingAtom);
  // const [refreshCounter] = useAtom(refreshChartAtom);
  const [startIndex, setStartIndex] = useState<number>(-200);
  const [endIndex, setEndIndex] = useState<number>(10);
  const [yParams, setYParams] = useState<any>({ start: null, range: null });
  const [autofitMode, setAutofitMode] = useState(true);
  const [ openDropdown, setOpenDropdown ] =useState<any>(null)
  const [switchingScrim, setSwitchingScrim] = useState(false);    // used for switches within a chart

  const ellipsisIconRef = useRef<HTMLDivElement>(null);


  const prevParamsRef = useRef<any>();


  const [firstDataFetchParams, setFirstDataFetchParams] = useState({
    interval: '',
    preset: '1M'
  });



  if (!user) return null;


  // UE #1 - reset everything on new symbol
  useEffect(() => {
    if (symbol !== previousSymbol || chartIndex !== previousInterval) {
      setBars([]);
      setPreviousSymbol(symbol);
      setPreviousInterval(chartIndex);
      setFirstDataFetchParams({
        interval: '',
        preset: '1M'
      })
    }
  }, [symbol, chartIndex]);


  const pan = (deltaX) => {
    const num_visible_bars = endIndex - startIndex;
    const deviation_from_200 = num_visible_bars - 200;
    const bars_factor = 1 + (deviation_from_200 / 200) * 0.8

    const delta = deltaX * 0.18 * bars_factor;
    let newStartIndex = startIndex + delta;
    let newEndIndex = endIndex + delta;
    const maxLoadedIndex = bars[bars.length - 1]?.relative_index;
    const minLoadedIndex = bars[0]?.relative_index;

    // Allow scrolling past the last candle
    const extraScrollSpace = num_visible_bars * 0.7; // Allow scrolling 70% of visible bars past the last candle

    if (newEndIndex > maxLoadedIndex + extraScrollSpace) {
      newEndIndex = maxLoadedIndex + extraScrollSpace;
      newStartIndex = newEndIndex - num_visible_bars;
    }

    if (newStartIndex < minLoadedIndex) {
      newStartIndex = minLoadedIndex;
      newEndIndex = newStartIndex + num_visible_bars;
    }

    // Ensure we have at least 30 bars visible
    if (newEndIndex - newStartIndex < 30) {
      return;
    }

    if (autofitMode) {
      const visibleCandles = bars.filter((bar) => bar.relative_index >= newStartIndex && bar.relative_index <= newEndIndex);
      setYParams(getYBounds(visibleCandles));
    }
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
    if (newStartIndex < bars[0]?.relative_index + 100) {
      load_more_if_necessary(newStartIndex);
    }
  };


  const zoom = (deltaX) => {

    // Compensate for number of visible bars, set bars_factor to 1 to disable
    const num_visible_bars = endIndex - startIndex;
    const deviation_from_200 = num_visible_bars - 200;
    const bars_factor = 1 + (deviation_from_200 / 200) * 0.8

    const newStartIndex = startIndex + (deltaX * 0.25 * bars_factor);

    // Calculate the number of visible bars
    const visibleBars = bars.filter((bar) =>
      bar.relative_index >= newStartIndex && bar.relative_index <= endIndex
    );

    // Check if the number of visible bars exceeds 800
    if ((visibleBars.length > 800) && (deltaX < 0)) {
      return;
    }

    // Ensure we don't zoom in too far
    if (visibleBars.length < 30) {
      return;
    }

    if (autofitMode) {
      setYParams(getYBounds(visibleBars));
    }

    setStartIndex(newStartIndex);
    load_more_if_necessary(newStartIndex);
  };


  const minWidthToShowMostElements = 680;
  const headerDivRef = useRef<HTMLDivElement>(null);

  // UE #2 - resize
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const divWidth = entry.contentRect.width;
        //@ts-ignore
        window.HEADER_DIV_WIDTH = divWidth;
        setHeaderDivWidth(divWidth);
      }
    });

    if (headerDivRef.current) {
      resizeObserver.observe(headerDivRef.current);
    }
    return () => {
      resizeObserver.disconnect();
      setHeaderDivWidth(10000000);
    };
  }, []);

  // UE #3 - inital bar load
useEffect(() => {
  const loadBars = async () => {
    setIsLoading(true);
    try {
      let _startDate, _endDate, _bufferedStartDate, _interval;

      // Determine which property changed
      const symbolChanged = symbol !== prevParamsRef.current?.symbol;
      const presetChanged = !!firstDataFetchParams.preset
      const intervalChanged = !!firstDataFetchParams.interval

      // New preset, symbol change, or initial load case
      if (presetChanged || symbolChanged || !prevParamsRef.current) {
        _endDate = new Date();
        _interval = '30m'
        _startDate = new Date(_endDate);
        _bufferedStartDate = new Date(_endDate);
        switch (firstDataFetchParams.preset) {
          case '1D': {
            let start_days_ago = 1
            if (_endDate.getDay() === 6) start_days_ago = 2
            else if (_endDate.getDay() === 0) start_days_ago = 3
            _startDate.setDate(_startDate.getDate() - start_days_ago);
            // _bufferedStartDate.setDate(_endDate.getDate() - 2)
            _interval = '1m';
            break;
          }
          case '1W':
            _startDate.setDate(_startDate.getDate() - 7);
            _bufferedStartDate.setDate(_endDate.getDate() - 14)
            _interval = '5m';
            break;
          case '1M':
            _startDate.setMonth(_startDate.getMonth() - 1);
            _bufferedStartDate.setDate(_endDate.getMonth() - 2)
            _interval = '30m';
            break;
          case '1Y':
            _startDate.setFullYear(_startDate.getFullYear() - 1);
            _bufferedStartDate.setDate(_endDate.getFullYear() - 2)
            _interval = '1d';
            break;
          case 'All':
            _startDate = new Date(0); // Beginning of time
            _interval = '1w';
            break;
        }
      }

      // New interval case
      else if (intervalChanged) {
        // setSwitchingScrim(true)
        _interval = firstDataFetchParams.interval
        _endDate = new Date();
        _startDate = new Date(_endDate);

        switch (_interval) {
          case '1m': {
            let start_days_ago = 1
            if (_endDate.getDay() === 6) start_days_ago = 2
            else if (_endDate.getDay() === 0) start_days_ago = 3
            _startDate.setDate(_startDate.getDate() - start_days_ago);
            break;
          }
          case '5m':
            _startDate.setDate(_startDate.getDate() - 7);
            break;
          case '30m':
            _startDate.setMonth(_startDate.getMonth() - 1);
            break;
          case '1h':
            _startDate.setMonth(_startDate.getMonth() - 1);
            break;
          case '2h':
            _startDate.setMonth(_startDate.getMonth() - 2);
            break;
          case '4h':
            _startDate.setMonth(_startDate.getMonth() - 4);
            break;
          case '1d':
            _startDate.setFullYear(_startDate.getFullYear() - 1);
            break;
          case '1w':
            _startDate.setFullYear(_startDate.getFullYear() - 2);
            break;
        }
      }

      // Error case, take this out when feeling confident
      else {
        console.log('No change in preset, interval, or symbol');
        setIsLoading(false);
        return;
      }

      setIntervalState(_interval);

      const loadedBars = await load_bars({
        symbol,
        interval: _interval,
        // start: _bufferedStartDate,
        start: _startDate,
        end: _endDate,
        user,
        uld,
      });

      // setYParams(getYBounds(visibleCandles))
      const bars_w_ri = loadedBars.map((bar, i) => ({
        ...bar,
        relative_index: i - loadedBars.length + 1,
      }))
      const adjustedBars = bars_w_ri;
      // const adjustedBars = compensate_for_stock_splits(bars_w_ri);     // was causing trouble
      setBars(adjustedBars)

      let { maxPrice, minPrice } = get_max_and_min(loadedBars)

      const range = maxPrice - minPrice
      maxPrice = maxPrice + (range * 0.05)
      minPrice = minPrice - (range * 0.05)
      if (range === 0 && minPrice === 0) {
        minPrice = -5
        maxPrice = 105
      }

      setYParams({ start: minPrice, range: maxPrice - minPrice, _startDate, _endDate });
      set__endDate(_endDate);

      // Reset indices
      setStartIndex(-loadedBars.length + 1);
      setEndIndex(0);

    } catch (err) {
      console.error(err)
      setBars([]);
    } finally {
      setIsLoading(false);
      setSwitchingScrim(false)
    }
  };

  loadBars();

  // Update the ref with the current params
  prevParamsRef.current = { ...firstDataFetchParams, symbol };
}, [firstDataFetchParams, symbol, user, uld]);



  const handleIntervalChange = useCallback((newInterval) => {
    // setSwitchingScrim(true)
    setFirstDataFetchParams({
      preset: '',
      interval: newInterval
    })
    setStartIndex(-200)
    setEndIndex(10)
    setBars([])
  }, []);


  const load_more_if_necessary = async (new_start_index) => {
    if (new_start_index >= bars[0]?.relative_index + 100) return;
    //@ts-ignore
    if (window.TBChartLoadingInProgress) return;
    //@ts-ignore
    window.TBChartLoadingInProgress = true;

    const end = new Date(bars[0]?.t);
    end.setMilliseconds(end.getMilliseconds() - 1);

    const new_bars = await load_bars({
      symbol,
      interval,
      start: undefined,
      end,
      user,
      uld,
    });

    const new_bars_filtered = new_bars.filter(bar => !bars.some(existingBar => existingBar.t === bar.t));

    const previous_relative_index = bars[0]?.relative_index;
    new_bars_filtered.forEach((bar, index) => {
      bar.relative_index = previous_relative_index - (new_bars_filtered.length - index);
    });

    // Apply stock split detection and adjustment
    const adjustedBars = compensate_for_stock_splits([...new_bars_filtered, ...bars]);
    setBars(adjustedBars);
    //@ts-ignore
    window.TBChartLoadingInProgress = false;
    // console.log(`Loaded ${new_bars.length} new bars, total bars: ${bars.length}`);
  };

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.ctrlKey || e.metaKey || e.type === "gesturestart") {
        e.preventDefault();
      }
    };

    window.addEventListener("wheel", disablePinchZoom, { passive: false });
    window.addEventListener("gesturestart", disablePinchZoom, { passive: false });

    return () => {
      window.removeEventListener("wheel", disablePinchZoom);
      window.removeEventListener("gesturestart", disablePinchZoom);
    };
  }, []);

  if (!symbol) {
    return (
      <div
        className='tb-chart'
        style={{
          cursor: isDragging ? 'grabbing' : 'crosshair',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: -40,
            }}
          >
            No chart loaded
          </div>
        </div>
      </div>
    );
  }

  let _startIndex = startIndex;
  let _endIndex = endIndex;

  let _yRange = yParams.range;
  let _yStartPrice = yParams.start;

  if (_endIndex !== undefined && __endDate) {
    const finalBarDate = new Date(bars[bars.length - 1]?.t);
    const endDateTimestamp = __endDate;
    const intervalMs = INTERVALS[interval];
    const numEmptyBars = getMarketOpenTimeDiffCandles(finalBarDate, endDateTimestamp, intervalMs);
    _endIndex += numEmptyBars;
  }

  const isInternal = is_internal(symbol)
  const _chartMode = isInternal ? 'line' : chartMode;

  const selectPresetTimeframe = (timeframe: string) => {
    setSwitchingScrim(true)
    setFirstDataFetchParams({
      preset: timeframe,
      interval: ''
    })
  }

  return (
    <div className='tb-chart' style={{ cursor: isDragging ? 'grabbing' : 'crosshair', width, height, minHeight: 0, position: 'relative' }}>
      {openDropdown}
      <div className='header' ref={headerDivRef}>
        <div className='left'>
          <div>{symbol}</div>
          <div ref={ellipsisIconRef}>
            <Icon
              icon='ellipsis'
              set='sharp-solid'
              size={15}
              style={{marginLeft: 10, marginRight: 10, marginTop: 0}}
              onClick={(e) => {
                const rect = ellipsisIconRef.current?.getBoundingClientRect();
                if (rect) {
                  const clickX = rect.left
                  const clickY = rect?.top - rect?.height + 40
                  setOpenDropdown(
                    <ChartOptionsDropdown
                      chartIndex={chartIndex}
                      left={clickX}
                      top={clickY}
                      onClose={() => setOpenDropdown(null)}
                      symbol={symbol}
                    />
                  )
                }
              }}
            />
          </div>
        </div>

        <div className='right' style={{ display: 'flex' }}>
          {headerDivWidth > minWidthToShowMostElements ? (
            <>
              <div className='interval-select'>
                {!isInternal ? (
                  <div
                    onClick={() => {selectPresetTimeframe('1D')}}
                    className='hov-text-btn'
                    title='Reset time horizon to 1 day'
                  >
                    1D
                  </div>
                ) : null}
                {!isInternal ? (
                  <div
                    onClick={() => {selectPresetTimeframe('1W')}}
                    className='hov-text-btn'
                    title='Reset time horizon to 1 week'
                  >
                    1W
                  </div>
                ) : null}
                <div
                  onClick={() => {selectPresetTimeframe('1M')}}
                  className='hov-text-btn'
                  title='Reset time horizon to 1 month'
                >
                  1M
                </div>
                <div
                  onClick={() => {selectPresetTimeframe('1Y')}}
                  className='hov-text-btn'
                  title='Reset time horizon to 1 year'
                >
                  1Y
                </div>
                <div
                  onClick={() => {selectPresetTimeframe('All')}}
                  className='hov-text-btn'
                  title='Reset time horizon to all time'
                >
                  All
                </div>
              </div>

              {!isInternal ? (
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    color: TEXT_GRAY,
                  }}
                >
                  •
                </div>
              ) : null}
            </>
          ) : null}

          {!isInternal ? (
            <DropdownSelect
              variant={'borderless'}
              width={'auto'}
              dropDownMenuWidth={80}
              value={interval}
              isDriven={true}
              chevronMarginTop={4}
              onChange={(val) => {handleIntervalChange(val)}}
              options={[
                { display: '1m', value: '1m' },
                { display: '5m', value: '5m' },
                { display: '30m', value: '30m' },
                { display: '1h', value: '1h' },
                { display: '2h', value: '2h' },
                { display: '4h', value: '4h' },
                { display: '1d', value: '1d' },
                { display: '1w', value: '1w' },
              ]}
            />
          ) : null}

          {!isInternal ? <Icon
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12}}
            onClick={(e) => {
              const rect = e.target.getBoundingClientRect()
              if (rect) {
                const left = rect.left - 80
                const top = rect.bottom
                setOpenDropdown(
                  <ChartSettingsDropdown
                    left={left}
                    top={top}
                    ticker={symbol}
                    autoFitMode={autofitMode}
                    setAutoFitMode={() => {
                      if (!autofitMode) {
                        const visibleCandles = bars.filter((bar) => bar.relative_index >= startIndex && bar.relative_index <= endIndex);
                        setYParams(getYBounds(visibleCandles));
                      }
                      setAutofitMode(!autofitMode)
                    }}
                    onClose={() => setOpenDropdown(null)}
                    chartMode={chartMode}
                    setChartMode={setChartMode}

                  />
                )
              }
            }}
          /> : null}
        </div>
      </div>

      {/* Dimming effect */}
      <div className={`switching-scrim ${switchingScrim ? 'visible' : ''}`} />

      {bars.length ? (
        <TBChartInner
          symbol={symbol}
          interval={interval}
          isInternal={isInternal}

          bars={bars}
          xStartIndex={_startIndex}
          xEndIndex={_endIndex}
          yRange={_yRange}
          yStartPrice={_yStartPrice}
          stepBack={0}
          setStepBack={() => {console.log('no op')}}
          chartMode={_chartMode}
          zoom={zoom}
          pan={pan}
          panVertical={(deltaY: number) => {
            if (autofitMode) return
            const delta = deltaY * 0.8;
            setYParams((prevYParams) => ({
              ...prevYParams,
              start: prevYParams.start + delta,
              range: prevYParams.range,
            }));
          }}
          zoomVertical={(deltaY: number) => {
            const delta = deltaY * 0.2;
            setYParams({
              range: yParams.range + delta * 0.4,
              start: yParams.start + delta * -0.2,
            });
          }}
          isLoading={isLoading}
          // isLoadingNewTimeframe={isLoadingNewTimeframe}
          isLoadingNewTimeframe={false}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: -40,
              color: '#FF8F0E',
            }}
          >
            {isLoading ? `Loading ${symbol}...` : 'No data'}
          </div>
        </div>
      )}
    </div>
  );
};


