import './EvaluationsBit.scss'

import React, { useState, useEffect, useMemo } from 'react'
import { useAtom } from 'jotai'
import { EELog } from '../../types/user_types'
import { format_price, valueForTable } from '../../logic/u'
import { loggedInUserAtom, userLiveDocAtom } from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import { EvaluationRow } from '../reusable/EvaluationRow'

export const EvaluationsBit = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [logs, setLogs] = useState<any[]>([])


  if (!user || !uld) return null

  useEffect(() => {
    if (uld.cachedEELogs) {
      const sortedLogs = [...uld.cachedEELogs].sort((a, b) => new Date(b.time_iso).getTime() - new Date(a.time_iso).getTime());
      setLogs(sortedLogs);
    } else {
      setLogs([]);
    }
  }, [uld]);

  // LEAVING THIS HERE FOR FUTURE LAZY LOAD USE
  // Load first 100 log records, and stay up to date
  // useEffect(() => {
  //   let isSubscribed = true; // Flag to manage cleanup
  //   console.log('**** USE EFFECT RUNNING AGAIN ****');

  //   const init = async () => {
  //     try {
  //       // Assuming `currentUser` is an async function that returns a user object or null
  //       const user = await currentUser();
  //       if (!user) throw new Error("No user found");

  //       const uid = user.uid; // Extract uid from the user object

  //       const query = db
  //         .collection('users')
  //         .doc(uid)
  //         .collection('EELogs')
  //         .orderBy('time', 'desc')
  //         .limit(10);

  //       const snapshot = await query.get();
  //       const initialLogs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  //       if (isSubscribed) {
  //         setLogs(initialLogs);
  //         setLoadingText('');
  //       }

  //       // If needed, initiate subscriptions or other actions that use uid here
  //       const unsubscribe = subscribeToNewLogs(uid);
  //       return unsubscribe;
  //     } catch (error) {
  //       console.error(error);
  //       if (isSubscribed) setLoadingText('Failed to load data');
  //     }
  //   };

  //   // Define the function to subscribe to new logs, now expecting uid as a parameter
  //   const subscribeToNewLogs = (uid) => {
  //     console.log('Subscribing to new logs');
  //     const query = db
  //       .collection('users')
  //       .doc(uid)
  //       .collection('EELogs')
  //       .orderBy('time', 'desc')
  //       .limit(10);

  //     return query.onSnapshot(snapshot => {
  //       if (!isSubscribed) return;

  //       snapshot.docChanges().forEach(change => {
  //         if (change.type === "added" || change.type === "modified") {
  //           const doc = change.doc;
  //           const newLog = { id: doc.id, ...doc.data() };

  //           setLogs(prevLogs => {
  //             const existingIndex = prevLogs.findIndex(log => log.id === newLog.id);
  //             if (existingIndex > -1) {
  //               const updatedLogs = [...prevLogs];
  //               updatedLogs[existingIndex] = newLog;
  //               return updatedLogs;
  //             }
  //             return [newLog, ...prevLogs];
  //           });
  //         }
  //       });
  //     });
  //   };

  //   init();

  //   // Cleanup function for useEffect
  //   return () => {
  //     isSubscribed = false;
  //   };
  // }, []);


  return (
    <div className='evaluations-bit'>

      {!logs.length ? (
        <div className='no-evals-text'>
          No logs.
        </div>
      ) : null}

      {logs.map((log: EELog) => {
        if (Object.keys(log).length === 0) return null

        // formatted_date, formatted_cash, formatted_equity, formatted_pl,
        const date = new Date(log.time_iso)
        const formatted_date = format_date(date)
        const equity = format_price(log._liveData?.BALANCES?.equity)
        const mkt_value = valueForTable('mkt_value', log._liveData?.BALANCES?.mkt_value)
        const day_unrealized_pl = valueForTable('day_unrealized_pl', log._liveData?.BALANCES?.day_unrealized_pl)
        const day_unrealized_pl_pc = valueForTable('day_unrealized_pl_pc', log._liveData?.BALANCES?.day_unrealized_pl_pc)

        let formatted_stats = `${equity} • ${mkt_value} • ${day_unrealized_pl} • ${day_unrealized_pl_pc}`

        if (log._liveData?.BALANCES === undefined) formatted_stats = '-'


        return <div className='outer-eval-row'>
          <div className='row space-between'>
            <div className='er-date'>{formatted_date}</div>
            <div className='er-stats'>{formatted_stats}</div>
          </div>
          <EvaluationRow log={log} />
        </div>
      })}
    </div>
  )
}


// 06/12/2024 6:15:02 AM - ONLY USING HERE
function format_date(date) {
  const options: any = {
    timeZone: 'America/New_York',
    weekday: 'short',  // Added short format for the weekday
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(date);

  let dateString = '';
  for (let part of parts) {
    // We add a comma after the weekday
    if (part.type === 'weekday') {
      dateString += part.value;
    } else {
      dateString += part.value;
    }
  }
  return dateString;
}



export const formatPercent = (value: number) => {
  if (value === null || value === undefined) {
    return '-'
  }
  return (Number(value) * 100).toFixed(2) + '%'
}