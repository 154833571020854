import axios  from 'axios'
import React, { useState, useEffect } from 'react'
import { currentUser } from '../../firebase'
import { Modal, showModal, Checkbox, Switch } from '../reusable'

import { getConfig } from '../../config'
import { ErrorModal } from './ErrorModal'
import { useAtom } from 'jotai'
import { openModalAtom } from '../../types/global_types'
import { updateLiveData } from '../../logic/u'
const config = getConfig() as any

export const DeletePositionsModal = () => {
  // General helpers
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This form
  const [closePositions, setClosePositions] = useState<boolean>(false)
  const [closeOrders, setCloseOrders] = useState<boolean>(false)

  useEffect(() => {
    setButtonEnabled(!!closePositions || !!closeOrders);
  }, [closePositions, closeOrders]);

  return (
    <Modal
      title='Sell • cancel'
      contents={[
        <div className='column'>
          <div className='row margin-bottom-20'>
            <Checkbox 
              className='margin-right-10'
              value={closePositions}
              onChange={(e) => {
                setClosePositions(!closePositions)
              }}    
            />
            <div className='white-text bold'>
              Sell all open positions
            </div>
          </div>
          <div className='row'>
            <Checkbox 
              className='margin-right-10'
              value={closeOrders}
              onChange={(e) => {setCloseOrders(!closeOrders)}}
            />
            <div className='white-text bold'>
              Cancel all open orders {closePositions ? 'first' : null}
            </div>
          </div>
          <div className='divider-green margin-bottom-20' style={{marginTop: 22}}></div>
          <div className='column width-full font-size-11 orange-text'>
            <div className='row space-between'>
              <div className=''>Sell all positions</div>
              <div className=''>{closePositions ? 'YES' : 'NO'}</div>
            </div>
            <div className='row space-between'>
              <div className=''>Cancel all open orders {closePositions ? 'first' : null}</div>
              <div className=''>{closeOrders ? 'YES' : 'NO'}</div>
            </div>
          </div>
        </div>
      ]}
      yesButtonText={'Submit'}
      isDanger
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()

          // if close positions, this endpoint will handle what to do with close orders
          if (closePositions) {
            const deleteTransferUrl = `${config.api_root_url}closeAllPositions?cancelOrders=${closeOrders}&create_ee_log=true`
            await axios.delete(deleteTransferUrl, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })

            // if !closePositions, then we use this endpoint to delete orders
          } else {
            const deleteTransferUrl = `${config.api_root_url}deleteOrders?create_ee_log=true`
            await axios.delete(deleteTransferUrl, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })
          }
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}