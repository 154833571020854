import './Functions.scss'
import { useAtom } from 'jotai';
import { loggedInUserAtom, userLiveDocAtom, openModalAtom, selectedCodeEditorSlotAtom, functionsWithUnsavedChangesAtom } from '../../types/global_types';
import { Icon, Modal } from '../reusable'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db } from '../../firebase';
import { GREEN_PARTIAL, RED_PARTIAL, formatDateForTable, timeAgo } from '../../logic/u';
import { FunctionObj } from '../../types/user_types';
import { CreateFunctionModal } from '../modals/CreateFunctionModal';
import { ORANGE } from '../../logic/colors';

export const FunctionsTree = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom);
  const [userLiveDoc] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [functions, setFunctions] = useState<FunctionObj[]>([])
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)
  const [functionsWithUnsavedChanges, setFunctionsWithUnsavedChanges] = useAtom(functionsWithUnsavedChangesAtom)

  const [validDrop, setValidDrop] = useState<boolean>(false)

  // Render tree
  useEffect(() => {
    setFunctions(user?.functions || [])
  }, [user])

  const onDragUpdate = (update) => {
    const { source, destination } = update
    console.log(update)
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination, type } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let functionsClone: FunctionObj[] = Array.from(functions) || []
    const [reorderedItem] = functionsClone.splice(source.index, 1) // remove dragged item
    functionsClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setFunctions(functionsClone) // local state
    db.collection('users').doc(user?.uid).update({ functions: functionsClone });
  }

  // NOTE: if liveData hasn't loaded, sidebar will be missing
  if (!user) return null
  if (!userLiveDoc || !userLiveDoc.liveData) return null

  return (
    <div className='functions-tree'>
      <div className='header'>
        <div>
          Functions
        </div>
        <div>
          <Icon
            icon='plus'
            set='sharp-solid'
            size={12}
            style={{marginTop: -.5}}
            onClick={() => {
              setOpenModal(<CreateFunctionModal />)
            }}
          />
        </div>
      </div>

      {functions.length === 0 ? (
        <div style={{marginTop: 10, marginLeft: 10, fontSize: 11.5, color: ORANGE}}>
          No functions yet. Click the plus sign to create one
        </div>
      ) : null}

      <div className='functions-list-container'>
        <DragDropContext
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
          key={'modal-content'}
        >
          <div className='droppable-container'>
            <Droppable droppableId={'1'} type='bucket'>
              {(provided, snapshot) => (
                <div
                  className='draggable-container'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {functions.map((functionObj: FunctionObj, index) => {

                    let parentClass = 'entry'
                    const visibleEditors = user.codeEditorConfig === '2-one-left-one-right' ? [user.codeEditorSlot1, user.codeEditorSlot2] : [user.codeEditorSlot1]
                    if (visibleEditors.includes(functionObj.id)) {
                      parentClass += ' selected'
                    }

                    let keyIcon
                    let keyIconSize = 12
                    if (functionObj.triggerType === 'interval') {
                      keyIcon = 'clock'
                    }
                    if (functionObj.triggerType === 'callable') {
                      keyIcon = 'bullseye-pointer'
                      keyIconSize = 13
                    }

                    let formatted_date = '-'
                    let time_ago = functionObj.triggerInterval
                    if (userLiveDoc.functionsLastRun && userLiveDoc.functionsLastRun[functionObj.id]) {
                      const last_ran_date = new Date(userLiveDoc.functionsLastRun[functionObj.id])
                      time_ago += ' • ' + timeAgo(last_ran_date)
                      formatted_date = formatDateForTable(last_ran_date)
                    }
                    return (
                      <Draggable
                        key={functionObj.id}
                        draggableId={functionObj.id}
                        index={index}
                      >
                        {(provided, snapshot) => {

                          return (
                            <div
                              className={parentClass}
                              title={functionObj.name}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                              }}
                              onClick={() => {

                                // Clear unsaved changes and change selected function
                                const handleClick = () => {
                                  setFunctionsWithUnsavedChanges({})
                                  if (selectedCodeEditorSlot === 1) {
                                    db.collection('users').doc(user?.uid).update({ codeEditorSlot1: functionObj.id });
                                    return
                                  }
                                  if (selectedCodeEditorSlot === 2) {
                                    db.collection('users').doc(user?.uid).update({ codeEditorSlot2: functionObj.id });
                                    return
                                  }

                                  // change the first slot even if it's not selected
                                  db.collection('users').doc(user?.uid).update({ codeEditorSlot1: functionObj.id });
                                }

                                // Flag unsaved changes
                                if (Object.keys(functionsWithUnsavedChanges).length) {
                                  setOpenModal(
                                    <Modal
                                      title={'Unsaved changes'}
                                      contents={[
                                        <div className='column width-full font-size-13 orange-text'>
                                          <div className='row'>
                                            You have unsaved editor changes. Navigating away will cause them to be discarded.
                                            <br />
                                            <br />
                                            Do you want to proceed?
                                          </div>
                                        </div>
                                      ]}
                                      yesButtonText={'Yes, discard'}
                                      isDanger
                                      onYes={ async () => {
                                        handleClick()
                                      }}
                                    />
                                  )
                                  return
                                }

                                // If no unsaved changes
                                handleClick()
                              }}
                            >
                              <div className='column'>
                                <div className={'row space-between'}>
                                  <div className='row'>
                                    <Icon
                                      icon={keyIcon}
                                      set='sharp-solid'
                                      size={keyIconSize}
                                      className={'key-icon'}
                                      style={{marginTop: 2, marginRight: 5}}
                                    />
                                    <div className='function-title'>
                                      {functionObj.name}
                                    </div>
                                  </div>
                                  {functionObj.triggerType === 'interval' ? <div className={functionObj.isRunning ? 'orange-tag' : 'orange-tag off'}>{functionObj.isRunning ? 'ON' : 'OFF'}</div> : null}
                                </div>
                                <div className='row space-between width-full font-size-11' style={{marginTop: 1}}>
                                  <div title={formatted_date}>{time_ago}</div>
                                  <div className='icon-container'>
                                    <Icon
                                      icon='grip-dots-vertical'
                                      size={7}
                                      className='grip-icon'
                                    />
                                  </div>
                                </div>

                              </div>
                            </div>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    </div>
  )
}