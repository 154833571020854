import axios  from 'axios'
import { useAtom } from 'jotai'
import {
  tableSX,
  formatDateForTable,
  BACKGROUND
} from '../../logic/u'
import { currentUser } from '../../firebase'
import React, { useState, useEffect } from 'react'
import { DataGridPro, GridOverlay, GridSortDirection } from '@mui/x-data-grid-pro'
import { getConfig } from '../../config'
import { openModalAtom } from '../../types/global_types'
import { ErrorModal } from '../modals/ErrorModal'
import { Button } from '../reusable'
import { FileInput } from '../reusable/FileInput'

const config = getConfig() as any

export function DocumentsTab() {
  const [ documents, setDocuments ] = useState<any[] | null>(null);
  const [, setOpenModal] = useAtom(openModalAtom)
  const defaultSortModel = [
    {
      field: 'date',
      sort: 'desc' as GridSortDirection
    },
  ];
  const [sortModel, setSortModel] = useState(defaultSortModel);

  // Fetch documents (THIS WORKS)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await currentUser()?.getIdToken()
        const getDocumentsUrl = `${config.api_root_url}getDocuments`
        const documentsResponse = await axios.get(getDocumentsUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        setDocuments(documentsResponse.data || [])
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData()
  }, [])

  const documentRows = documents || []

  const getOverlay = () => {
    if (!documents) {
      return (
        <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
          <div>Loading documents...</div>
        </GridOverlay>
      )
    }
    return (
      <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
        <div>No documents.</div>
      </GridOverlay>
    )
  }

  const handleSorting = (v1: string | number | Date, v2: string | number | Date): number => {
    // Handle '-' values
    if (v1 === '-' && v2 !== '-') {
      return -1;
    }
    if (v1 !== '-' && v2 === '-') {
      return 1;
    }

    // Custom sorting for dates
    if (typeof v1 === 'string' && typeof v2 === 'string' && Date.parse(v1) && Date.parse(v2)) {
      return new Date(v1).getTime() - new Date(v2).getTime();
    }

    // Default sorting logic for numbers and strings
    return Number(v1) - Number(v2);
  };


  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  const PdfLinkButton = ({ rowData }) => {
    const [isDownloading, setIsDownloading] = useState(false)
    return (
      <div
        className='pointer hover-underline'
        onClick={async (e) => {
          setIsDownloading(true)
          try {
            const token = await currentUser()?.getIdToken()
            const getDocumentLinkUrl = `${config.api_root_url}getDocumentLink?documentId=${rowData.id}`
            const response = await axios.get(getDocumentLinkUrl, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
              responseType: 'blob',
            })

            if (response.status === 200) {
              const blob = new Blob([response.data], { type: 'application/pdf' })
              const downloadUrl = URL.createObjectURL(blob)
              const a = document.createElement("a")
              a.href = downloadUrl
              a.download = `${rowData.type}_${rowData.date}.pdf`
              document.body.appendChild(a)
              a.click()
              window.URL.revokeObjectURL(downloadUrl)
              document.body.removeChild(a)
              setIsDownloading(false)
            } else {
              console.error('Non-200 response', response)
            }
          } catch (error) {
            console.error('Error fetching document:', error)
          }
        }}
      >
        {!isDownloading ? 'Download PDF' : 'Loading...'}
      </div>
    )
  }


  return (
    <div className='tab-contents has-subtab'>          
      <div className='table-container'>
        <DataGridPro
          rows={documentRows}
          columns={[
            {
              field: 'type',
              headerName: 'document',
              flex: 3,
              type: 'string',
              sortComparator: handleSorting,
              valueGetter: (params) => {
                let type = params.row.type.charAt(0).toUpperCase() + params.row.type.slice(1).toLowerCase()
                type = type.split('_').join(' ')
                return type
              }
            },
            {
              field: 'date',
              headerName: 'date',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleSorting,
              valueGetter: (params) => formatDateForTable(params.row.date)
            },
            {
              field: 'id',
              headerName: 'link',
              flex: 1,
              sortable: false,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleSorting,
              renderCell: (params) => <PdfLinkButton rowData={params.row} />,
            },
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          components={{
            NoRowsOverlay: getOverlay,
          }}
          sx={tableSX}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total documents:',
          }}
        />
      </div>

    </div>
  )
}