import axios  from 'axios'
import React, { useState, useEffect } from 'react'
import { formatCurrency, formatDateForTable, formatNumberForTable, formatPercent, formatStockPrice, isMarketOpen } from '../../logic/u'
import { currentUser } from '../../firebase'
import { Modal } from '../reusable'
import { ErrorModal } from './ErrorModal'
import { useAtom } from 'jotai'
import { openModalAtom } from '../../types/global_types'
import { getConfig } from '../../config'
const config = getConfig() as any

export interface OrderDetailsModalProps {
  order: any
}

export const OrderDetailsModal = (props: OrderDetailsModalProps) => {
  // General helpers
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [order, setOrder] = useState<any>(props.order)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await currentUser()?.getIdToken()
        const endpoint = `${config.api_root_url}getOrder?orderId=${order.id}`
        const res = await axios.get(endpoint, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        setOrder(res.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData()

    const intervalId = setInterval(() => {
      fetchData(); // Run every 5 seconds
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [])


  const contents = (
    <div className='column width-full font-size-13 orange-text'>

      <div className='row space-between margin-bottom-10'>
        <div className=''>symbol: </div>
        <div className=''>{order.symbol}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>side: </div>
        <div className=''>{order.side}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>type: </div>
        <div className=''>{order.type}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>notional: </div>
        <div className=''>{formatStockPrice(order.notional, true)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>qty: </div>
        <div className=''>{formatNumberForTable(order.qty).split('.')[0]}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>filled_qty: </div>
        <div className=''>{order.filled_qty}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>filled_avg_price: </div>
        <div className=''>{formatNumberForTable(order.filled_avg_price)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>limit_price: </div>
        <div className=''>{formatNumberForTable(order.limit_price)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>stop_price: </div>
        <div className=''>{formatNumberForTable(order.stop_price)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>trail_percent: </div>
        <div className=''>{formatPercent(order.trail_percent)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>trail_price: </div>
        <div className=''>{formatNumberForTable(order.trail_price)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>time_in_force: </div>
        <div className=''>{order.time_in_force}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>created_at: </div>
        <div className=''>{formatDateForTable(order.created_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>submitted_at: </div>
        <div className=''>{formatDateForTable(order.submitted_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>filled_at: </div>
        <div className=''>{formatDateForTable(order.filled_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>expired_at: </div>
        <div className=''>{formatDateForTable(order.expired_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>cancelled_at: </div>
        <div className=''>{formatDateForTable(order.cancelled_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>failed_at: </div>
        <div className=''>{formatDateForTable(order.failed_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>replaced_at: </div>
        <div className=''>{formatDateForTable(order.replaced_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>updated_at: </div>
        <div className=''>{formatDateForTable(order.updated_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>status: </div>
        <div className=''>{order.status}</div>
      </div>

    </div>
  )

  return (
    <Modal
      title='Order details'
      contents={[contents]}
      scrollableBody={true}
      yesButtonText='Cancel order'
      isDanger={true}
      isButtonDisabled={['filled', 'canceled', 'expired', 'rejected'].includes(order.status)}
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const deleteOrderUrl = `${config.api_root_url}deleteOrder?orderId=${order.id}&create_ee_log=true`
          await axios.delete(deleteOrderUrl, { headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          } })
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}