/*
Duplicate of the one on the backend
*/

import { format_price } from "./u"
import { ArgsSetTrade } from "../types/user_types"

export const make_create_order_string = (args: ArgsSetTrade) => {
  let s = `order: ${args.side} ${args.symbol} ${args.type} ${args.qty} ${args.time_in_force}`
  if (args.type === 'limit') {
    s =`order: ${args.side} ${args.symbol} ${args.type} ${args.qty} @ ${args.limit_price} ${args.time_in_force}`
  }
  if (args.type === 'stop_limit') {
    const fmt_stop = format_price(args.stop_price || 0)
    const fmt_limit = format_price(args.limit_price || 0)
    s += ` @ ${fmt_stop}-${fmt_limit}`
  }
  return s
}