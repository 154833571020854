import './BottomPane.scss';

import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { EvaluationsBit } from './EvaluationsBit'
import { loggedInUserAtom, openModalAtom, selectedEmptyChartAtom, userLiveDocAtom } from '../../types/global_types'
import { Icon } from '../reusable';
import { db } from '../../firebase';
import { Variable } from '../../types/user_types';
import { returnChartPaneContextObj, set_open_chart, valueForTable } from '../../logic/u';
import { CreateVariableModal } from '../modals/CreateVariableModal';

export const BottomPane = () => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  if (!user) return null

  return (
    <div className='bottom-pane-container'>
      <div className='handle' />
      <div className='state-pane-container'>
        <div className='header'>
          <div className='header-tab selected'>
            State
          </div>
          <Icon
            icon='plus'
            set='sharp-solid'
            size={11}
            style={{
              marginTop: -7
            }}
            onClick={() => {
              setOpenModal(<CreateVariableModal />)
            }}
          />
        </div>
        <VariablesPane />
      </div>
      <div className='logs-pane-container'>
        <div className='logs-pane-header'>
          <div className='header-tab selected' >
            Logs
          </div>
        </div>
        <EvaluationsBit />
      </div>
    </div>
  )
}

const VariablesPane = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyChart] = useAtom(selectedEmptyChartAtom)

  if (!user) return null
  if (!uld) return null

  return (
    <>
      <div className='sect-body'>

        {/* Empty state */}
        {user.variables && Object.keys(user.variables).length === 0 && (
          <div className='row margin-top-10 margin-left-10 orange-text font-size-11'>
            No variables.
          </div>
        )}

        {Object.keys(user.variables || [])?.sort()?.map(v => {
          const variable: Variable = user.variables[v]
          const chart = 'STATE.' + variable.symbol
          const chartPaneContextObj = returnChartPaneContextObj(user, chart)
          const chartIsVisible = chartPaneContextObj?.chartIsVisible
          let divClass = 'entry'
          if (chartIsVisible) {
            divClass += ' selected'
          }
          return (
            <div
              className={divClass}
              onClick={() => {
                set_open_chart(user, uld, selectedEmptyChart || 1, chart)
              }}
            >
              <div className='row space-between orange-text'>
                <div className='entry-title' title={variable.symbol}>
                  {variable?.symbol}
                </div>
                <div>{valueForTable(variable?.symbol, uld.liveData.STATE[variable?.symbol])}</div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

