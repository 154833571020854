import { db } from '../../../firebase'
import { loadAlpacaData } from './loadAlpacaData'
import { get_empty_bars } from './get_empty_bars'
import { UserLiveDoc } from '../../../types/user_types'

interface load_internal_data_props {
  user: any
  uld: UserLiveDoc
  symbol: string
  startDate: Date
  endDate: Date
  interval: string
}

export const load_internal_data = async ({user, uld, symbol, startDate, endDate, interval}: load_internal_data_props) => {

  // Equity and overall PL, absolute and percent, come directly from alpaca
  if (['BALANCES.equity', 'BALANCES.pl', 'BALANCES.pl_pct'].includes(symbol)) {
    const alpaca_data = await loadAlpacaData(startDate, endDate, interval)
    if (symbol === 'BALANCES.equity') return alpaca_data.equity
    else if (symbol === 'BALANCES.pl') return alpaca_data.profit_loss
    else if (symbol === 'BALANCES.pl_pct') return alpaca_data.pl_pct
  }

  // Otherwise we're getting data from EELogs
  const start_date = new Date(startDate)
  const end_date = new Date(endDate)

  // Determine if we should use daily data
  const timeDiff = end_date.getTime() - start_date.getTime()
  const daysDiff = timeDiff / (1000 * 3600 * 24)
  const isDaily = daysDiff > 30 // Use daily data if the range is more than 30 days

  /* Assemble logs, starting with cache */
  const ALL_LOGS: any = []

  // (this is a format adapter, between v3 and legacy)
  const add_log_if_match = (raw_log: any) => {
    let time: any = null
    let liveData: any = null
    if (raw_log.log_version === 3) {
      time = new Date(raw_log.time_iso)
      liveData = raw_log._liveData
    } else {
      // Legacy version, get rid of this when no longer relevant
      time = raw_log.time.toDate()
      liveData = raw_log.DS
    }
    if (time >= start_date && time <= end_date) {
      const logExists = ALL_LOGS.some((log: any) => Math.abs(log.time.getTime() - time.getTime()) <= 30000)
      if (!logExists) {
        ALL_LOGS.push({ time, liveData })
      }
    }
  }

  // Find relevant points from cached daily logs; hopefully we don't need to query
  const cachedDayLogs = uld.cachedDayLogs || []
  let need_more = false
  for (let i = 0; i < cachedDayLogs.length; i++) {
    const log = cachedDayLogs[i]
    add_log_if_match(log)
  }
  let singleLog: any = null
  if (ALL_LOGS.length > 0) singleLog = ALL_LOGS[ALL_LOGS.length - 1]

  // Do we need more from the database?
  if (cachedDayLogs.length > 80) {
    const oldest_cached_daily_log_time = cachedDayLogs[cachedDayLogs.length - 1].time.toDate()
    if (oldest_cached_daily_log_time > start_date) {
      need_more = true
    }
  }

  // Sort ALL_LOGS by time (changed to ascending order)
  ALL_LOGS.sort((a: any, b: any) => a.time - b.time)

  // Set data_start_time to the time of the first log, if any logs exist
  let data_start_time: number | null = ALL_LOGS.length > 0 ? ALL_LOGS[0].time.getTime() : null

  // If we need more, query the database
  if (need_more) {
    console.log('CACHE INSUFFICIENT, QUERYING DATABASE')
    let time_before = new Date()
    let filteredLogsRef = db.collection('users')
      .doc(user.uid)
      .collection('EELogs')
      .where('time', '>', start_date)
      .where('isDayPoint', '==', true)
      .orderBy('time', 'desc')
    const EELogRecs: any[] = []

    const querySnapshot = await filteredLogsRef.get()
    querySnapshot.forEach(doc => {
      EELogRecs.push(doc.data())
    })
    const dur = new Date().getTime() - time_before.getTime()
    console.log(`   loaded ${EELogRecs.length} EELogs in ${dur}ms`)

    // Add these logs to the ALL_LOGS array
    for (let i = 0; i < EELogRecs.length; i++) {
      const log = EELogRecs[i]
      add_log_if_match(log)
    }
  }

  /* ADAPT ALL THIS TO THE CANDLESTICK CHART FORMAT (CONSIDER CHANGING) */

  // Create empty bars for the timeframe
  const emptyBars = get_empty_bars({ timeframe: interval, limit: 1000, start: start_date, end: end_date })
  const bucket = symbol.split('.')[0]
  const key = symbol.split('.')[1]

  let initialVal = 0
  if (singleLog) {
    const bucket_contents = singleLog.liveData[bucket]
    initialVal = Number(bucket_contents[key])
  }

  // Assemble bars as best we can with what we've got
  let most_recent_val: number = initialVal
  const _data: any[] = []

  for (let i = 0; i < emptyBars.length; i++) {
    const timestamp = new Date(emptyBars[i].t).getTime()
    const next_timestamp = emptyBars[i + 1] ? new Date(emptyBars[i + 1].t).getTime() : Infinity

    // We default to the most recent value
    let value: any = most_recent_val

    const datapoints = ALL_LOGS.filter((log: any) => {
      const ts = log.time.getTime()
      return ts >= timestamp && ts < next_timestamp
    })
    let datapoint: any = datapoints[0] ? datapoints[0] : null

    // If we have a datapoint, this is the new most recent
    if (datapoint) {
      const liveData = datapoint.liveData
      const bucket_contents = liveData[bucket]
      value = Number(bucket_contents[key])
      most_recent_val = value
    }

    if (value === Infinity) value = 0     // handling bad old data, fixed now
    if (isNaN(value)) value = 0           // debatable

    if (data_start_time !== null && timestamp < data_start_time) {
      value = 0
    }

    // Add this datapoint to the data array
    const bar: any = [timestamp, value]
    _data.push(bar)
  }

  return _data
}