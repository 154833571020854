import "./TBChart.scss"
import React, { useEffect, useState, useRef } from "react"
import { useAtom } from "jotai"
import {
  loggedInUserAtom, userLiveDocAtom,
} from "../../types/global_types"
import { Indicator } from '../../types/user_types'
import {
  INTERVALS,
  is_internal,
  ORANGE,
  uuid,
} from "../../logic/u"
import {
  formatNumber,
  updateChartDataWithStreamingData,
  get_empty_bars,
} from './chart_utils'
import { get_y_label_prices } from './chart_utils/get_y_label_prices'
import { make_x_axis_labels } from './chart_utils/make_x_axis_labels'


import { add_indicators_if_missing, get_indicators_for_chart } from '../../logic/indicator_helpers'
import { isDraggingAtom } from '../../types/global_types'
import { OscillatorPlot } from "./OscillatorPlot"
import { OscillatorPlotTabBar } from "./OscillatorPlotTabBar"
import { IndicatorSeriesData } from './OscillatorPlot'
import { CANDLE_GREEN, CANDLE_RED } from "../../logic/colors"

// import { DEMO_BARS, SIMPLE_DEMO_BARS } from './test_data'
import { ChartOverlay } from './ChartOverlay'

// import { getConfig } from '../../config'
// const config: any = getConfig()


const MARKET_CLOSED_COLOR = '#3a004c80'   // midnight purple
const LIVE_STREAMING_DATA_ENABLED = true     // FOR TESTING PURPOSES

interface TBChartInnerProps {
  symbol: string
  bars: any[]
  interval: string
  isInternal: boolean
  xStartIndex: number
  xEndIndex: number
  yStartPrice: number
  yRange: number
  zoom: (deltaX: number) => void          // anchors right, zooms number of candles
  pan: (deltaX: number) => void           // slides L/R
  panVertical: (deltaY: number) => void   // slide up/down
  zoomVertical: (deltaY: number) => void  // increases/decreases price magnification
  stepBack: number
  chartMode: 'candles' | 'line'
  setStepBack: (stepBack: number) => void
  // showStepBack: boolean
  isLoadingNewTimeframe: boolean
  isLoading: boolean
}

export const TBChartInner = (props: TBChartInnerProps) => {
  const chartRef = useRef<HTMLDivElement>(null) // Reference to the chart div element
  //@ts-ignore
  const [measuredWidth, setMeasuredWidth] = useState<number>(window.measuredWidth || 1000)
  //@ts-ignore
  const [measuredHeight, setMeasuredHeight] = useState<number>(window.measuredHeight || 500)
  const [hasOscillatorPlot, setHasOscillatorPlot] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useAtom(isDraggingAtom)
  const [endOfDataVisible, setEndOfDataVisible] = useState(false);
  const [oscillatorIndicator, setOscillatorIndicator] = useState<Indicator | null>(null)
  const [demarcateDays, setDemarcateDays] = useState(false)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)

  const [cumulativeDragX, setCumulativeDragX] = useState<number>(0);
  const [cumulativeDragY, setCumulativeDragY] = useState<number>(0);

  if (!user) return null
  if (!uld) return null

  useEffect(() => {
    if (is_internal(props.symbol)) return
    const demarcate_days = !!user.charts[props.symbol]?.demarcateDays
    if (demarcate_days !== demarcateDays) setDemarcateDays(demarcate_days)
  }, [user])

  const startOfDataLabelRef = useRef(null);

  const chartOverlayRef = useRef(null);
  const updateCrosshairPosition = () => {
    if (chartOverlayRef.current) {
      //@ts-ignore
      chartOverlayRef.current.updateCrosshair();
    }
  };

  const marketClosedLines: JSX.Element[] = [];
  const calculateCandleGap = (current: any, previous: any) => {
    const currentDate = new Date(current.t);
    const previousDate = new Date(previous.t);
    const gap = currentDate.getTime() - previousDate.getTime();
    const intervalMs = INTERVALS[props.interval];
    return Math.floor(gap / intervalMs) - 1;
  };

  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      const { width, height } = entry.contentRect
      setMeasuredWidth(width)
      setMeasuredHeight(height)
    }
  })

  const createLinePath = () => {
    let pathD = ""
    let lastActualCandleIndex = visibleCandles.findIndex(candle => candle.isFutureCandle) - 1;
    if (lastActualCandleIndex === -2) lastActualCandleIndex = visibleCandles.length - 1;

    visibleCandles.forEach((candle, index) => {
      if (candle.c !== null && index <= lastActualCandleIndex) {
        const x = get_x_for_candle_index(candle.relative_index)
        const y = get_y_for_price(candle.c)
        if (index === 0) {
          pathD = `M${x},${y}`
        } else {
          pathD += ` L${x},${y}`
        }
      }
    })
    return pathD
  }

  const createFillPath = () => {
    let lastActualCandleIndex = visibleCandles.findIndex(candle => candle.isFutureCandle) - 1;
    if (lastActualCandleIndex === -2) lastActualCandleIndex = visibleCandles.length - 1;

    let pathD = `M${get_x_for_candle_index(visibleCandles[0].relative_index)},${measuredHeight}`
    visibleCandles.forEach((candle, index) => {
      if (index <= lastActualCandleIndex) {
        const x = get_x_for_candle_index(candle.relative_index)
        const y = get_y_for_price(candle.c)
        pathD += ` L${x},${y}`
      }
    });
    const lastX = get_x_for_candle_index(visibleCandles[lastActualCandleIndex].relative_index)
    pathD += ` L${lastX},${measuredHeight} Z`
    return pathD
  }

  useEffect(() => {
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current)
    }

    return () => {
      if (chartRef.current) {
        resizeObserver.unobserve(chartRef.current)
      }
    }
  }, [chartRef])

  // Check if the end of data is within view
  useEffect(() => {
    const is_all = props.interval === '1w'    // HACK, fix this at some point
    const end_in_sight = props.bars.length > 0 && props.xStartIndex <= props.bars[0].relative_index
    if (is_all && end_in_sight) {
      //@ts-ignore
      const labelWidth = startOfDataLabelRef?.current?.offsetWidth;
      const labelPosition = get_x_for_candle_index(props.bars[0].relative_index);
      const spaceAvailable = measuredWidth - labelPosition;
      console.log(`labelWidth: ${labelWidth}, labelPosition: ${labelPosition}, spaceAvailable: ${spaceAvailable}`)
      let label_visible = (spaceAvailable >= labelWidth) && (labelPosition > 75)
      setEndOfDataVisible(label_visible);
    } else {
      setEndOfDataVisible(false);
    }
  }, [props.xStartIndex, props.bars]);

  let nonGapCandles = props.bars.filter((candle: any) => !candle.isGap)

  const indicators_present = get_indicators_for_chart(user, props.symbol, props.interval)

    // We can have one oscillator indicator visible at a time. Find it.
    useEffect(() => {
      let oscillator: any = null
      const indicator_keys = Object.keys(indicators_present)
      for (let i = 0; i < indicator_keys.length; i++) {
        const indicator_key = indicator_keys[i]
        const indicator = indicators_present[indicator_key]
        if (indicator.oscillator_settings && !indicator.isHidden) {
          oscillator = indicator
          break
        }
      }
      setOscillatorIndicator(oscillator || null)
      setHasOscillatorPlot(!!oscillator)
    }, [indicators_present])

  // Set up oscillator_data to receive datapoints
  let oscillator_title = ''
  // const oscillator_plot_parameters = {
  //   zero_band: false,
  //   upper_band: null,
  //   lower_band: null,
  //   max: null,
  //   min: null,
  //   auto_limits: false,
  //   enforce_zero: false
  // }
  const oscillator_data: any = {}     // map, will be made into array later
  const indicators_present_keys = Object.keys(indicators_present)
  for (let i = 0; i < indicators_present_keys.length; i++) {
    const indicator_key = indicators_present_keys[i]
    const indicator = indicators_present[indicator_key]

    if (!indicator.oscillator_settings) continue

    // (we can only have one oscillator at a time)
    oscillator_title = indicator.value // indicator.display
    // oscillator_plot_parameters.zero_band = indicator.oscillator_settings.zero_band
    // oscillator_plot_parameters.upper_band = indicator.oscillator_settings.upper_band
    // oscillator_plot_parameters.lower_band = indicator.oscillator_settings.lower_band
    // oscillator_plot_parameters.max = indicator.oscillator_settings.max
    // oscillator_plot_parameters.min = indicator.oscillator_settings.min
    // oscillator_plot_parameters.auto_limits = indicator.oscillator_settings.auto_limits
    // oscillator_plot_parameters.enforce_zero = indicator.oscillator_settings.enforce_zero

    const plot_keys = Object.keys(indicator.plots)
    for (let j = 0; j < plot_keys.length; j++) {
      const plot_key = plot_keys[j]
      const plot = indicator.plots[plot_key]

      if (indicator.oscillator_settings && !indicator.isHidden) {
        const series_data: IndicatorSeriesData = {
          data: [],
          color: plot.color,
          weight: plot.weight,
          style: plot.style,
          name: plot_key
        }
        oscillator_data[plot_key] = series_data
      }
    }
  }
  // (now we have the template to pass to OscillatorPlot, we just need datapoints)

  // We should already have computed indicators when we loaded bars. Add any recent additions
  nonGapCandles = add_indicators_if_missing(user, props.symbol, props.interval, nonGapCandles, uld)

  // Trim down to what's visible
  const visibleCandles: any[] = []
  const lastRealCandle = nonGapCandles[nonGapCandles.length - 1];
  const lastRealCandleTime = new Date(lastRealCandle.t).getTime();
  const intervalMs = INTERVALS[props.interval];

  nonGapCandles.forEach((candle: any) => {
    if (candle.relative_index >= props.xStartIndex && candle.relative_index <= props.xEndIndex) {
      visibleCandles.push(candle)
    }
  })

  // Add empty future candles
  let futureCandlesCount = 0
  while (visibleCandles.length < props.xEndIndex - props.xStartIndex + 1) {
    const lastCandle = visibleCandles[visibleCandles.length - 1];
    const nextCandleTime = new Date(lastCandle.t).getTime() + intervalMs;
    visibleCandles.push({
      relative_index: lastCandle.relative_index + 1,
      t: new Date(nextCandleTime).toISOString(),
      o: lastRealCandle.c,
      h: lastRealCandle.c,
      l: lastRealCandle.c,
      c: lastRealCandle.c,
      v: 0,
      isFutureCandle: true
    });
    futureCandlesCount++;
  }

  // Are we all the way scrolled right? If not, don't bother with streaming data
  const all_the_way_right = (() => {
    if (nonGapCandles.length === 0 || visibleCandles.length === 0) {
      return false;
    }
    const lastVisibleCandle = visibleCandles[visibleCandles.length - 1];
    const lastNonGapCandle = nonGapCandles[nonGapCandles.length - 1];

    // Check if the last visible candle is the most recent one in the dataset
    return lastVisibleCandle.relative_index === lastNonGapCandle.relative_index;
  })();

  // Live streaming data from uld.quotes
  if (LIVE_STREAMING_DATA_ENABLED && all_the_way_right) {
    const last_candle = nonGapCandles[nonGapCandles.length - 1]
    const last_candle_time = new Date(last_candle.t).getTime()
    if (uld.quotes && uld.quotes[props.symbol]) {
      const last_quote_data = uld.quotes[props.symbol]
      const last_quote_time = new Date(last_quote_data.last_updated).getTime()
      const ms_since_last_candle = last_quote_time - last_candle_time
      if (ms_since_last_candle > 0 && props.interval === '1m') {
        nonGapCandles = updateChartDataWithStreamingData(uld.quotes[props.symbol].bars, nonGapCandles)

        // Update the last candle with the latest price
        if (nonGapCandles.length > 0) {
          const lastCandle = nonGapCandles[nonGapCandles.length - 1]
          lastCandle.c = last_quote_data.latest
          lastCandle.h = Math.max(lastCandle.h, last_quote_data.latest)
          lastCandle.l = Math.min(lastCandle.l, last_quote_data.latest)
        }
      }
    }
  }



  // const allSameValue = visibleCandles.every(candle => candle.c === visibleCandles[0].c);

  // After calculating initial minPrice and maxPrice
  let minPrice = Math.min(...visibleCandles.map(candle => candle.l || candle.c));
  let maxPrice = Math.max(...visibleCandles.map(candle => candle.h || candle.c));

  // Calculate range and padding
  let range = maxPrice - minPrice;
  const paddingPercentage = 0.1;

  // Special case for when all values are the same or very close
  if (range < Number.EPSILON) {
    const value = (minPrice + maxPrice) / 2;
    range = Math.abs(value) * 0.1;
    minPrice = value - range / 2;
    maxPrice = value + range / 2;
  }

  const padding = range * paddingPercentage;

  // Apply padding
  minPrice -= padding;
  maxPrice += padding;

  // Ensure zero is visible if it's close to the range
  if (minPrice > 0 && minPrice / range < 0.2) {
    minPrice = -padding;
  }

  // Recalculate range after adjustments
  range = maxPrice - minPrice;

  // Use these values for yStartPrice and yRange
  const yStartPrice = props.yStartPrice || minPrice;
  const yRange = props.yRange || range;

  // console.log(`minPrice: ${minPrice}, maxPrice: ${maxPrice}`);
  // console.log(`adjustedMinPrice: ${adjustedMinPrice}, adjustedMaxPrice: ${adjustedMaxPrice}`);
  // console.log(`yStartPrice: ${yStartPrice}, yRange: ${yRange}`);


  // Check if all visible candles have a closing price of zero
  const isFlatLineAtZero = visibleCandles.every(candle => candle.c === 0)
  if (props.isInternal && isFlatLineAtZero) {
    maxPrice = 100
    minPrice = -5
  }

  const priceRange = props.yRange || maxPrice - minPrice

  // Calculate max volume, and create a pixel multiplier
  const VOLUME_HEIGHT = 60
  const maxVolume = Math.max(...visibleCandles.map((candle: any) => candle.v || 0))

  // const yDollarsPerPixel = priceRange / measuredHeight

  const get_price_for_y = (y: number) => {
    const yDollarsPerPixel = yRange / measuredHeight;
    const pixels_reversed = measuredHeight - y;
    const price = yStartPrice + (pixels_reversed * yDollarsPerPixel);
    let ret = price.toFixed(2);

    // Handle Y value within oscillator chart
    if (hasOscillatorPlot) {
      let is_in_oscillator_chart = y - measuredHeight > 0;
      if (is_in_oscillator_chart) {
        const y_within_plot = 100 - (y - measuredHeight);      // accommodating tab bar
        if (y_within_plot < 0) return 0;
        //@ts-ignore
        const oscMin = window.oscillatorChartMin;
        //@ts-ignore
        const oscMax = window.oscillatorChartMax;
        const oscRange = oscMax - oscMin;
        const oscPixelsPerPixel = oscRange / 80;
        const oscPrice = oscMin + (y_within_plot * oscPixelsPerPixel);
        ret = oscPrice.toFixed(2);
      }
    }

    if (ret === '-0.00') {    // tiny number rounding issue
      ret = '0.00';
    }
    return ret;
  };


  const get_y_for_price = (price: number) => {
    const yDollarsPerPixel = yRange / measuredHeight;
    const pixels_reversed = (price - yStartPrice) / yDollarsPerPixel;
    return measuredHeight - pixels_reversed;
  };


  const total_candles = props.xEndIndex - props.xStartIndex
  const candle_width = measuredWidth / total_candles

  const get_x_for_candle_index = (abs_index: number) => {
    const rel_index = abs_index - props.xStartIndex
    const x = candle_width * rel_index
    return x
  }

  // Returns absolute index of the candle with the relevant date
  // Returns absolute index of the candle with the relevant date
  const get_candle_index_for_timestamp = (timestamp: number) => {
    const interval = props.interval;
    const interval_ms = INTERVALS[interval];
    const half_interval = interval_ms / 2;

    if (props.bars.length === 0) {
      return null;
    }

    // Filter bars to get visible candles
    const visibleCandles = props.bars.filter(
      (bar) => bar.relative_index >= props.xStartIndex && bar.relative_index <= props.xEndIndex
    );

    const firstVisibleCandleTimestamp = new Date(visibleCandles[0].t).getTime();
    const lastVisibleCandleTimestamp = new Date(visibleCandles[visibleCandles.length - 1].t).getTime();

    if (timestamp < firstVisibleCandleTimestamp) {
      // Timestamp is before the first visible candle
      const diff = firstVisibleCandleTimestamp - timestamp;
      const steps = Math.ceil(diff / interval_ms);
      return visibleCandles[0].relative_index - steps;
    } else if (timestamp > lastVisibleCandleTimestamp) {
      // Timestamp is after the last visible candle
      const diff = timestamp - lastVisibleCandleTimestamp;
      const steps = Math.ceil(diff / interval_ms);
      return visibleCandles[visibleCandles.length - 1].relative_index + steps;
    } else {
      // Timestamp is within the range of the visible bars
      for (let i = 0; i < visibleCandles.length; i++) {
        const candle = visibleCandles[i];
        const candleTimestamp = new Date(candle.t).getTime();
        if (Math.abs(candleTimestamp - timestamp) < half_interval) {
          return candle.relative_index;
        }
      }
    }

    return null;
  };




  const snap_tos: number[] = []
  const x_to_timestamp: any = {}
  const make_candle = (data: any, width: number) => {

    if (data.o === data.c && data.o === data.h && data.o === data.l) {
      // console.log(`ZERO VOLUME CANDLE at ${data.o}, ${data.t}`)
    }

    const o = get_y_for_price(data.o)
    const h = get_y_for_price(data.h)
    const l = get_y_for_price(data.l)
    const c = get_y_for_price(data.c)

    const wick_x = get_x_for_candle_index(data.relative_index)
    const candle_x = wick_x - (width / 2)
    snap_tos.push(wick_x)
    x_to_timestamp[wick_x] = data.t

    let color: string
    let height: number
    let wick_height: number
    let candle_y: number
    let wick_y: number

    // RED CANDLE
    if (data.o > data.c) {
      color = CANDLE_RED
      height = c-o
      wick_height = l-h
      candle_y = o
      wick_y = h
    }

    // GREEN CANDLE
    else if (data.o < data.c) {
      color = CANDLE_GREEN
      height = o-c
      wick_height = l-h
      candle_y = c
      wick_y = h
    }

    // FLAT CANDLE
    else {
      color = 'gray'
      wick_height = l-h
      height = 1
      candle_y = c
      wick_y = c
    }

    return [
      <rect x={candle_x} y={candle_y} width={width} height={height} fill={color} key={'c-' + candle_x} />,
      <rect x={wick_x} y={wick_y} width="1" height={wick_height} fill={color} key={'w-' + candle_y} />
    ]
  }
  const make_volume_bar = (data: any, width: number) => {
    const volume = data.v;
    const volume_height = (volume / maxVolume) * VOLUME_HEIGHT;
    const volume_x = get_x_for_candle_index(data.relative_index);
    const volume_y = measuredHeight - volume_height;
    let color = CANDLE_GREEN;
    if (data.o >= data.c) {
      color = CANDLE_RED;
    }

    return (
      <rect
        x={volume_x}
        y={volume_y}
        width={width}
        height={volume_height}
        fill={color}
        // key={`v-${data.relative_index}-${data.t}-${volume_x}-${volume_y}`} // Ensure unique key
        key={uuid()} // Ensure unique key
        style={{ opacity: 0.6 }}
      />
    );
  };



  let candles: any[] = []
  let volume_bars: any[] = []
  let inline_indicator_point_data: any = {}   // indicator series plotted by price

  // Put together candles and indicators
  for (let i = 0; i < visibleCandles.length; i++) {
    const candle = visibleCandles[i]
    if (candle.isGap) {
      continue
    }
    candles.push(
      make_candle({
        o: candle.o,
        h: candle.h,
        l: candle.l,
        c: candle.c,
        t: candle.t,
        relative_index: candle.relative_index
      }, candle_width * 0.9)
    )

    let volumeHidden = user.charts[props.symbol]?.volumeHidden
    if (volumeHidden === undefined) volumeHidden = false
    if (!volumeHidden) {
      volume_bars.push(make_volume_bar({
        v: candle.v,
        t: candle.t,
        o: candle.o,
        c: candle.c,
        relative_index: candle.relative_index
      }, candle_width * 0.3))
    }

    // For overlay indicators, add x and y coordinates to set data for plotting
    if (candle.overlays) {
      const overlay_keys = Object.keys(candle.overlays)
      for (let i = 0; i < overlay_keys.length; i ++) {
        const overlay_key = overlay_keys[i]
        const datapoint = candle.overlays[overlay_key]

        // Simple series, like SMA, EMA, or VWAP
        if (typeof datapoint === 'number') {
          if (!inline_indicator_point_data[overlay_key]) {
            inline_indicator_point_data[overlay_key] = []
          }
          inline_indicator_point_data[overlay_key].push({
            x: get_x_for_candle_index(candle.relative_index),
            y: get_y_for_price(datapoint)
          })
        }

        // Multi-plot series, like BOLL
        else if (typeof datapoint === 'object') {
          const indicator = indicators_present[overlay_key]
          const plots = Object.keys(indicator?.plots || {})
          for (let j = 0; j < plots.length; j++) {
            const plot = plots[j]
            if (!inline_indicator_point_data[overlay_key + '_' + plot]) {
              inline_indicator_point_data[overlay_key + '_' + plot] = []
            }
            inline_indicator_point_data[overlay_key + '_' + plot].push({
              x: get_x_for_candle_index(candle.relative_index),
              y: get_y_for_price(datapoint[plot])
            })
          }
        }
      }
    }

    // Add market closed lines
    if (demarcateDays && i > 0) {
      const previousCandle = visibleCandles[i - 1];
      const gap = calculateCandleGap(candle, previousCandle);
      if (gap > 3) {
        const x = get_x_for_candle_index(candle.relative_index) - candle_width / 2;
        marketClosedLines.push(
          <line
            key={`market-closed-${i}`}
            x1={x}
            y1={0}
            x2={x}
            y2={measuredHeight}
            stroke={MARKET_CLOSED_COLOR}
            // stroke={'red'}
            strokeWidth={4}
          />
        );
      }
    }

    if (candle.oscillators) {
      const oscillator_keys = Object.keys(candle.oscillators)
      for (let i = 0; i < oscillator_keys.length; i ++) {
        const oscillator_key = oscillator_keys[i]
        const datapoint = candle.oscillators[oscillator_key]

        if (typeof datapoint === 'number') {
          oscillator_data[oscillator_key]?.data?.push({
            x: get_x_for_candle_index(candle.relative_index),
            val: datapoint,
            relative_index: candle.relative_index
          })

        } else if (typeof datapoint === 'object') {
          const plots = Object.keys(datapoint)
          for (let j = 0; j < plots.length; j++) {
            const plot = plots[j]
            oscillator_data[plot]?.data?.push({
              x: get_x_for_candle_index(candle.relative_index),
              val: datapoint[plot],
              relative_index: candle.relative_index
            })
          }
        }
      }
    }
  }

  // SVGs plotted on the main chart
  const svgPaths: any[] = []

  const indicator_keys = Object.keys(inline_indicator_point_data)

  // creating overlay indicator plot
  for (let i = 0; i < indicator_keys.length; i++) {
    const indicator_key = indicator_keys[i]
    const indicator_key_root = indicator_key.split('_')[0]      // "BOLL"
    const indicator = indicators_present[indicator_key_root]
    let plot_key = indicator_key.split('_')[1]

    // Single plot scenario
    if (!plot_key) {
      plot_key = Object.keys(indicator?.plots || {})[0]
    }
    let color = indicator?.plots[plot_key]?.color || 'neongreen'
    let stroke_weight = indicator?.plots[plot_key]?.weight || 2
    const plot_not_visible = indicator?.plots[plot_key]?.visible === false

    // If the indicator has been deleted or made invisible, skip it
    if (!indicator || indicator.isHidden || plot_not_visible) {
      continue
    }

    const points = inline_indicator_point_data[indicator_key]
    let pathData = `M ${points[0].x} ${points[0].y}`
    for (let i = 1; i < points.length; i++) {
      pathData += ` L ${points[i].x} ${points[i].y}`
    }
    svgPaths.push(
      <path
        d={pathData}
        stroke={color}
        strokeWidth={stroke_weight}
        fill="none"
        key={indicator_key + '-' + i}
      />
    )
  }

  // We need this to handle height correctly around the oscillator plot
  if (Object.keys(oscillator_data).length) {
    if (!hasOscillatorPlot) setHasOscillatorPlot(true)
  } else {
    if (hasOscillatorPlot) setHasOscillatorPlot(false)
  }

  if (visibleCandles.length === 0) {
    throw new Error('EMPTY SET, WE SHOULD NOT BE HERE')
  }

  // Make y axis labels
  const lower = props.yStartPrice
  const upper = props.yStartPrice + props.yRange
  const y_label_prices = get_y_label_prices(lower, upper)   // array of prices
  const y_label_data: any[] = [] // {price: number, y: number, label: string}[]
  const y_rules: any[] = []
  const y_labels: any[] = []
  for (let i = 0; i < y_label_prices.length; i++) {
    const price = y_label_prices[i]
    const y = get_y_for_price(Number(price))
    const label = formatNumber(price)
    y_label_data.push({price, y, label})

    // Create an SVG line for each
    if (props.chartMode === 'candles') {
      y_rules.push(
        <line
          x1={0}
          y1={y}
          x2={measuredWidth}
          y2={y}
          stroke="#20242f"
          strokeWidth="1"
          key={'y-rule-' + i}
        />
      )
    }

    // Add a label for each except the last
    y_labels.push(
      <div
        className='label'
        style={{top: y - 8}}
        key={'y-label-' + i}
      >
        {label}
      </div>
    )
  }

  // Define candleTimestamps by mapping visibleCandles to their timestamps
let candleTimestamps = visibleCandles.map(candle => candle.t)

// Make x axis labels, working with the full set of candles
const totalCandles = props.xEndIndex - props.xStartIndex


// if (props.fixedTimeframe === '1D') {
//   const nominal_start = new Date(visibleCandles[0].t)
//   const interval_ms = INTERVALS[props.interval]
//   const nominal_end = new Date(nominal_start.getTime() + (interval_ms * totalCandles))

//   const bar_full_set = get_empty_bars({
//     timeframe: props.interval,
//     limit: 1000,
//     start: nominal_start.toISOString(),
//     end: nominal_end.toISOString(),
//   })
//   candleTimestamps = bar_full_set.map(bar => bar.t)
// }

let x_label_data = make_x_axis_labels(candleTimestamps, props.interval, totalCandles, measuredWidth)

// Handle transitional state with too many labels
if (x_label_data.length > 32) x_label_data = []

const x_labels: any[] = []
const x_rules: any[] = []

for (let i = 0; i < x_label_data.length; i++) {
  const timestamp = new Date(x_label_data[i].timestamp).getTime()
  // const bold = x_label_data[i].bold
  const bold = false
  const candleIndex = get_candle_index_for_timestamp(timestamp)
  const x = get_x_for_candle_index(candleIndex)

  // Create a label for each
  if (i === 0) continue
  x_labels.push(
    <div
      className={`label ${bold ? 'bold' : ''}`}
      style={{ left: x - 30 }}
      key={'x-label-' + i}
    >
      {x_label_data[i].label}
    </div>
  )

  // Create an SVG line for each
  if (props.chartMode === 'candles') {
    x_rules.push(
      <line
        x1={x}
        y1={0}
        x2={x}
        y2={measuredHeight}
        stroke="#20242f"
        strokeWidth="1"
        key={'x-rule-' + i}
      />
    )
  }
}

  // Make current price indicator
  const current_price = visibleCandles[visibleCandles.length - 1].c
  const current_price_y = get_y_for_price(current_price)
  const current_price_formatted = formatNumber(current_price)
  const latest_candle_is_red = visibleCandles[visibleCandles.length - 1].o > current_price

  let current_price_label: any = (
    <div
      className={!latest_candle_is_red ? 'current-price' : 'current-price negative'}
      style={{top: current_price_y,}}
    >
      <div className={'cp-label'}>
        {current_price_formatted}
      </div>
    </div>
  )
  if (current_price_y < 0) {
    current_price_label = null
  } else if (current_price_y > measuredHeight) {
    current_price_label = null
  }

  const handleScroll = (e: any) => {
    if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
      if (e.deltaY !== 0) {
        if (e.shiftKey) {
          props.zoomVertical(e.deltaY)
          updateCrosshairPosition()
        } else {
          props.zoom(e.deltaY)
          updateCrosshairPosition()
        }
      }
    } else {
      if (e.deltaX !== 0) {
        props.pan(e.deltaX)
        updateCrosshairPosition()
      }
    }
  }

  const handleDrag = (e: any) => {
    let deltaX = 0;
    let deltaY = 0;

    if (e.movementX) {
      const numCandlesVisible = props.xEndIndex - props.xStartIndex;
      const candleWidth = measuredWidth / numCandlesVisible;
      let deltaXInCandles = -e.movementX / candleWidth;  // Adjusting direction
      deltaXInCandles = deltaXInCandles * 7.2;

      deltaX = deltaXInCandles;

      setCumulativeDragX((prev) => {
        const newCumulativeDragX = prev + e.movementX;
        return newCumulativeDragX;
      });

      props.pan(deltaXInCandles);
    }

    if (e.movementY) {
      const priceRange = props.yRange || 100;
      const pricePerPixel = priceRange / measuredHeight;
      let deltaYInPrice = e.movementY * pricePerPixel;
      deltaYInPrice = deltaYInPrice * 1.4;

      deltaY = deltaYInPrice;

      setCumulativeDragY((prev) => {
        const newCumulativeDragY = prev + e.movementY;
        return newCumulativeDragY;
      });

      props.panVertical(deltaYInPrice);
    }
  };





  // Make space for indicator chart if necessary
  const op_toggled_shut = user.charts[props.symbol]?.oscillatorsHidden
  const have_oscillators = !!oscillatorIndicator
  const oscillator_plot_visible = have_oscillators && !op_toggled_shut
  let chartInnerClassName = 'chart-inner'
  if (oscillator_plot_visible) {
    chartInnerClassName += ' has-oscillator-chart'
  }

  // Format oscillator data as an array
  const oscillator_series_data_array = Object.keys(oscillator_data).map(key => oscillator_data[key])

  // Names of available oscillators
  // const oscillator_names: string[] = []
  // let selected_oscillator = ''
  // Object.keys(indicators_present).forEach(indicator_key => {
  //   const indicator = indicators_present[indicator_key]
  //   if (indicator.oscillator_settings) {
  //     oscillator_names.push(indicator.abbrev_str)
  //     if (!indicator.isHidden) selected_oscillator = indicator.abbrev_str
  //   }
  // })


  return (
    <div className='chart-wrapper'>
      {/* {props.isLoading || props.isLoadingNewTimeframe ? <div className='chart-overlay' /> : null} */}
      <div
        className={chartInnerClassName}
        ref={chartRef}
        onWheel={handleScroll}
        onMouseDown={() => {
          setIsDragging(true)}
        }
        onMouseUp={() => {
          setCumulativeDragX(0)
          setCumulativeDragY(0)
          setIsDragging(false)
        }}
        onMouseMove={(e) => {
          if (isDragging) handleDrag(e)
        }}
      >
        {/* Crosshairs */}
        <ChartOverlay
          ref={chartOverlayRef}
          symbol={props.symbol}
          interval={props.interval}
          get_price_for_y={get_price_for_y}
          snap_tos={snap_tos}
          data={visibleCandles}
          get_candle_index_for_timestamp={get_candle_index_for_timestamp}
          get_x_for_candle_index={get_x_for_candle_index}
          get_y_for_price={get_y_for_price}
          width={measuredWidth}
          height={measuredHeight}
          stepBack={props.stepBack}
          setStepBack={props.setStepBack}
          chartMode={props.chartMode}
          futureCandlesCount={futureCandlesCount}
        />

        {/* Chart proper */}
        <svg width={measuredWidth} height={measuredHeight}>
          {y_rules}
          {x_rules}

          {/* Line at y=0 if isInternal is true */}
          {props.isInternal && (
            <line
              x1={0}
              y1={get_y_for_price(0)}
              x2={measuredWidth}
              y2={get_y_for_price(0)}
              stroke={'#444444'}
              // stroke={ORANGE}
              strokeWidth="1"
              // strokeDasharray="5,5"
            />
          )}

          {props.chartMode === 'candles' && visibleCandles.map((candle, index) => {
            if (candle.isFutureCandle) {
              // Render a thin line for future candles
              const x = get_x_for_candle_index(candle.relative_index);
              return (
                <line
                  key={`future-candle-${candle.relative_index}`}
                  x1={x}
                  y1={get_y_for_price(candle.c)}
                  x2={x}
                  y2={get_y_for_price(candle.c) + 1}
                  stroke="#333"
                  strokeWidth={1}
                />
              );
            } else {
              // Render normal candles
              return make_candle(candle, candle_width * 0.9);
            }
          })}
          {props.chartMode === 'candles' && user.preferences?.show_volume ? volume_bars : null}
          {props.chartMode === 'line' ?
            <>
              <path d={createFillPath()} fill="#FF8F0E11" />
              <path d={createLinePath()} stroke="#FF8F0E" strokeWidth="2" fill="none" />
            </>
            : null}
          {volume_bars}
          {svgPaths}
          {demarcateDays ? marketClosedLines : null}
        </svg>

        {current_price_label}
      </div>

      {oscillator_plot_visible ? <OscillatorPlotTabBar
        symbol={props.symbol}
        user={user}
      /> : null}

      {/* Oscillator plot */}
      {oscillator_plot_visible ? (
        <div style={{display: 'flex', flexDirection: 'column', height: 120}}>


          <OscillatorPlot
            title={oscillator_title}
            width={measuredWidth}
            series_data={oscillator_series_data_array}
            // plot_parameters={oscillator_plot_parameters}
            indicator={oscillatorIndicator}
            symbol={props.symbol}
          />
        </div>
      ) : null}
      {/* {oscillatorIndicator ? (
        <OscillatorPlot
          title={oscillator_title}
          width={measuredWidth}
          series_data={oscillator_series_data_array}
          plot_parameters={oscillator_plot_parameters}
          indicator={oscillatorIndicator}
        />
      ) : null} */}

      {/* X axis labels */}
      <div className='x-axis-labels'>
        {x_labels}
      </div>

      {/* Y axis labels */}
      <div
        className='y-axis-labels'
        onMouseDown={() => {setIsDragging(true)}}
        onMouseUp={() => {setIsDragging(false)}}
        onMouseMove={(e) => {
          if (isDragging) {
            props.zoomVertical(e.movementY)
          }
        }}
      >
        {y_labels}
      </div>

      {/* Start of data */}
      <div
        ref={startOfDataLabelRef}
        style={{
          position: 'absolute',
          bottom: oscillator_plot_visible ? 140 : 20,
          left: `${get_x_for_candle_index(props.bars[0].relative_index)}px`,
          border: '1px solid #FF8F0E',
          color: '#FF8F0E',
          backgroundColor: '#0D0A0A',
          padding: '5px 10px',
          borderRadius: 3,
          fontSize: '12px',
          zIndex: 1,
          pointerEvents: 'none',
          whiteSpace: 'nowrap',
          transform: 'translateX(-50%)',
          opacity: endOfDataVisible ? 1 : 0,  // Hide it when there's not enough space
          transition: 'opacity 0.3s ease',
        }}
      >
        Start of Data
      </div>

    </div>
  )
}

const generateFullDayTimestamps = (date: Date, intervalMs: number) => {
  const start = new Date(date);
  start.setHours(9, 30, 0, 0); // Market open at 9:30 AM
  const end = new Date(date);
  end.setHours(16, 0, 0, 0); // Market close at 4:00 PM

  const timestamps: number[] = [];
  let current = start.getTime();
  while (current <= end.getTime()) {
    timestamps.push(current);
    current += intervalMs;
  }

  return timestamps;
};
