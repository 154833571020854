import React, { useState, useEffect } from 'react'
import { Modal, Input, SliderToggle, Icon, showModal, DropdownSelect, CurrencyInput } from '../reusable'
import axios  from 'axios'
import { currentUser } from '../../firebase'
import { getConfig } from '../../config'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { ErrorModal } from './ErrorModal'
import firebase from 'firebase/app';
import { formatNumberForTable, formatStockPrice, stringToFormattedAmount, updateLiveData, valueForTable } from '../../logic/u'
const config = getConfig() as any

export function CreateTransferModal() {
  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // Transfers form
  const [ direction, setDirection ] = useState<string>('INCOMING')
  const [ transferAmount, setTransferAmount] = useState(0)
  const [ bankAccountNumber, setBankAccountNumber ] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setButtonEnabled(!!direction && !!transferAmount && !!bankAccountNumber);
  }, [direction, transferAmount, bankAccountNumber]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const token = await currentUser()?.getIdToken()
        const getAchRelationshipUrl = `${config.api_root_url}getAchRelationship`
        const achRelationshipResponse = await axios.get(getAchRelationshipUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        const bank_account_number = achRelationshipResponse.data[0]?.bank_account_number || ''
        setBankAccountNumber(bank_account_number)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error)
      }
    };

    fetchData()
  }, [])

  if (!user) return null

  const getBankText = () => {
    if (loading) {
      return 'Loading...'
    }
    if (!bankAccountNumber) {
      return 'Add a bank from the Account tab'
    }
    return bankAccountNumber
  }

  if (!uld) return null

  return (
    <Modal
      title={'Transfer'}
      contents={[
        <div className='column width-full'>
          <DropdownSelect
            label={'Direction'}
            // width={374}
            width={362}
            options={[
              {display: 'Deposit', value: 'INCOMING'},
              {display: 'Withdraw', value: 'OUTGOING'}
            ]}
            value={direction}
            onChange={(val) => setDirection(val)}
          />
          {direction === 'OUTGOING' && <div className='row width-full'>
            <div className='under-input-label'>
              {'cash_withdrawable: ' + formatStockPrice(uld.liveData.BALANCES.cash_withdrawable, true)}
            </div>
          </div>}
          <CurrencyInput
            className={'margin-top-20'}
            label='Amount'
            width={380}
            onChange={(val) => {
              if (val === undefined) setTransferAmount(0)
              else setTransferAmount(val)
            }}
          />
          <div className='divider-green margin-bottom-20' style={{marginTop: 22}}></div>
          <div className='column width-full font-size-11 orange-text'>
            <div className='row space-between'>
              <div className=''>From</div>
              <div className=''>{direction === 'OUTGOING' ? 'TickberBot' : getBankText()}</div>
            </div>
            <div className='row space-between'>
              <div className=''>To</div>
              <div className=''>{direction === 'OUTGOING' ? getBankText() : 'Tickerbot'}</div>
            </div>
            <div className='row space-between'>
              <div className=''>Amount</div>
              <div className=''>{transferAmount ? valueForTable('amount', transferAmount) : '-'}</div>
            </div>
          </div>
        </div>
      ]}
      yesButtonText='Submit'
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const url = `${config.api_root_url}createTransfer`

          const data = {
            transfer_type: 'ach',
            amount: transferAmount,
            direction: direction,
            create_ee_log: true
          }
          await axios.post(url, data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          // Set flag on user
          const userId = currentUser()?.uid
          await firebase.firestore().collection('users').doc(userId).update({
            pendingTransferFlag: true
          })

          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal( <ErrorModal errorMessage={error.message}/> )
        }
        updateLiveData(user)
      }}
    />
  )
}