import React from 'react';
import { CANDLE_GREEN, CANDLE_RED } from '../../logic/colors';
import { OrderDetailsModal } from '../modals/OrderDetailsModal';
import { make_create_order_string } from '../../logic/make_create_order_string';
// import './OpenOrderIndicator.scss';

interface OpenOrderIndicatorProps {
  order: any;
  y_position_pixel: number;
  chartHeight: number;
  setOpenModal: (modal: any) => void;
}

export const OpenOrderIndicator: React.FC<OpenOrderIndicatorProps> = ({
  order,
  chartHeight,
  y_position_pixel,
  setOpenModal
}) => {
  const INDICATOR_HEIGHT = 20;
  const EDGE_THRESHOLD = 24;

  const isOffTop = y_position_pixel < EDGE_THRESHOLD;
  const isOffBottom = y_position_pixel > chartHeight - EDGE_THRESHOLD;

  const COLOR = order.side === 'sell' ? CANDLE_RED : CANDLE_GREEN;
  const label = make_create_order_string(order);

  let indicatorStyle: React.CSSProperties = {};
  let show_dashed_line = false;
  let show_top_arrow = false;
  let show_bottom_arrow = false;

  if (isOffTop) {
    indicatorStyle.top = `${EDGE_THRESHOLD - 10}px`;
    show_top_arrow = true;
  } else if (isOffBottom) {
    indicatorStyle.bottom = `${EDGE_THRESHOLD - 10}px`;
    show_bottom_arrow = true;
  } else {
    indicatorStyle.top = `${y_position_pixel - INDICATOR_HEIGHT / 2}px`;
    show_dashed_line = true;
  }

  return (
    <div
      className={`tbc-oo-ind ${order.side}`}
      style={indicatorStyle}
      onClick={() => setOpenModal(<OrderDetailsModal order={order} />)}
    >
      {label}
      {show_dashed_line && <div className="dashed-line" />}
      {show_top_arrow && (
        <div className="arrow-top">
          <div className="arrow-top-outline" />
          <div className="arrow-top-fill" />
        </div>
      )}
      {show_bottom_arrow && (
        <div className="arrow-bottom">
          <div className="arrow-bottom-outline" />
          <div className="arrow-bottom-fill" />
        </div>
      )}
    </div>
  );
};

export default OpenOrderIndicator;