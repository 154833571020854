import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react'
import {
  openModalAtom,
  userLiveDocAtom,
  loggedInUserAtom,
  consoleOutputAtom,
  selectedCodeEditorSlotAtom,
  functionsWithUnsavedChangesAtom,
  cmdSAtom, cmdTAtom, cmdOAtom
} from '../../types/global_types';
import { DropdownMenu, DropdownSelect, Icon, Switch } from '../reusable';
import { RunFunctionModal } from '../modals/RunFunctionModal';
import { RunFunctionOnceModal } from '../modals/RunFunctionOnceModal';
import { TestFunctionModal } from '../modals/TestFunctionModal';
import { CodeEditor } from './CodeEditor';
import { FunctionObj } from '../../types/user_types';
import { db } from '../../firebase';
import { FunctionOptionsDropdown } from '../dropdowns/FunctionOptionsDropdown';
import { createEELog } from '../../logic/u';
import { CreateFunctionModal } from '../modals/CreateFunctionModal';

interface CodeEditorWrapperProps {
  codeEditorSlot: number
  functionObj?: FunctionObj | undefined
}

export const CodeEditorWrapper = (props: CodeEditorWrapperProps) => {
  const {codeEditorSlot, functionObj} = props


  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setConsoleOutput] = useAtom(consoleOutputAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [selectedCodeEditorSlot, setSelectedCodeEditorSlot] = useAtom(selectedCodeEditorSlotAtom)
  const [ dropdown, setDropdown ] = useState<any>()
  const [functionsWithUnsavedChanges, setFunctionsWithUnsavedChanges] = useAtom(functionsWithUnsavedChangesAtom)
  const [cmdS] = useAtom(cmdSAtom)
  const [cmdT] = useAtom(cmdTAtom)
  const [cmdO] = useAtom(cmdOAtom)

  const ellipsisIconRef = useRef<HTMLDivElement>(null);

  const page_load_start_ts = new Date().getTime()

  // Save on cmd + s, see keyboard listener in app
  useEffect(() => {
    if (!cmdS) return
    do_save()
  }, [cmdS])

  // // Cmd + T to test function, see keyboard listener in app
  // useEffect(() => {
  //   if (!functionObj) return
  //   if (!cmdT) return
  //   do_save().then((new_code) => {
  //     if (new_code) functionObj.codeBlock = new_code
  //     setOpenModal(<TestFunctionModal functionObj={functionObj} />)
  //   })
  // }, [cmdT])

  // // Shift + cmd + r to run function once
  // useEffect(() => {
  //   if (!functionObj) return
  //   if (!cmdO) return
  //   do_save().then((new_code) => {
  //     if (new_code) functionObj.codeBlock = new_code
  //     setOpenModal(<RunFunctionOnceModal functionObj={functionObj}/>)
  //   })
  // }, [cmdO])

  // Also returns new_code, for the sake of saving prior to executing
  const do_save = async () => {
    if (!functionObj) return
    if (!functionsWithUnsavedChanges[functionObj.id]?.unsavedEditorValue) return
    const new_code = functionsWithUnsavedChanges[functionObj.id]?.unsavedEditorValue
    await save_function_code(functionObj, user, functionsWithUnsavedChanges[functionObj.id]?.unsavedEditorValue)
    let newState = {...functionsWithUnsavedChanges}
    delete newState[functionObj.id]
    setFunctionsWithUnsavedChanges(newState || {})
    return new_code
  }


  let codeEditorWrapperClass = 'code-editor-wrapper'
  if (user?.codeEditorConfig === '2-one-left-one-right') {
    codeEditorWrapperClass += ' two-left-right'
  }
  if (!functionObj) {
    codeEditorWrapperClass += ' empty'
  }

  let emptyHeaderClass = 'tab-header empty'
  let emptyBoxClass = 'empty-box'
  let emptyText = 'Click here'
  if (selectedCodeEditorSlot === codeEditorSlot) {
    emptyHeaderClass += ' selected'
    emptyBoxClass += ' selected'
    emptyText = 'Choose a function...'
  }

  if (!user || !uld) return null

  if (!functionObj) return (
    <div className={codeEditorWrapperClass}>
      <div className={emptyHeaderClass}>
      </div>
      <div
        className={emptyBoxClass}
        onClick={() => {
          setSelectedCodeEditorSlot(selectedCodeEditorSlot ? null : codeEditorSlot)
        }}
      >
        {emptyText}
      </div>
    </div>
  )

  return (
    <div className={codeEditorWrapperClass}>
      {dropdown}
      <div className='tab-header'>
        <div className='row align-center'>
          <div className={user.codeEditorConfig !== '1' ? 'function-tab-name smaller' : 'function-tab-name'}>
            {functionObj?.name}
          </div>
          <div ref={ellipsisIconRef}>
            <Icon
              hoverText='Function options'
              icon='ellipsis'
              set='sharp-solid'
              size={15}
              style={{marginLeft: 10, marginRight: 10, marginTop: 0}}
              onClick={(e) => {
                const rect = ellipsisIconRef.current?.getBoundingClientRect();
                if (rect) {
                  const clickX = rect.left
                  const clickY = rect?.top - rect?.height + 40
                  setDropdown(
                    <FunctionOptionsDropdown
                      functionObj={functionObj}
                      codeEditorSlot={codeEditorSlot}
                      left={clickX}
                      top={clickY}
                      onClose={() => setDropdown(null)}
                    />
                  )
                }
              }}
            />
          </div>
          {functionsWithUnsavedChanges[functionObj.id]?.unsavedEditorValue ? <div
            className='functions-tab-header-btn'
            onClick={async () => {
              await save_function_code(functionObj, user, functionsWithUnsavedChanges[functionObj.id].unsavedEditorValue)
              let newState = {...functionsWithUnsavedChanges}
              delete newState[functionObj.id]
              setFunctionsWithUnsavedChanges(newState || {})
            }}
          >
            <Icon
              icon='floppy-disk'
              className='functions-tab-header-icon'
              hoverText='Run function once'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>
              Save
            </div>
          </div> : null}
        </div>

        {/* Right */}
        <div className='row'>

          {/* Test function button */}
          <div
            className='functions-tab-header-btn'
            style={{marginRight: 10}}
            onClick={async () => {
              do_save().then((new_code) => {
                if (new_code) functionObj.codeBlock = new_code
                setOpenModal(<TestFunctionModal functionObj={functionObj} />)
              })
            }}
          >
            <Icon
              icon='flask'
              className='functions-tab-header-icon'
              hoverText='Test function'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for hover
              }}
              />
            <div>
              Test
            </div>
          </div>

          {/* Run once button */}
          <div
            className='functions-tab-header-btn'
            onClick={async () => {
              do_save().then((new_code) => {
                if (new_code) functionObj.codeBlock = new_code
                setOpenModal(<RunFunctionOnceModal functionObj={functionObj}/>)
              })
            }}
            >
            <Icon
              icon='circle-play'
              className='functions-tab-header-icon'
              hoverText='Run function once'
              set='sharp-solid'
              size={14}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for hover
              }}
            />
            <div>
              Run once
            </div>
          </div>

          {/* Interval switch */}
          {functionObj.triggerType === 'interval' ? <Switch
            style={{marginLeft: 10}}
            label={functionObj.isRunning ? 'ON' : 'OFF'}
            title={'Run function on interval'}
            value={functionObj?.isRunning || false}
            onChange={async (val) => {
              setOpenModal(<RunFunctionModal functionObj={functionObj} />)
            }}
          /> : null}
          <Icon 
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12, marginTop: 5.5}}
            onClick={() => {
              setOpenModal(<CreateFunctionModal functionObj={functionObj} />)
            }}
          />
        </div>
      </div>
      <CodeEditor functionObj={functionObj} />
    </div>
  )
}

const save_function_code = async(functionObj: any, user: any, editorValue: string) => {
  let currentFunctions = user?.functions || []
  const functionObjToUpdateIndex = currentFunctions.findIndex(f => f.id === functionObj?.id)
  currentFunctions[functionObjToUpdateIndex] = {
    ...functionObj,
    codeBlock: editorValue,
    updatedAt: new Date()
  }
  db.collection('users').doc(user?.uid).update({ functions: currentFunctions })
}