import React from 'react';
import _CurrencyInput from 'react-currency-input-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Input.scss';

interface CurrencyInputProps {
  className?: string;
  label: string;
  onChange?: (value: number | undefined) => void;
  onBlur?: (value: number | undefined) => void;
  value?: number;
  hoverText?: string;
  style?: React.CSSProperties;
  width?: number;
  variant?: 'compact' | undefined;
}

export function CurrencyInput(props: CurrencyInputProps) {
  const width = props.width || 120;

  let classList = 'input-component money';
  if (props.className) {
    classList += ' ' + props.className;
  }

  const handleValueChange = (value: string | undefined) => {
    if (props.onChange) {
      props.onChange(value ? parseFloat(value) : undefined);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      const value = event.target.value;
      props.onBlur(value ? parseFloat(value) : undefined);
    }
  };

  const getLabel = () => {
    let label;
    let infoIcon;
    let labelClassName = 'label';
    if (props.variant === 'compact') {
      labelClassName += ' compact';
    }
    if (props.label) {
      label = <div className={labelClassName}>{props.label}</div>;
      if (props.hoverText) {
        infoIcon = (
          <FontAwesomeIcon
            className={'info-btn'}
            icon={faInfoCircle}
            title={props.hoverText}
          />
        );
      }
    }

    return (
      <div className={'label-row'}>
        {label}
        {infoIcon}
      </div>
    );
  };

  return (
    <div className={classList} style={props.style}>
      {getLabel()}
      <div className={'row'} style={{ position: 'relative', width: width }}>
        <div
          style={{
            color: 'white',
            position: 'absolute',
            top: 6,
            left: 10,
            zIndex: 1,
          }}
        >
          $
        </div>
        <_CurrencyInput
          id="currency-input"
          name="currency-input"
          placeholder="0.00"
          defaultValue={props.value}
          decimalsLimit={2}
          onValueChange={handleValueChange}
          onBlur={handleBlur}
          prefix=""
          style={{
            width: '100%',
            paddingLeft: '24px', // Increased padding for more space after $
          }}
        />
      </div>
    </div>
  );
}

export default CurrencyInput;