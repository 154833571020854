import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection } from '@mui/x-data-grid-pro'
import {
  tableSX,
  formatNumberForTable,
  activityTypes,
  BACKGROUND,
  formatDateForTable,
  capitalizeFirstLetterOfAWord,
  handleMUISorting
} from '../../logic/u'
import axios  from 'axios'
import { currentUser } from '../../firebase'
import {
  openModalAtom,
} from '../../types/global_types'
import { getConfig } from '../../config'
import { TransactionsDetailsModal } from '../modals/TransactionDetailsModal'
const config = getConfig() as any

export function TransactionsTab() {
  const [ transactions, setTransactions ] = useState<any[] | null>(null)
  const [, setOpenModal] = useAtom(openModalAtom)
  const defaultSortModel = [
    {
      field: 'date',
      sort: 'desc' as GridSortDirection
    },
  ];
  const [sortModel, setSortModel] = useState(defaultSortModel);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await currentUser()?.getIdToken()
        const endpoint = `${config.api_root_url}getAccountActivity?&getAll=true`
        const res = await axios.get(endpoint, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })

        const transactionsRaw = res.data
        const transactionsParsed: any[] = []
        if (transactionsRaw) {
          transactionsRaw.forEach((transaction: any) => {
            const transactionParsed: any = {}
            Object.keys(transaction).forEach(key => {
              let result
              const value = transaction[key]

              if (typeof value === 'string') {
                // Convert to number if it's numeric, otherwise keep as string
                result = !isNaN(Number(value)) ? parseFloat(value) : value;

              } else {
                // Preserve non-string values
                result = value
              }
              transactionParsed[key] = result
            })
            transactionsParsed.push(transactionParsed)
          })
        }
        setTransactions(transactionsParsed)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData()

    const intervalId = setInterval(() => {
      fetchData(); // Run every 5 seconds
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [])

  const transactionRows = transactions || []

  const getOverlay = () => {
    if (!transactions) {
      return (
        <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
          <div>Loading transactions...</div>
        </GridOverlay>
      )
    }
    return (
      <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
        <div>No transactions.</div>
      </GridOverlay>
    )
  }

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  return (
    <div className='tab-contents has-subtab'>
      <div className='table-container'>
        <DataGridPro
          rows={transactionRows}
          className='clickable-grid uppercase-grid'
          columns={[
            {
              field: '',
              headerName: 'type',
              flex: 1,
              type: 'string',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                const type = activityTypes.find((activityType) => activityType.activityType === params.row.activity_type)?.description || params.row.type
                return type.toLowerCase();
              }
            },
            {
              field: 'symbol',
              headerName: 'symbol',
              flex: .5,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                return params.row.symbol || '-'
              }
            },
            {
              field: 'side',
              headerName: 'side',
              flex: .5,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                return params.row.side || '-'
              }
            },
            {
              field: 'qty',
              headerName: 'qty',
              flex: .5,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                return params.row.qty || '-'
              }
            },
            {
              field: 'net_amount',
              headerName: 'amount',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                let amount = params.row.net_amount
                if (params.row.price) {
                  amount = params.row.price * params.row.qty
                }
                return formatNumberForTable(amount)
              }
            },
            {
              field: 'status',
              headerName: 'status',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                return params.row.status || params.row.order_status || '-'
              }
            },
            {
              field: 'date',
              headerName: 'transaction_time',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              valueGetter: (params) => {
                let date = params.row.date
                if (params.row.transaction_time) {
                  date = params.row.transaction_time
                }
                return formatDateForTable(date)
              }
            }
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: getOverlay,
          }}
          sx={tableSX}
          onRowClick={(params) => setOpenModal(<TransactionsDetailsModal transaction={params.row} />)}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total transactions:',
          }}
        />
      </div>
    </div>
  )
}