import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal'
import { DuplicateWatchlistModal } from '../modals/DuplicateWatchlistModal'
import { DeleteWatchlistModal } from '../modals/DeleteWatchlistModal'

interface WatchlistOptionsDropdownProps {
  watchlistId: string
  left: number
  top: number
  onClose: () => void
}

export const WatchlistOptionsDropdown = (props: WatchlistOptionsDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const {watchlistId, left, top, onClose} = props

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  let contents: DropdownItem[] = []

  contents = []
  contents.push(
    {
      display: (
        <div className='left'>
          <Icon
            icon='copy'
            set='sharp-solid'
            size={10}
            style={{marginRight: 10}}
          />
          <div>Duplicate</div>
        </div>
      ),
      onClick: async () => {
        setOpenModal(<DuplicateWatchlistModal watchlistId={watchlistId} />)
      }
    },
  )
  if (user?.watchlists.find(w => w.id !== watchlistId)?.isDefault) {
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='trash'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
            <div>Delete</div>
          </div>
        ),
        onClick: () => {
          setOpenModal(<DeleteWatchlistModal watchlistId={watchlistId} />)
        }
      }
    )
  }
  
  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={175}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}