/*

*/

import {
  NOCTIS_OBSCURO_YELLOW,
  NOCTIS_OBSCURO_ORANGE,
  NOCTIS_OBSCURO_MUSTARD,
  NOCTIS_OBSCURO_GREEN,
  NOCTIS_OBSCURO_PALE_PURPLE,
  NOCTIS_OBSCURO_GRAY,
  NOCTIS_OBSCURO_DULL_ORANGE,
  NOCTIS_OBSCURO_PINK,
  NOCTIS_OBSCURO_BLUE,
  NOCTIS_OBSCURO_PURPLE,
} from './colors'

import { User, Indicator, UserLiveDoc } from '../types/user_types'
import { db } from '../firebase'
import firebase from 'firebase/app'
import { uuid } from './u'
import { SMA, EMA, BollingerBands, VWAP, RSI, MACD } from 'technicalindicators'

export const get_defaults = (indicator_value: string) => {
  switch (indicator_value) {
    case 'SMA':
      return {
        type: 'technical',
        fields: {
          period: {index: 0, type: 'number', label: 'Period', value: 9, min: 0, max: 200}
        },
        plots: {
          sma: {style: 'line', color: NOCTIS_OBSCURO_BLUE, weight: 2, index: 0}
        },
        oscillator_settings: null,
      }
    case 'EMA':
      return {
        type: 'technical',
        fields: {
          period: {index: 0, type: 'number', label: 'Period', value: 9, min: 2, max: 200}
        },
        plots: {
          ema: {style: 'line', color: NOCTIS_OBSCURO_GREEN, weight: 2, index: 0}
        },
        oscillator_settings: null
      }
    case 'BOLL':
      return {
        type: 'technical',
        fields: {
          period: {index: 0, type: 'number', label: 'Period', value: 20, min: 2, max: 200},
          stdDev: {index: 1, type: 'number', label: 'Standard deviation', value: 2, min: 0, max: 10}
        },
        plots: {
          upper: {style: 'line', color: NOCTIS_OBSCURO_PALE_PURPLE, weight: 2, visible: true, index: 0},
          lower: {style: 'line', color: NOCTIS_OBSCURO_PALE_PURPLE, weight: 2, visible: true, index: 1},
          middle: {style: 'line', color: NOCTIS_OBSCURO_GRAY, weight: 2, visible: true, index: 2},
        },
        oscillator_settings: null
      }
    case 'VWAP':
      return {
        type: 'technical',
        fields: {
          period: {index: 0, type: 'number', label: 'Period', value: 14, min: 2, max: 200}
        },
        plots: {
          VWAP: {style: 'line', color: NOCTIS_OBSCURO_PURPLE, weight: 2, index: 0}
        },
        oscillator_settings: null
      }
    case 'RSI':
      return {
        type: 'technical',
        fields: {
          period: {index: 0, type: 'number', label: 'Period', value: 14, min: 2, max: 200}
        },
        plots: {
          RSI: {style: 'line', color: NOCTIS_OBSCURO_PINK, weight: 2, index: 0}
        },
        oscillator_settings: {
          zero_band: false,
          upper_band: 70,
          lower_band: 30,
          max: 100,
          min: 0,
          auto_limits: false,
        }
      }
    case 'MACD':
      return {
        type: 'technical',
        fields: {
          fastPeriod: {index: 0, type: 'number', label: 'Fast Period', value: 12, min: 2, max: 200},
          slowPeriod: {index: 1, type: 'number', label: 'Slow Period', value: 26, min: 2, max: 200},
          signalPeriod: {index: 2, type: 'number', label: 'Signal Period', value: 9, min: 2, max: 200}
        },
        plots: {
          MACD: {style: 'line', color: NOCTIS_OBSCURO_GREEN, weight: 2, visible: true, index: 0},
          signal: {style: 'line', color: NOCTIS_OBSCURO_ORANGE, weight: 2, visible: true, index: 1},
          histogram: {style: 'histogram', color: NOCTIS_OBSCURO_BLUE, visible: true, index: 2}
        },
        oscillator_settings: {
          zero_band: true,
          auto_limits: true
        }
      }
    case 'qty':
      return {
        type: 'internal',
        fields: {},
        plots: {
          qty: {style: 'line', color: NOCTIS_OBSCURO_YELLOW, weight: 2, index: 0}
        },
        oscillator_settings: {
          min: 0,
          auto_limits: true,
          enforce_zero: true,
        }
      }
    case 'day_price_change':
      return {
        type: 'internal',
        fields: {},
        plots: {
          day_price_change: {style: 'line', color: NOCTIS_OBSCURO_MUSTARD, weight: 2, index: 0}
        },
        oscillator_settings: {
          auto_limits: true,
          zero_band: false,
          enforce_zero: true
        }
      }
    case 'day_price_change_pc':
      return {
        type: 'internal',
        fields: {},
        plots: {
          day_price_change_pc: {style: 'line', color: NOCTIS_OBSCURO_DULL_ORANGE, weight: 2, index: 0}
        },
        oscillator_settings: {
          auto_limits: true,
          zero_band: false,
          enforce_zero: true
        }
      }
    case 'mkt_value':
      return {
        type: 'internal',
        fields: {},
        plots: {
          mkt_value: {style: 'line', color: NOCTIS_OBSCURO_GREEN, weight: 2, index: 0}
        },
        oscillator_settings: {
          auto_limits: true,
          zero_band: false,
          enforce_zero: true
        }
      }
    case 'day_unrealized_pl':
      return {
        type: 'internal',
        fields: {},
        plots: {
          day_unrealized_pl: {style: 'line', color: NOCTIS_OBSCURO_PURPLE, weight: 2, index: 0}
        },
        oscillator_settings: {
          auto_limits: true,
          zero_band: false,
          enforce_zero: true
        }
      }
    case 'day_unrealized_pl_pc':
      return {
        type: 'internal',
        fields: {},
        plots: {
          day_unrealized_pl_pc: {style: 'line', color: NOCTIS_OBSCURO_PALE_PURPLE, weight: 2, index: 0}
        },
        oscillator_settings: {
          auto_limits: true,
          zero_band: false,
          enforce_zero: true
        }
      }
    case 'unrealized_pl':
      return {
        type: 'internal',
        fields: {},
        plots: {
          unrealized_pl: {style: 'line', color: NOCTIS_OBSCURO_BLUE, weight: 2, index: 0}
        },
        oscillator_settings: {
          auto_limits: true,
          zero_band: false,
          enforce_zero: true
        }
      }
    case 'unrealized_pl_pc':
      return {
        type: 'internal',
        fields: {},
        plots: {
          unrealized_pl_pc: {style: 'line', color: NOCTIS_OBSCURO_PINK, weight: 2, index: 0}
        },
        oscillator_settings: {
          auto_limits: true,
          zero_band: false,
          enforce_zero: true
        }
      }
    case 'avg_entry_price':
      return {
        type: 'internal',
        fields: {},
        plots: {
          avg_entry_price: {style: 'line', color: NOCTIS_OBSCURO_GRAY, weight: 2, index: 0}
        },
        oscillator_settings: null
      }
    case 'cost_basis':
      return {
        type: 'internal',
        fields: {},
        plots: {
          cost_basis: {style: 'line', color: NOCTIS_OBSCURO_ORANGE, weight: 2, index: 0}
        },
        oscillator_settings: {
          auto_limits: true,
          zero_band: false,
          enforce_zero: true
        }
      }
    default:
      return {}
  }
}

export const make_abbrev_string = (indicator: Indicator) => {
  const fields = Object.keys(indicator.fields)
  const fields_in_order = fields.sort((a, b) => indicator.fields[a].index - indicator.fields[b].index)
  let abbrev_str = indicator.value
  let abbrev_str_hovertext = indicator.display + '—'
  for (let i = 0; i < fields_in_order.length; i++) {
    const field_key = fields_in_order[i]
    const field_val = indicator.fields[field_key]?.value
    abbrev_str += `-${field_val}`
    abbrev_str_hovertext += `${field_key}: ${field_val}, `
  }
  abbrev_str_hovertext = abbrev_str_hovertext.slice(0, -2)

  return {abbrev_str, abbrev_str_hovertext}
}

interface AddIndicatorProps {
  indicator: any
  interval: string
  symbol: string
  user: User
}

export const add_indicator = async (props: AddIndicatorProps) => {
  const { indicator, interval, symbol, user } = props
  if (!user) return ''

  // Ensure the charts object and specific symbol object exist
  const userDoc = db.collection('users').doc(user.uid)
  const userData = (await userDoc.get()).data() as User

  if (!userData.charts) {
    await userDoc.update({ charts: {} })
  }

  if (!userData.charts[symbol]) {
    await userDoc.update({ [`charts.${symbol}`]: { indicators: {} } })
  }

  const indicator_instance_id = uuid()

  // Figure out what the highest index currently is
  let highest_index = 0
  const indicators_for_chart = userData.charts[symbol]?.indicators || {}
  for (const id in indicators_for_chart) {
    const indicator = indicators_for_chart[id]
    if (indicator.index > highest_index) highest_index = indicator.index
  }

  const ind: Indicator = {
    symbol,
    abbrev_str: '',
    display: indicator.display,
    value: indicator.value,
    intervals: [],
    index: highest_index + 1,
    id: indicator_instance_id,
    abbrev_str_hovertext: '',
    type: 'technical',
    fields: {},
    plots: {},
    oscillator_settings: null,
    created_date_iso: new Date().toISOString(),
  }


  // Get and fill in defaults
  const { fields, plots, oscillator_settings, type } = get_defaults(ind.value)
  ind.fields = fields
  ind.plots = plots
  ind.oscillator_settings = oscillator_settings
  ind.type = type || 'technical'

  // Make the abbreviation string. We'll repeat this when fields are updated
  const { abbrev_str, abbrev_str_hovertext } = make_abbrev_string(ind)
  ind.abbrev_str = abbrev_str
  ind.abbrev_str_hovertext = abbrev_str_hovertext

  // If this is an oscillator, set it visible and all the others hidden
  if (ind.oscillator_settings) {
    const other_oscillators = list_oscillators(user, symbol)
    other_oscillators.forEach(async (osc: any) => {
      await userDoc.update({
        [`charts.${symbol}.indicators.${osc.id}.isHidden`]: true
      })
    })
  }

  await userDoc.update({
    [`charts.${symbol}.indicators.${indicator_instance_id}`]: ind
  })

  return indicator_instance_id
}

interface UpdateIndicatorProps {
  indicator_instance_id: string
  user: User
  parameters: any
}

export const update_indicator = async (props: UpdateIndicatorProps) => {
  const { indicator_instance_id, parameters, user } = props
  if (!user) {
    throw new Error("User is not defined")
  }

  const userDoc = db.collection('users').doc(user.uid)
  const userData = (await userDoc.get()).data() as User
  const symbol = Object.keys(userData.charts).find(symbol =>
    userData.charts[symbol].indicators?.[indicator_instance_id]
  )

  if (!symbol) {
    throw new Error("Indicator not found")
  }

  const updates = Object.keys(parameters).reduce((acc: any, key) => {
    acc[`charts.${symbol}.indicators.${indicator_instance_id}.settings.${key}`] = parameters[key]
    return acc
  }, {})
  await userDoc.update(updates)

  // Get updated user doc
  const updated_user = (await userDoc.get()).data() as User
  const updated_indicator = updated_user.charts[symbol].indicators[indicator_instance_id]
  const { abbrev_str, abbrev_str_hovertext } = make_abbrev_string(updated_indicator)
  await userDoc.update({
    [`charts.${symbol}.indicators.${indicator_instance_id}.abbrev_str`]: abbrev_str,
    [`charts.${symbol}.indicators.${indicator_instance_id}.abbrev_str_hovertext`]: abbrev_str_hovertext
  })
}

export const list_oscillators = (user: User, symbol: string) => {
  const indicators = user.charts[symbol]?.indicators || {}
  const oscillator_ids = Object.keys(indicators).filter(id => indicators[id].oscillator_settings !== null)
  const oscillators = oscillator_ids.map(id => indicators[id])
  const sorted_oscillators = oscillators.sort((a, b) => {
    return new Date(a.created_date_iso).getTime() - new Date(b.created_date_iso).getTime()
  })

  return sorted_oscillators
}

export const delete_indicator = async (indicator_instance_id: string, user: User) => {
  if (!user) throw new Error("User is not defined")

  const userDoc = db.collection('users').doc(user.uid)

  // Use a transaction to ensure consistency
  return db.runTransaction(async (transaction) => {
    const userSnapshot = await transaction.get(userDoc)
    const userData = userSnapshot.data() as User

    const symbol = Object.keys(userData.charts).find(symbol =>
      userData.charts[symbol].indicators?.[indicator_instance_id]
    )

    if (!symbol) {
      throw new Error("Indicator not found")
    }

    const indicators = userData.charts[symbol].indicators
    const targetIndicator = indicators[indicator_instance_id]
    const is_oscillator = targetIndicator.oscillator_settings !== null

    if (is_oscillator) {
      const other_oscillators = Object.entries(indicators)
        .filter(([id, ind]) => id !== indicator_instance_id && ind.oscillator_settings !== null)

      if (other_oscillators.length > 0) {
        const [next_oscillator_id] = other_oscillators[0]
        transaction.update(userDoc, {
          [`charts.${symbol}.indicators.${next_oscillator_id}.isHidden`]: false
        })
      }
    }

    // Delete the indicator
    transaction.update(userDoc, {
      [`charts.${symbol}.indicators.${indicator_instance_id}`]: firebase.firestore.FieldValue.delete()
    })

    return { success: true, message: "Indicator deleted successfully" }
  }).catch(error => {
    console.error("Error deleting indicator:", error)
    throw new Error("Failed to delete indicator")
  })
}

export const toggle_indicator_visibility = async (indicator_instance_id: string, user: User) => {
  const userDoc = db.collection('users').doc(user.uid)
  const userData = (await userDoc.get()).data() as User
  const symbol = Object.keys(userData.charts).find(symbol =>
    userData.charts[symbol].indicators?.[indicator_instance_id]
  )

  if (!symbol) {
    throw new Error("Indicator not found")
  }

  const is_currently_hidden = userData.charts[symbol].indicators[indicator_instance_id].isHidden
  await userDoc.update({
    [`charts.${symbol}.indicators.${indicator_instance_id}.isHidden`]: !is_currently_hidden
  })
}

export const update_abbrev_string = async (indicator: Indicator, user_id: string, symbol: string) => {
  const {abbrev_str, abbrev_str_hovertext} = make_abbrev_string(indicator)
  const userDoc = db.collection('users').doc(user_id)
  await userDoc.update({
    [`charts.${symbol}.indicators.${indicator.id}.abbrev_str`]: abbrev_str,
    [`charts.${symbol}.indicators.${indicator.id}.abbrev_str_hovertext`]: abbrev_str_hovertext
  })
}

// Returns object keyed by abbreviation string
export const get_indicators_for_chart = (user: User, symbol: string, interval: string): any => {
  const indicators_for_chart: any = {}
  const indicators = user.charts[symbol]?.indicators || {}
  for (const id in indicators) {
    const indicator = indicators[id]

    // If symbol match, include, unless intervals are specified, in which case only include if matches
    if (indicator.symbol === symbol) {
      if (indicator.intervals.length) {
        if (indicator.intervals.includes(interval)) {
          indicators_for_chart[indicator.abbrev_str] = indicator
        }
      } else {
        indicators_for_chart[indicator.abbrev_str] = indicator
      }
    }
  }
  return indicators_for_chart
}
export const add_indicators_if_missing = (user: User, symbol: string, interval: string, bars: any, uld: any): any => {
  const indicators_we_need = get_indicators_for_chart(user, symbol, interval)

  const indicators_present: any = {}
  const last_bar = bars[bars.length - 1]
  for (const key in last_bar.overlays || {}) {
    indicators_present[key] = true
  }
  for (const key in last_bar.oscillators || {}) {
    indicators_present[key] = true
  }

  // Are we missing anything?
  const missing_indicators = Object.keys(indicators_we_need).filter((key) => !indicators_present[key])

  if (!missing_indicators.length) return bars
  return add_indicators_to_dataset({indicators: indicators_we_need, bars, interval, uld, symbol})
}


interface AddIndicatorsToDatasetProps {
  indicators: any
  bars: any
  interval: string
  uld: UserLiveDoc      // needed for internal indicators
  symbol?: string       // needed for internal indicators
}
export const add_indicators_to_dataset = (props: AddIndicatorsToDatasetProps): any => {
  const { indicators, bars, interval, uld, symbol } = props
  const close_prices = bars.map((bar: any) => bar.c)

  const add_sma = (indicator: Indicator) => {
    const sma = SMA.calculate({
      values: close_prices,
      period: indicator.fields.period?.value || 9,
    })
    const startIndex = bars.length - sma.length
    for (let i = startIndex; i < bars.length; i++) {
      bars[i].overlays = bars[i].overlays || {}
      bars[i].overlays[indicator.abbrev_str] = sma[i - startIndex]
    }

    // Add formatted values string
  }

  const add_ema = (indicator: Indicator) => {
    const ema = EMA.calculate({
      values: close_prices,
      period: indicator.fields?.period.value || 9,
    })
    const startIndex = bars.length - ema.length
    for (let i = startIndex; i < bars.length; i++) {
      bars[i].overlays = bars[i].overlays || {}
      bars[i].overlays[indicator.abbrev_str] = ema[i - startIndex]
    }
  }

  const add_boll = (indicator: Indicator) => {
    const boll = BollingerBands.calculate({
      values: close_prices,
      period: indicator.fields.period?.value || 20,
      stdDev: indicator.fields.stdDev?.value || 2,
    })
    const startIndex = bars.length - boll.length
    for (let i = startIndex; i < bars.length; i++) {
      bars[i].overlays = bars[i].overlays || {}
      bars[i].overlays[indicator.abbrev_str] = boll[i - startIndex]
    }
  }

  const add_vwap = (indicator: Indicator) => {
    const vwap = VWAP.calculate({
      //@ts-ignore
      open: bars.map((bar: any) => bar.o),
      high: bars.map((bar: any) => bar.h),
      low: bars.map((bar: any) => bar.l),
      close: bars.map((bar: any) => bar.c),
      volume: bars.map((bar: any) => bar.v),
    })
    const startIndex = bars.length - vwap.length
    for (let i = startIndex; i < bars.length; i++) {
      bars[i].overlays = bars[i].overlays || {}
      bars[i].overlays[indicator.abbrev_str] = vwap[i - startIndex]
    }
  }

  const add_rsi = (indicator: Indicator) => {
    const rsi = RSI.calculate({
      values: close_prices,
      period: indicator.fields.period?.value || 14,
    })
    const startIndex = bars.length - rsi.length
    for (let i = startIndex; i < bars.length; i++) {
      bars[i].oscillators = bars[i].oscillators || {}
      bars[i].oscillators[indicator.value] = rsi[i - startIndex]
      // bars[i].oscillators[indicator.abbrev_str] = rsi[i - startIndex]
    }
  }

  const add_macd = (indicator: Indicator) => {
    const macd = MACD.calculate({
      values: close_prices,
      fastPeriod: indicator.fields.fastPeriod?.value || 12,
      slowPeriod: indicator.fields.slowPeriod?.value || 26,
      signalPeriod: indicator.fields.signalPeriod?.value || 9,
      SimpleMAOscillator: false,
      SimpleMASignal: false,
    })
    const startIndex = bars.length - macd.length
    for (let i = startIndex; i < bars.length; i++) {
      bars[i].oscillators = bars[i].oscillators || {}
      bars[i].oscillators[indicator.abbrev_str] = macd[i - startIndex]
    }
  }


  // MORNING: figure out what indicators is and if it's wrong
  // then whether we need internal data
  // then fetch it
  // then consume it
  const internal_charts_needed: string[] = []
  Object.keys(indicators).forEach((key: string) => {
    const indicator = indicators[key]
    if (indicator.type === 'internal') {
      internal_charts_needed.push(indicator.value)
    }
  })

  // Get data for internal charts


  const add_internal = (indicator: Indicator) => {
    internal_charts_needed.forEach((chart_name: string) => {
      for (let i = 0; i < bars.length; i++) {
        const bar = bars[i]
        const val = get_closest_value_from_eelogs({
          symbol: symbol || '',
          property: chart_name,
          eelogs: uld.cachedDayLogs,
          isoDate: bar.t
        })
        const is_oscillator = indicator.oscillator_settings !== null
        if (is_oscillator) {
          bars[i].oscillators = bars[i].oscillators || {}
          bars[i].oscillators[chart_name] = val
        } else {
          bars[i].overlays = bars[i].overlays || {}
          bars[i].overlays[chart_name] = val
        }
      }
    })
  }

  // If this is an internal chart, prepare the data we'll need
  // if ()


  // Sort indicators by type
  const indicators_for_chart: any = []
  const ind_keys = Object.keys(indicators)
  for (let i = 0; i < ind_keys.length; i++) {
    const key = ind_keys[i]
    const indicator = indicators[key]
    if ((indicator.intervals.length > 0) && (!indicator.intervals.includes(interval))) continue
    if (indicator.isHidden) continue
    indicators_for_chart.push(indicator)
  }

  // Sort indicators by type
  const SMA_indicators = indicators_for_chart.filter((indicator: any) => indicator.value === 'SMA')
  const EMA_indicators = indicators_for_chart.filter((indicator: any) => indicator.value === 'EMA')
  const BOLL_indicators = indicators_for_chart.filter((indicator: any) => indicator.value === 'BOLL')
  const VWAP_indicators = indicators_for_chart.filter((indicator: any) => indicator.value === 'VWAP')
  const RSI_indicators = indicators_for_chart.filter((indicator: any) => indicator.value === 'RSI')
  const MACD_indicators = indicators_for_chart.filter((indicator: any) => indicator.value === 'MACD')
  const internal_indicators = indicators_for_chart.filter((indicator: any) => indicator.type === 'internal')

  // Add indicators to bars
  SMA_indicators.forEach((indicator: Indicator) => add_sma(indicator))
  EMA_indicators.forEach((indicator: Indicator) => add_ema(indicator))
  BOLL_indicators.forEach((indicator: Indicator) => add_boll(indicator))
  VWAP_indicators.forEach((indicator: Indicator) => add_vwap(indicator))
  RSI_indicators.forEach((indicator: Indicator) => add_rsi(indicator))
  MACD_indicators.forEach((indicator: Indicator) => add_macd(indicator))
  internal_indicators.forEach((indicator: Indicator) => add_internal(indicator))

  return bars
}

interface GetClosestValueFromEELogsProps {
  symbol: string
  property: string
  eelogs: any
  isoDate: string
}
const get_closest_value_from_eelogs = (props: GetClosestValueFromEELogsProps) => {
  const { symbol, property, eelogs, isoDate } = props;
  const path = `POSITIONS.${symbol}.${property}`;
  const targetDate = new Date(isoDate);

  let closestValue = 0;

  for (let i = 0; i < eelogs.length; i++) {
    const log = eelogs[i];
    const logDate = new Date(log.time_iso);

    if (logDate > targetDate) {
      break;  // Since logs are ordered by date, stop if we've passed the target date
    }

    // Attempt to retrieve the value using the path; default to 0 if undefined along the path
    const value = path.split('.').reduce((acc, key) => acc && acc[key], log._liveData) ?? 0;

    // Update closestValue if a value (including 0) is found
    closestValue = value;
  }

  // console.log(`Closest value for ${property} at ${isoDate}: ${closestValue}`)

  return closestValue
}
