import axios  from 'axios'
import React, { useState, useEffect } from 'react'
import { formatNumberForTable, formatPercent, updateLiveData } from '../../logic/u'
import { currentUser } from '../../firebase'
import { Modal, showModal } from '../reusable'

import { getConfig } from '../../config'
import { ErrorModal } from './ErrorModal'
import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { CreateOrderModal } from './CreateOrderModal'
const config = getConfig() as any

export interface PositionDetailsModalProps {
  symbol: string
}

export const PositionDetailsModal = (props: PositionDetailsModalProps) => {
  // General helpers
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)

  if (!user) return null
  if (!uld) return null

  const position = uld.liveData.POSITIONS[props.symbol]

  const contents = (
    <div className='column width-full font-size-13 orange-text'>
      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>symbol: </div>
        <div className='orange-text'>{position.symbol}</div>
      </div>
      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>asset_class: </div>
        <div className='orange-text'>{position.asset_class}</div>
      </div>
      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>exchange: </div>
        <div className='orange-text'>{position.exchange}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>qty: </div>
        <div className='orange-text'>{position.qty}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>current_price: </div>
        <div className='orange-text'>{formatNumberForTable(position.current_price)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>last_price: </div>
        <div className='orange-text'>{formatNumberForTable(position.last_price)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>day_price_change: </div>
        <div className={position.day_price_change >= 0 ? ' pos' : ' neg'}>{formatNumberForTable(position.day_price_change)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>day_price_change_pc: </div>
        <div className={position.day_price_change >= 0 ? ' pos' : ' neg'}>{formatPercent(position.day_price_change_pc)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>avg_entry_price: </div>
        <div className='orange-text'>{formatNumberForTable(position.avg_entry_price)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>cost_basis: </div>
        <div className='orange-text'>{formatNumberForTable(position.cost_basis)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>mkt_value: </div>
        <div className={position.unrealized_pl >= 0 ? ' pos' : ' neg'}>{formatNumberForTable(position.mkt_value)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>day_unrealized_pl: </div>
        <div className={position.day_unrealized_pl >= 0 ? ' pos' : ' neg'}>{formatNumberForTable(position.day_unrealized_pl)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>day_unrealized_pl_pc: </div>
        <div className={position.day_unrealized_pl >= 0 ? ' pos' : ' neg'}>{formatPercent(position.day_unrealized_pl_pc)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>unrealized_pl: </div>
        <div className={position.unrealized_pl >= 0 ? ' pos' : ' neg'}>{formatNumberForTable(position.unrealized_pl)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className='orange-text'>unrealized_pl_pc: </div>
        <div className={position.unrealized_pl >= 0 ? ' pos' : ' neg'}>{formatPercent(position.unrealized_pl_pc)}</div>
      </div>



    </div>
  )

  return (
    <Modal
      title='Position details'
      contents={[contents]}
      yesButtonText='Close position'
      isDanger
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const closePositionUrl = `${config.api_root_url}closePosition?symbol=${position.symbol}`
          await axios.delete(closePositionUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
      secondaryButtonText='Trade'
      secondaryOnClick={() => {
        setOpenModal(<CreateOrderModal symbol={position.symbol} />)
      }}
    />
  )
}