import axios  from 'axios'
import React, { useState, useEffect } from 'react'
import { formatDateForTable, formatNumberForTable } from '../../logic/u'
import { currentUser } from '../../firebase'
import { Modal, showModal } from '../reusable'

import { getConfig } from '../../config'
import { ErrorModal } from './ErrorModal'
import { useAtom } from 'jotai'
import { openModalAtom, transfersAtom } from '../../types/global_types'
const config = getConfig() as any

export interface TransferDetailsModalProps {
  transferId: string
}

export const TransferDetailsModal = (props: TransferDetailsModalProps) => {
  const [ transfers ] = useAtom(transfersAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [, setButtonIsLoading] = useState<boolean>(false)

  const transfer = transfers?.find(item => item.id === props.transferId);
  const contents = (
    <div className='column width-full font-size-13 orange-text'>

      <div className='row space-between margin-bottom-10'>
        <div className=''>type: </div>
        <div className=''>{transfer.type}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>direction: </div>
        <div className=''>{transfer.direction}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>status: </div>
        <div className=''>{transfer.status}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>amount: </div>
        <div className=''>{formatNumberForTable(transfer.amount)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>requested_amount: </div>
        <div className=''>{formatNumberForTable(transfer.requested_amount)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>fee: </div>
        <div className=''>{formatNumberForTable(transfer.fee)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>created_at: </div>
        <div className=''>{formatDateForTable(transfer.created_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>updated_at: </div>
        <div className=''>{formatDateForTable(transfer.updated_at)}</div>
      </div>

      <div className='row space-between margin-bottom-10'>
        <div className=''>expires_at: </div>
        <div className=''>{formatDateForTable(transfer.expires_at)}</div>
      </div>
    </div>
  )

  return (
    <Modal
      title='Transfer details'
      contents={[contents]}
      yesButtonText='Cancel transfer'
      isDanger={true}
      isButtonDisabled={['REJECTED', 'CANCELED', 'APPROVED', 'COMPLETE', 'RETURNED'].includes(transfer.status)}
      twoStepConfirmation={true}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const deleteTransferUrl = `${config.api_root_url}deleteTransfer?transferId=${transfer.id}`
          await axios.delete(deleteTransferUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}