import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection } from '@mui/x-data-grid-pro'
import {
  tableSX,
  formatNumberForTable,
  formatDateForTable,
  BACKGROUND,
  handleMUISorting
} from '../../logic/u'
import {
  openModalAtom,
  ordersAtom,
} from '../../types/global_types'
import { OrderDetailsModal } from '../modals/OrderDetailsModal'

export function OrdersTab() {
  const [ orders ] = useAtom(ordersAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const defaultSortModel = [
    {
      field: 'submitted_at',
      sort: 'desc' as GridSortDirection
    },
  ];
  const [sortModel, setSortModel] = useState(defaultSortModel);

  // in this case recent is all
  const orderRows = orders?.recent || []

  const getOverlay = () => {
    if (!orders) {
      return (
        <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
          <div>Loading orders...</div>
        </GridOverlay>
      )
    }
    return (
      <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
        <div>No orders.</div>
      </GridOverlay>
    )
  }

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  }

  return (
    <div className='tab-contents has-subtab'>
      <div className='table-container'>
        <DataGridPro
          className='clickable-grid uppercase-grid'
          rows={orderRows}
          columns={[
            {
              field: 'symbol',
              headerName: 'symbol',
              flex: 1,
              type: 'string',
              sortComparator: handleMUISorting,
            },
            {
              field: 'side',
              headerName: 'side',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting,
            },
            {
              field: 'type',
              headerName: 'type',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting,
            },
            {
              field: 'notional',
              headerName: 'notional',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter(params) {
                return formatNumberForTable(params.row.notional)
              },
            },
            {
              field: 'qty',
              headerName: 'qty',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter(params) {
                return formatNumberForTable(params.row.qty).split('.')[0]
              },
            },
            {
              field: 'filled_qty',
              headerName: 'filled_qty',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
            },
            {
              field: 'filled_avg_price',
              headerName: 'filled_avg_price',
              flex: 1, headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter(params) {
                return formatNumberForTable(params.row.filled_avg_price)
              },
            },
            {
              field: 'submitted_at',
              headerName: 'submitted_at',
              flex: 1, headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              valueGetter(params) {
                return formatDateForTable(params.row.submitted_at)
              },
            },
            {
              field: 'filled_at',
              headerName: 'filled_at',
              flex: 1, headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              valueGetter(params) {
                return formatDateForTable(params.row.filled_at)
              },
            },
            {
              field: 'status',
              headerName: 'status',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting,
            },
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: getOverlay,
          }}
          sx={tableSX}
          // pagination
          // paginationMode='client'
          // rowsPerPageOptions={[10, 25, 50, 100, 500, orderRows.length]}
          onRowClick={(params) => setOpenModal(<OrderDetailsModal order={params.row} />)}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total orders:',
          }}
        />
      </div>
    </div>
  )
}