/*
INPUT

    - type: string
      - text
      - range
      - date
      - datetime-local
      - email
      - password
    - label: string
    - placeholder: string
    - value: string
    - initialValue: string
    - onBlur: (value) => void
    - isError: function, takes value, returns true if error on blur. Corrects on keydown.


*/


import React from 'react'
import './Input.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export class Input extends React.Component {
  constructor(props) {
    super(props)
    this.className = this.props.className
    this.type = this.props.type || 'text' || 'number'
    this.label = this.props.label
    this.placeholder = this.props.placeholder
    this.initialValue = this.props.initialValue
    this.onChange = this.props.onChange
    this.onBlur = this.props.onBlur
    this.isError = this.props.isError
    this.hoverText = this.props.hoverText
    this.autoFocus = this.props.autoFocus
    this.maxLength = this.props.maxLength
    this.variant = this.props.variant
    this.noLabel = this.props.noLabel
    this.max = this.props.max               // use for number only
    this.min = this.props.min               // use for number only

    this.inputRef = React.createRef()
    this.style = this.props.style
  }

  state = {
    // inError: false,
    errorMessage: '',
    value: ''
  }

  componentDidMount() {
    this.setState({ value: this.props.initialValue })
  }

  render() {
    let classList = 'input-component'
    if (this.className) {
      classList += ' ' + this.className
    }

    return (
      <div className={classList} style={this.style}>
        {this.getLabel()}
        <input
          type={this.type}
          className={''}
          placeholder={this.placeholder}
          value={this.state.value}
          ref={this.inputRef}
          spellCheck={false}
          autoFocus={this.autoFocus}
          maxLength={this.maxLength}
          max={this.max}
          min={this.min}
          onChange={(e) => {
            const newValue = e.target.value
            if (this.onChange) {
              const updatedValue = this.onChange(newValue)
              if (typeof updatedValue === 'string') {
                this.setState({ value: updatedValue })
                return
              }
            }
            this.setState({ value: newValue })

            // If we're in error, see if we aren't anymore
            if (this.state.errorMessage) {
              this.checkError()
            }
          }}
          onBlur={(e) => {
            if (this.onBlur) {
              this.onBlur(e.target.value)
            }
            if (this.isError) {
              this.checkError()
            }
          }}
        />
        {this.state.errorMessage ? (
          <div className='error-message'>
            {this.state.errorMessage}
          </div>
        ) : null}
      </div>
    )
  }

  clear() {
    this.setState({ value: '' })
  }

  checkError() {
    const errorMessage = this.isError(this.inputRef.current.value)
    this.setState({ errorMessage })
    this.inputRef.current.classList.remove('error')
    if (errorMessage) {
      this.inputRef.current.classList.add('error')
    }
  }

  getLabel() {
    let label
    let infoIcon
    if (this.label) {
      label = (
        <div className={'label ' + this.variant || ''}>{this.label}</div>
      )
      if (this.hoverText) {
        infoIcon = (
          <FontAwesomeIcon
            className={'info-btn'}
            icon={faInfoCircle}
            title={this.hoverText}
          />
        )
      }
    }

    let labelStyle
    if (this.noLabel) {
      labelStyle = {display: 'none'}
    }

    return (
      <div className={'label-row'} style={labelStyle}>
        {label}
        {infoIcon}
      </div>
    )
  }
}