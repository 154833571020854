import './LeftPane.scss'
import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  selectedEmptyChartAtom,
} from '../../types/global_types'
import { valueForTable, GREEN_PARTIAL, RED_PARTIAL, returnChartPaneContextObj, set_open_chart, updateLiveData } from '../../logic/u'
import { Icon } from '../reusable'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Chart, WatchlistObj } from '../../types/user_types'
import { AddTickersModal } from '../modals/AddTickersModal'
import { PositionDetailsModal } from '../modals/PositionDetailsModal';
import { WatchlistDropdown } from '../dropdowns/WatchlistsDropdown';
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal';

export const LeftPane = () => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom)
  const [ tab, setTab ] = useState<'tickers' | 'balances'>('tickers')


  // NOTE: if liveData hasn't loaded, sidebar will be missing
  if (!user) return null
  if (!uld || !uld.liveData) return null

  return (
    <div className={'left-pane'}>
      <div className='header'>
        <div className='row'>
          <div
            className={tab === 'tickers' ? 'header-tab selected' : 'header-tab'}
            onClick={() => {setTab('tickers')}}
          >
            Tickers
          </div>
          <div
            className={tab === 'balances' ? 'header-tab selected' : 'header-tab'}
            onClick={() => {setTab('balances')}}
          >
            Balances
          </div>
        </div>
      </div>
      {tab === 'tickers' ? <TickersList /> : <BalancesList />}
    </div>
  )
}

const TickersList = () => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyChart] = useAtom(selectedEmptyChartAtom)
  const [tickers, setTickers] = useState<WatchlistObj['tickers']>([])
  const [validDrop, setValidDrop] = useState<boolean>(false)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const tickerDropdownRef = useRef<HTMLDivElement>(null);
  

  // Render tree
  useEffect(() => {
    setTickers(user?.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.tickers || [])
  }, [user])

  const onDragUpdate = (update) => {
    const { source, destination } = update
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let tickersClone = Array.from(tickers)

    // Reorder buckets
    const [reorderedItem] = tickersClone.splice(source.index, 1) // remove dragged item
    tickersClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setTickers(tickersClone) // update local state to smooth transition
    db.collection('users').doc(user?.uid).update({ watchlists: user?.watchlists.map(w =>
      w.id === user.selectedWatchlistIdHomeTab ? { ...w, tickers: tickersClone } : w
    )});
  }

  if (!user) return null
  if (!uld || !uld.liveData) return null

  return <>
    <div className='subheader'>
      {openDropdown}
      <div
        className={user.watchlists?.length ? 'watchlist-dropdown-container' : 'row'}
        ref={tickerDropdownRef}
        onClick={(e) => {
          if (!user.watchlists?.length) {
            return
          }
          const rect = tickerDropdownRef.current?.getBoundingClientRect();
          if (rect) {
            const clickX = rect.left
            const clickY = rect?.top - rect?.height + 40
            setOpenDropdown(
              <WatchlistDropdown
                left={clickX}
                top={clickY}
                onClose={() => setOpenDropdown(null)}
              />
            )
          }
        }}
      >
        <div className={'watchlist-dropdown-title'}>
          {user.watchlists?.find(w => w.id === user.selectedWatchlistIdHomeTab)?.name}
        </div>
        {user.watchlists?.length ? <Icon
          icon='chevron-down'
          className='custom-dropdown-icon'
          size={9}
          style={{marginLeft: 5}}
        /> : null}
      </div>
      <Icon
        icon='plus'
        set='sharp-solid'
        size={11}
        style={{
          marginTop: 2
        }}
        onClick={() => {
          if (user.watchlists?.length) {
            setOpenModal(<AddTickersModal watchlistId={user.selectedWatchlistIdHomeTab} />)
            return
          }
          setOpenModal(<CreateWatchlistModal fromHome={true} />)
        }}
      />
    </div>
    <div className='pane-body'>
      {user.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.tickers.length ? <DragDropContext
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
        key={'modal-content'}
      >
        <div className='droppable-container'>
          <Droppable droppableId={'1'} type='bucket'>
            {(provided, snapshot) => (
              <div
                className='draggable-container'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {tickers.map((t, index) => {
                  if (!Object.keys(user.charts).includes(t)) {
                    return
                  }
                  const ticker: Chart = user.charts[t]
                  const chart = ticker.symbol
                  const chartIcon = 'square-' + ticker.symbol.charAt(0)
                  const chartPaneContextObj = returnChartPaneContextObj(user, chart)
                  const chartIsVisible = chartPaneContextObj?.chartIsVisible
                  let parentClass = 'entry'
                  if (chartIsVisible) {
                    parentClass += ' selected'
                  }
                  return (
                    <Draggable
                      key={ticker.symbol}
                      draggableId={ticker.symbol}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className={parentClass}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                            }}
                            onClick={ async (event) => {
                              event.stopPropagation();
                              set_open_chart(user, uld, selectedEmptyChart || 1, chart)
                              return
                            }}
                          >
                            <div className='column'>
                              <div className={'row space-between'}>
                                <div className='row'>
                                  <Icon
                                    icon={chartIcon}
                                    set='sharp-solid'
                                    size={12}
                                    className={'key-icon'}
                                    style={{marginTop: 2, marginRight: 5}}
                                  />
                                  <div>
                                    {ticker.symbol}
                                  </div>
                                  {Object.keys(uld.liveData.POSITIONS).includes(ticker.symbol) ? <div
                                    className='tag'
                                    onClick={() => {setOpenModal(<PositionDetailsModal symbol={ticker.symbol} />)}}
                                  >
                                    POS
                                  </div> : null}
                                </div>
                                <div className={uld.liveData[ticker.symbol]?.day_price_change >= 0 ? ' green-tag' : ' red-tag'}>{valueForTable('day_price_change', uld.liveData[ticker.symbol]?.day_price_change)}{' [' + valueForTable('day_price_change_pc', uld.liveData[ticker.symbol]?.day_price_change_pc) + ']'}</div>
                              </div>
                              <div className='row space-between width-full font-size-11'>
                                {/* <div className='row'>
                                  {(valueForTable('volume', uld.liveData[ticker.symbol]?.v)).slice(0, -3)}
                                </div> */}
                                <div>{valueForTable('price', uld.liveData[ticker.symbol]?.price)}</div>
                                <div className='icon-container'>
                                  <Icon
                                    icon='xmark'
                                    size={9}
                                    style={{marginRight: 12}}
                                    onClick={ async () => {
                                      const tickers = user?.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.tickers            
                                      const updatedTickers = tickers?.filter(t => t !== ticker.symbol)
                                      let charts = user.charts
                                      delete charts[ticker.symbol]
                                      await db.collection('users').doc(user.uid).update({
                                        watchlists: user.watchlists.map(w =>
                                          w.id === user.selectedWatchlistIdHomeTab ? { ...w, tickers: updatedTickers, updatedAt: new Date() } : w
                                        ),
                                        charts
                                      })
                                      const chartContext = returnChartPaneContextObj(user, ticker.symbol)
                                      if (chartContext?.chartIsVisible) {
                                        const panes = chartContext.panesWhereChartIsVisible
                                        if (panes.length) {
                                          panes.forEach(p => {
                                            set_open_chart(user, uld, p, null)
                                          })
                                        }
                                      }
                                      await updateLiveData(user)
                                    }}
                                  />
                                  <Icon
                                    icon='grip-dots-vertical'
                                    size={7}
                                    className='grip-icon'
                                  />
                                </div>
                              </div>

                            </div>
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext> : <div style={{marginTop: 10, marginLeft: 10, fontSize: 11.5}}>
        No tickers.
      </div>}
    </div>
  </>
}

const BalancesList = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyChart] = useAtom(selectedEmptyChartAtom)

  if (!user) return null
  if (!uld) return null

  const balancesArr = [
    {
      path: 'cash',  // from alpaca
      description: 'Cash balance',
    },
    {
      path: 'long_mkt_value',  // from alpaca
      description: 'Porftfolio value of all long positions',
    },
    {
      path: 'mkt_value', // tb
      description: 'Portfolio value of all positions',
    },
    {
      path: 'equity', // from alpaca
      description: 'Total account value (cash + mkt_value)',
    },



    {
      path: 'P/L change since yesterday ($)',
      description: '',
      isDivider: true
    },
    {
      path: 'day_unrealized_pl', // from alpaca
      description: 'Portfolio unrealized profit/loss since yesterday',
    },
    {
      path: 'day_realized_pl',  // tb
      description: 'Realized profit/loss since yesterday',
    },
    {
      path: 'day_pl', // tb
      description: 'Unrealized + realized profit/loss since yesterday',
    },
    {
      path: 'day_equity_change', // from alpaca
      description: 'Equity change since yesterday',
    },




    {
      path: 'P/L change since yesterday (%)',
      description: '',
      isDivider: true
    },
    {
      path: 'day_unrealized_pl_pc', // tb
      description: 'Portfolio unrealized profit/loss percent change since yesterday',
    },
    {
      path: 'day_realized_pl_pc',  // tb
      description: 'Realized profit/loss percent change since yesterday',
    },
    {
      path: 'day_pl_pc', // tb
      description: 'Unrealized + realized profit/loss percent change since yesterday',
    },
    {
      path: 'day_equity_change_pc', // from alpaca
      description: 'Equity percent change since yesterday',
    },



    {
      path: 'P/L total ($)',
      description: '',
      isDivider: true
    },
    {
      path: 'unrealized_pl', // from alpaca
      description: 'Portfolio unrealized profit/loss',
    },
    {
      path: 'realized_pl',  // tb
      description: 'Realized profit/loss',
    },
    {
      path: 'pl', // tb
      description: 'Unrealized + realized profit/loss',
    },






    {
      path: 'P/L total (%)',
      description: '',
      isDivider: true
    },
    {
      path: 'unrealized_pl_pc', // from alpaca
      description: 'Portfolio unrealized profit/loss percent change',
    },
    {
      path: 'realized_pl_pc', // tb
      description: 'Realized profit/loss percent change',
    },
    {
      path: 'pl_pc', // tb
      description: 'Unrealized + realized profit/loss judged against net deposits',
    },



    {
      path: 'MISC',
      description: '',
      isDivider: true
    },
    {
      path: 'net_deposits',  // tb
      description: 'Total deposits minus total withdrawals',
    },
    {
      path: 'cost_basis', // from alpaca
      description: 'Portfolio cost basis',
    },
    {
      path: 'buying_pwr',  // from alpaca
      description: 'Cash buying power',
    },
    {
      path: 'daytrading_buying_pwr', // from alpaca
      description: 'Buying power for day trades',
    },
    {
      path: 'daytrade_count', // alpaca
      description: 'The current number of daytrades that have been made in the last 5 trading days inclusive of today',
    },
  ]

  return (
    <div className='pane-body'>
      {balancesArr.map(balance => {

        const chart = 'BALANCES.' +  balance.path
        const chartPaneContextObj = returnChartPaneContextObj(user, chart)
        const chartIsVisible = chartPaneContextObj?.chartIsVisible
        let divClass = 'entry smaller'
        if (chartIsVisible) {
          divClass += ' selected'
        }
        if (balance.isDivider) {
          return <div className='entry smaller balance-divider'>
            <div className='row'>
              {balance.path}
            </div>
          </div>
        }
        return (
          <div
            title={balance.description}
            className={divClass}
            onClick={() => {
              set_open_chart(user, uld, selectedEmptyChart || 1, chart)
            }}
          >
            <div className='row space-between orange-text'>
              <div>
                {balance.path}
              </div>
              <div>{valueForTable(balance.path, uld.liveData.BALANCES[balance.path])}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}