import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  selectedCodeEditorSlotAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { DividerPaneIcon } from '../reusable/DividerPaneIcon'
import { CreateFunctionModal } from '../modals/CreateFunctionModal'
import { DuplicateFunctionModal } from '../modals/DuplicateFunctionModal'
import { DeleteFunctionModal } from '../modals/DeleteFunctionModal'
import { FunctionObj, Variable } from '../../types/user_types'
import { TEXT_GRAY, set_open_chart } from '../../logic/u'
import { CreateVariableModal } from '../modals/CreateVariableModal'
import { DeleteVariableModal } from '../modals/DeleteVariableModal'

interface ChartOptionsDropdownProps {
  chartIndex: number
  left: number
  top: number
  onClose: () => void
  symbol?: string
}

export const ChartOptionsDropdown = (props: ChartOptionsDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom);
  const [, setOpenModal] = useAtom(openModalAtom)

  const {chartIndex, left, top, onClose, symbol} = props

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  let contents: DropdownItem[] = []
  if (symbol?.startsWith('STATE')) {
    const varStr = symbol.split('.')[1]
    const varObj = user?.variables[varStr]
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='square-pen'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
            <div>Update</div>
          </div>
        ),
        onClick: () => {
          setOpenModal(<CreateVariableModal variable={varObj} />)
        }
      },
    )
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='trash'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
            <div>Delete</div>
          </div>
        ),
        onClick: () => {
          if (varObj) {
            setOpenModal(<DeleteVariableModal variable={varObj} />)
          }
        }
      },
    )
    contents.push(
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
      },
    )  
  }
  contents.push(
    {
      display: (
        <div className='left'>
          <Icon
            icon='circle-x'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10}}
          />
          <div>Clear chart slot</div>
        </div>
      ),
      onClick: () => {
        set_open_chart(user, uld, chartIndex, null);
      }
    },
  )

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={175}
      height={100}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}