import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react'
import {
  userLiveDocAtom,
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types';
import Split from 'react-split';
import { db } from '../../firebase';
import { DataGridPro, GridOverlay, GridSortDirection } from '@mui/x-data-grid-pro'
import { v4 as uuidv4 } from 'uuid';
import { BACKGROUND, tableSX, valueForTable } from '../../logic/u';
import { Icon } from '../reusable';
import { WatchlistOptionsDropdown } from '../dropdowns/WatchlistOptionsDropdown';
import { AddTickersModal } from '../modals/AddTickersModal';
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal';

export const WatchlistTab = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const ellipsisIconRef = useRef<HTMLDivElement>(null);
  const defaultSortModel = [
    {
      field: 'symbol',
      sort: 'asc' as GridSortDirection
    },
  ];
  const [sortModel, setSortModel] = useState(defaultSortModel);
  const [, setOpenModal] = useAtom(openModalAtom)

  if (!user || !uld) return null

  let watchlistsColClass = 'watchlists-col'
  if (user.watchlistsTreeClosed) {
    watchlistsColClass += ' watchlists-tree-closed'
  }

  const watchlistRows = (user.watchlists || []).find(w => w.id === user.selectedWatchlistIdWatchlistsTab)?.tickers.map(t => {
    return {
      ticker: t,
      price: valueForTable(t, uld.liveData[t].price),
      day_price_change: valueForTable(t, uld.liveData[t].day_price_change),
      day_price_change_pc: valueForTable(t, uld.liveData[t].day_price_change_pc),
    }
  }) || []

  const getOverlay = () => {
    return (
      <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
        <div>No tickers.</div>
      </GridOverlay>
    )
  }

  const handleSorting = (v1: string | number | Date, v2: string | number | Date): number => {
    // Handle '-' values
    if (v1 === '-' && v2 !== '-') {
      return -1;
    }
    if (v1 !== '-' && v2 === '-') {
      return 1;
    }

    // Custom sorting for dates
    if (typeof v1 === 'string' && typeof v2 === 'string' && Date.parse(v1) && Date.parse(v2)) {
      return new Date(v1).getTime() - new Date(v2).getTime();
    }

    // Default sorting logic for numbers and strings
    return Number(v1) - Number(v2);
  };


  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  return (
    <div className={watchlistsColClass} >
      {openDropdown}
      <div className='tab-header'>
        <div className='row'>
          <div className='watchlist-tab-name'>
            {(user.watchlists || []).find(w => w.id === user.selectedWatchlistIdWatchlistsTab)?.name}
          </div>
          <div ref={ellipsisIconRef}>
            <Icon
              hoverText='Function options'
              icon='ellipsis'
              set='sharp-solid'
              size={15}
              style={{marginLeft: 10, marginTop: 0}}
              onClick={(e) => {
                const rect = ellipsisIconRef.current?.getBoundingClientRect();
                if (rect) {
                  const clickX = rect.left
                  const clickY = rect?.top - rect?.height + 40
                  if (user.selectedWatchlistIdWatchlistsTab) {
                    setOpenDropdown(
                      <WatchlistOptionsDropdown
                        watchlistId={user.selectedWatchlistIdWatchlistsTab}
                        left={clickX}
                        top={clickY}
                        onClose={() => setOpenDropdown(null)}
                      />
                    )
                  }
                }
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div
            className='watchlists-tab-header-btn'
            onClick={() => {
              setOpenModal(<AddTickersModal watchlistId={user.selectedWatchlistIdWatchlistsTab} />)
            }}
          >
            <Icon
              // icon='magnifying-glass'
              icon='circle-plus'
              className='watchlists-tab-header-icon'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>Tickers</div>
          </div>
          {user?.watchlists.find(w => w.id !== user.selectedWatchlistIdWatchlistsTab)?.isDefault ? <Icon 
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12, marginTop: 5.5}}
            onClick={() => {
              setOpenModal(<CreateWatchlistModal watchlistId={user.selectedWatchlistIdWatchlistsTab} />)
            }}
          /> : null}
        </div>

      </div>
      <div className='watchlist-table-container'>
        <div className='table-container'>
          <DataGridPro
            className='clickable-grid'
            rows={watchlistRows}
            columns={[
              {
                field: 'ticker',
                headerName: 'ticker',
                flex: 1,
                type: 'string',
                sortComparator: handleSorting,
              },
              {
                field: 'price',
                headerName: 'price',
                flex: 1,
                type: 'number',
                headerAlign: 'right',
                align: 'right',
                sortComparator: handleSorting,
              },
              {
                field: 'day_price_change',
                headerName: 'day_price_change',
                flex: 1,
                type: 'number',
                headerAlign: 'right',
                align: 'right',
                sortComparator: handleSorting,
              },
              {
                field: 'day_price_change_pc',
                headerName: 'day_price_change_pc',
                flex: 1,
                type: 'number',
                headerAlign: 'right',
                align: 'right',
                sortComparator: handleSorting,
              },
            ]}
            getRowId={(row) => uuidv4()}
            disableColumnResize
            disableColumnMenu
            disableColumnPinning
            disableSelectionOnClick
            disableColumnReorder
            density='compact'
            components={{
              NoRowsOverlay: getOverlay,
            }}
            sx={tableSX}
            // pagination
            // paginationMode='client'
            // rowsPerPageOptions={[10, 25, 50, 100, 500]}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            localeText={{
              footerTotalRows: 'Total tickers: 0',
            }}
          />
        </div>
      </div>
    </div>
  )
}
