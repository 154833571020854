import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection } from '@mui/x-data-grid-pro'
import {
  tableSX,
  formatNumberForTable,
  formatDateForTable,
  BACKGROUND,
  valueForTable,
  handleMUISorting
} from '../../logic/u'
import {
  openModalAtom,
  transfersAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { TransferDetailsModal } from '../modals/TransferDetailsModal'

export function TransfersTab() {
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [transfers, ] = useAtom(transfersAtom)
  const defaultSortModel = [
    {
      field: 'created_at',
      sort: 'desc' as GridSortDirection
    },
  ];
  const [sortModel, setSortModel] = useState(defaultSortModel);

  if (!uld) return null


  const transferRows = transfers || []

  const getOverlay = () => {
    if (!transfers) {
      return (
        <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
          <div>Loading transfers...</div>
        </GridOverlay>
      )
    }
    return (
      <GridOverlay sx={{ backgroundColor: BACKGROUND }}>
        <div>No transfers.</div>
      </GridOverlay>
    )
  }

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  return (
    <div className='tab-contents has-subtab'>
      <div className='summary-table'>
        <div>
          Summary
        </div>
        <div className='row'>
          <div className='summary-bit'>
            <div>cash</div>
            <div className='val-thing'>{valueForTable('cash', uld.liveData.BALANCES.cash)}</div>
          </div>
          <div className='summary-bit'>
            <div>buying_pwr</div>
            <div className='val-thing'>
              <div className=''>{valueForTable('buying_pwr', uld.liveData.BALANCES.buying_pwr)}</div>
            </div>
          </div>
          <div className='summary-bit last-thing'>
            <div>cash_withdrawable</div>
            <div className='val-thing'>
              <div className=''>{valueForTable('cash_withdrawable', uld.liveData.BALANCES.cash_withdrawable)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='table-container'>
        <DataGridPro
          rows={transferRows}
          className='clickable-grid uppercase-grid'
          columns={[
            {
              field: 'type',
              headerName: 'type',
              flex: 1,
              align: 'left',
              type: 'string',
              sortComparator: handleMUISorting
            },
            {
              field: 'direction',
              headerName: 'direction',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting
            },
            {
              field: 'amount',
              headerName: 'amount',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => valueForTable('amount', params.row.amount)
            },
            {
              field: 'requested_amount',
              headerName: 'requested_amount',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => valueForTable('amount', params.row.requested_amount)
            },
            {
              field: 'fee',
              headerName: 'fee',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              valueGetter: (params) => valueForTable('fee', params.row.fee)
            },
            {
              field: 'created_at',
              headerName: 'created_at',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              valueGetter: (params) => formatDateForTable(params.row.created_at)
            },
            {
              field: 'updated_at',
              headerName: 'updated_at',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              valueGetter: (params) => formatDateForTable(params.row.updated_at)
            },
            {
              field: 'status',
              headerName: 'status',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              type: 'string',
              sortComparator: handleMUISorting
            },

          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: getOverlay,
          }}
          sx={tableSX}
          onRowClick={(params) => setOpenModal(<TransferDetailsModal transferId={params.row.id} />)}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total transfers:',
          }}
        />
      </div>
    </div>
  )
}